import React from "react";
// Css
import "./promo.scss";

export default function Promo() {
  return (
    <div id="promo_banner" className="flex h-center v-center">
        <p># <span>下單滿 NT$ 880 免運</span></p>
    </div>
  );
}
