import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { Buffer } from "buffer";
// Category
import { category } from "../../pages/Data";
// Loading Animation
import LoadingAnimation from "../../widgets/Animations/Loading";
// Footer
import Footer from "../../widgets/Footer/Footer";
// Category side bar
import CategorySideBar from "../../widgets/CategorySideBar/CategorySideBar";
// Icons
import { AiFillCaretDown, AiFillCaretUp } from "react-icons/ai";
import { BiSortDown, BiSortUp } from "react-icons/bi";
// Css
import "./productList.scss";

export default function ProductList(props) {
  const navigate = useNavigate();
  // [ Loading ]
  const [loading, setLoading] = useState(true);
  // [ Selected type ]
  const [selected, setSelected] = useState(null);
  // [ Sort ]
  const [sort, setSort] = useState("產品型號");
  const [sortAsc, setSortAsc] = useState(true);
  // [ Dropdown ]
  const [showDropdown, setShowDropdown] = useState(false);
  // [ Products ]
  const [products, setProducts] = useState(new Map());
  const [updateImageIndex, setUpdateImageIndex] = useState(false);
  const [showProducts, setShowProducts] = useState([]);
  // [ Redirect ]
  const [updateCategory, setUpdateCategory] = useState(false);
  const [productId, setProductId] = useState(null);
  const [productSelected, setProductSelected] = useState(null);
  const [clickColor, setClickColor] = useState(false);

  useEffect(() => {
    // Handle initial selected type
    const pathname = getCategoryFromPath();
    setSelected(pathname);

    // Get product list
    callProductListEndpoint(pathname);
  }, []);
  useEffect(() => {
    // Handle nav update on product list
    if (props.clickNav) {
      const pathname = getCategoryFromPath();
      setSelected(pathname);
      filterProducts(products, pathname);
      props.setClickNav(false);
    }
  }, [props.clickNav]);
  useEffect(() => {
    setUpdateImageIndex(false);
  }, [updateImageIndex]);
  useEffect(() => {
    // Handle initial selected type
    const pathname = getCategoryFromPath();
    setSelected(pathname);

    // Get product list
    callProductListEndpoint(pathname);
    
    setUpdateCategory(false);
  }, [updateCategory]);
  useEffect(() => {
    if (productId && clickColor) {
      setProductId(null);
      setClickColor(false);
    } else if (productId && !clickColor) {
      goProductInfoPage();
    }
  }, [productId]);

  // Endpoint
  async function callProductListEndpoint(initialSelect) {
    await axios
      .get(`${process.env.REACT_APP_API}/products/list`, {
        headers: { "x-api-key": process.env.REACT_APP_API_KEY },
      })
      .then((res) => {
        if (res.status === 200) {
          const data = classifyProducts(res.data);
          setProducts(data);
          filterProducts(data, initialSelect);
          setLoading(false);
        }
      })
      .catch(() => { });
  }

  // Get path from url
  const getCategoryFromPath = () => {
    let path = window.location.pathname;
    path = path.split("/");
    return decodeURIComponent(path[2]);
  }

  // Redirect to product info page
  const goProductInfoPage = () => {
    navigate(`/product/${productId}/${productSelected}`);
  };

  // [ Handle Products ]
  // Classify products
  const classifyProducts = (data) => {
    const typeMap = new Map();
    const productMap = new Map();
    let list = [];
    let lastType = null;

    // Product colors, price, and sale
    for (let i = 0; i < data.product_info.length; i++) {
      const { productId, color, code, price, sale, image } =
        data.product_info[i];

      if (!productMap.has(productId)) {
        productMap.set(productId, {
          color: [color],
          code: [code],
          price: [price],
          sale: [sale],
          image: [image],
          imageIndex: 0,
        });
      } else {
        const old = productMap.get(productId);
        productMap.set(productId, {
          color: [...old.color, color],
          code: [...old.code, code],
          price: [...old.price, price],
          sale: [...old.sale, sale],
          image: [...old.image, image],
          imageIndex: 0,
        });
      }
    }

    // Classify products by types and add product color, price, and sale to products
    for (let i = 0; i < data.products.length; i++) {
      const { id, type } = data.products[i];
      const info = {
        ...data.products[i],
        ...productMap.get(id),
      };

      if (lastType != type) {
        if (i !== 0) {
          typeMap.set(lastType, [...list]);
        }
        list = [info];
      } else {
        list.push(info);
      }
      lastType = type;
    }

    if (data.products.length > 0) {
      typeMap.set(lastType, [...list]);
    }

    return typeMap;
  };
  // Filter products
  const filterProducts = (data, currentSelect) => {
    let list = [];
    const productSet = new Set();

    if (currentSelect === "所有商品") {
      for (const value of data.values()) {
        for (let i = 0; i < value.length; i++) {
          const { name, model } = value[i];
          const distinctName = name + "_" + model;

          if (!productSet.has(distinctName)) {
            list.push(value[i]);
            productSet.add(distinctName);
          }
        }
      }
    } else if (
      currentSelect === "專業安全鞋" ||
      currentSelect === "舒適生活鞋" ||
      currentSelect === "其他產品"
    ) {
      for (let i = 0; i < category.length; i++) {
        const { name, type } = category[i];

        if (name === currentSelect) {
          for (let j = 0; j < type.length; j++) {
            const value = data.get(type[j]);

            for (let k = 0; value && k < value.length; k++) {
              const { name, model } = value[k];
              const distinctName = name + "_" + model;

              if (!productSet.has(distinctName)) {
                list.push(value[k]);
                productSet.add(distinctName);
              }
            }
          }
          break;
        }
      }
    } else {
      list = data.get(currentSelect);
    }
    sortProducts(list === undefined ? [] : list, sort, sortAsc);
  };
  // Sort products
  const sortProducts = (data, currentSort, currentAsc) => {
    const field = {
      產品型號: "model",
      產品價格: "sale",
    };
    const fieldName = field[currentSort];
    let sortedData = null;

    if (fieldName === "sale") {
      sortedData = data.sort((a, b) => {
        const priceA = Math.min(...a[fieldName]);
        const priceB = Math.min(...b[fieldName]);

        if (priceA === priceB) {
          return 0;
        }
        return currentAsc ? priceA - priceB : priceB - priceA;
      });
    } else {
      sortedData = data.sort((a, b) => {
        if (a[fieldName] === b[fieldName]) {
          return 0;
        }

        if (currentAsc) {
          return a[fieldName] < b[fieldName] ? -1 : 1;
        } else {
          return b[fieldName] < a[fieldName] ? -1 : 1;
        }
      });
    }

    setShowProducts(sortedData);
  };

  // List products
  const listProducts = () => {
    const list = [];

    for (let i = 0; i < showProducts.length; i++) {
      const { id, name, model, price, sale, code, image, imageIndex } =
        showProducts[i];
      const number = "(型號: " + model + ")";

      // Get lowest sale price
      let minIdx = null;
      let min = null;
      for (let j = 0; j < price.length; j++) {
        if (j === 0 || price[j] < min) {
          minIdx = j;
          min = price[j];
        }
      }

      // List all colors
      const colorDivs = [];
      for (let j = 0; j < code.length; j++) {
        colorDivs.push(
          <div
            key={"product_color" + i + "_" + j}
            style={{ backgroundColor: code[j] }}
            className="color"
            onMouseOver={() => {
              setShowProducts((old) => {
                old[i].imageIndex = j;
                return old;
              });
              setUpdateImageIndex(true);
            }}
            onClick={() => {
              setClickColor(true);
            }}
          ></div>
        );
      }

      // Convert image
      let base64String = "";
      if (image && image[imageIndex] && Array.isArray(image[imageIndex].data)) {
        const realBuffer = Buffer.from(image[imageIndex].data);
        base64String = realBuffer.toString('base64');
      }

      list.push(
        <div
          key={"product_div" + i}
          className="flex-column v-center product"
          to="/product"
          onClick={() => {
            setProductId(id);
            setProductSelected(imageIndex);
          }}
        >
          <div key={"product_img" + i} className="product-img">
            <img src={`data:image/png;base64, ${base64String}`} />
          </div>
          <a key={"product_name" + i} className="product-name">
            {name}
            <span>{number}</span>
          </a>
          <div key={"product_price_div" + i} className="flex price-cont">
            <a
              key={"product_price" + i}
              className={`flex product-price${price[minIdx] !== sale[minIdx] ? " strikethrough" : ""}`}
            >
              <span className="currency">NT$</span>
              <span className="number">{price[minIdx] && getFormattedPrice(price[minIdx])}</span>
            </a>
            {price[minIdx] !== sale[minIdx] && (
              <a key={"product_sale" + i} className="flex product-sale">
                <span className="currency">NT$ </span>
                <span className="number">{sale[minIdx] && getFormattedPrice(sale[minIdx])}</span>
              </a>
            )}
          </div>
          <div key={"product_color_div" + i} className="flex color_container">
            {colorDivs}
          </div>
        </div>
      );
    }

    return list;
  };

  // [ Price ]
  const getFormattedPrice = (number) => {
    return new Intl.NumberFormat('en-US').format(number);
  }

  return (
    <div id="page-product-list" className="flex-column v-center page">
      <div className="flex page-content">
        {/* Category */}
        <CategorySideBar
          isFromProductList={true}
          setUpdateCategory={setUpdateCategory}
        />
        <div className="flex-column list">
          <div className="flex v-center h-between title-row">
            <a className="category-select">{selected}</a>
            {/* Sort */}
            <div className="flex h-end v-center sort">
              <a className="sort-title">排序方式:</a>
              <div className="flex-column dropdown">
                <div
                  className="flex h-between v-center dropdown-text"
                  onClick={() => setShowDropdown(!showDropdown)}
                >
                  <a id="dropdown-text">{sort}</a>
                  {showDropdown ? (
                    <AiFillCaretUp id="dropdown-icon" />
                  ) : (
                    <AiFillCaretDown id="dropdown-icon" />
                  )}
                  <div
                    style={{
                      display: showDropdown ? "flex" : "none",
                    }}
                    id="dropdown-content"
                    className="v-center dropdown-content"
                  >
                    <a
                      onClick={() => {
                        setSort("產品型號");
                        sortProducts(showProducts, "產品型號", sortAsc);
                        setShowDropdown(!showDropdown);
                      }}
                    >
                      產品型號
                    </a>
                    <a
                      onClick={() => {
                        setSort("產品價格");
                        sortProducts(showProducts, "產品價格", sortAsc);
                        setShowDropdown(!showDropdown);
                      }}
                    >
                      產品價格
                    </a>
                  </div>
                </div>
              </div>
              <div className="flex v-center sort-icon">
                {sortAsc ? (
                  <BiSortDown
                    onClick={() => {
                      sortProducts(showProducts, sort, !sortAsc);
                      setSortAsc(!sortAsc);
                    }}
                  />
                ) : (
                  <BiSortUp
                    onClick={() => {
                      sortProducts(showProducts, sort, !sortAsc);
                      setSortAsc(!sortAsc);
                    }}
                  />
                )}
              </div>
            </div>
          </div>
          {/* Loading animation */}
          {loading &&
            <div className="flex h-center loader-cont">
              <LoadingAnimation />
            </div>
          }
          {/* List of products */}
          {!loading && <div className="h-around product-list">{listProducts()}</div>}
        </div>
      </div>
      <Footer />
    </div>
  );
}
