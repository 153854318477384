import React, { useState, useRef, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
// Sign in
import SignIn from "../../User/SignInUp/SignIn";
import SignUp from "../../User/SignInUp/SignUp";
import ForgotUserName from "../../User/SignInUp/ForgotUserName";
import ForgotPassword from "../../User/SignInUp/ForgotPassword";
// Pagination
import Pagination from "../../../widgets/Pagination/Pagination";
// Helper
import { getCookie } from "../../../utils/Cookie";
// Footer
import Footer from "../../../widgets/Footer/Footer";
// Icon
import { PiPackageDuotone } from "react-icons/pi";
// Css
import "./orderTracking.scss";

export default function OrderTracking(props) {
  const navigate = useNavigate();
  const { signedIn } = props;

  // Page
  const [page, setPage] = useState(1);
  const [shouldUpdatePage, setShouldUpdatePage] = useState(false);
  const [totalPages, setTotalPages] = useState(1);
  // Show content - 1 = sign in, 2 = order tracking
  const [showContent, setShowContent] = useState(1);
  // Sign in/sign up/forgot user name/forgot password
  const [showSignIn, setShowSignIn] = useState(true);
  const [showSignUp, setShowSignUp] = useState(false);
  const [showForgotUserName, setShowForgotUserName] = useState(false);
  const [showForgotPassword, setShowForgotPassword] = useState(false);
  // Orders
  const [orders, setOrders] = useState([]);
  // Tracking & email
  const trackingNumber = useRef("");
  const trackingEmail = useRef("");
  const [validTrackingNumber, setValidTrackingNumber] = useState(true);
  const [validTrackingEmail, setValidTrackingEmail] = useState(true);
  const [validTracking, setValidTracking] = useState(true);
  const [error, setError] = useState("");

  useEffect(() => {
    const info = getCookie();

    if (signedIn || (info && info.user && info.x)) {
      // call endpoint - get tracking status
      callGetAccountOrders(info);
    }
  }, []);

  useEffect(() => {
    if (shouldUpdatePage) {
      // Get auth log action list
      callGetAccountOrders();
    }
  }, [shouldUpdatePage]);

  // Endpoint
  async function callGetAccountOrders(info) {
    if (!info) {
      info = getCookie();
    }

    await axios
      .get(
        `${process.env.REACT_APP_API}/orders/getOrders`,
        {
          headers: {
            "x-access-token": info.x,
            "x-api-key": process.env.REACT_APP_API_KEY
          },
          params: {
            userName: info.user,
            page
          },
        }
      )
      .then((res) => {
        if (res.status === 200) {
          setOrders(res.data.orders);
          setTotalPages(res.data.totalPages);
          setShouldUpdatePage(false);
        }
      })
      .catch(() => { });
  }
  async function callCheckOrderEndpoint(orderNumber, email) {
    await axios
      .get(
        `${process.env.REACT_APP_API}/orders/check?trackingNumber=${orderNumber}&email=${email}`, {
        headers: { "x-api-key": process.env.REACT_APP_API_KEY },
      }
      )
      .then((res) => {
        if (res.status === 200) {
          navigate("/order-detail/" + orderNumber + "/" + email);
        }
      })
      .catch((err) => {
        if (err.response && err.response.status === 403) {
          setValidTracking(false);
          setError("抱歉, 查無此訂單, 請確認您輸入的訂單號碼和email是否正確");
        }
      });
  }

  // Get order list
  const getOrderList = () => {
    const list = [];

    if (orders.length === 0) {
      list.push(
        <div key="order_list_no_order" className="flex row no-order">
          <div key="order_list_div_no_order">
            <a key="order_list_text_no_order">( 無訂單資料 )</a>
          </div>
        </div>
      );
    } else {
      orders.forEach((item, index) => {
        list.push(
          <div
            key={"order_list" + index}
            className="flex h-around row"
            onClick={() =>
              navigate("/order-detail/" + item.trackingNumber + "/0")
            }
          >
            <div key={"order_date_div" + index}>
              <a key={"order_date" + index}>{formatDate(item.date)}</a>
            </div>
            <div key={"order_number_div" + index}>
              <a key={"order_number" + index}>{item.trackingNumber}</a>
            </div>
            <div key={"order_price_div" + index}>
              <a key={"order_price" + index}>NT$ {item.totalAmount}</a>
            </div>
            <div key={"order_status_div" + index}>
              <a key={"order_status" + index}>{item.status}</a>
            </div>
          </div>
        );
      });
    }

    return (
      <div className="flex-column desktop order-list">
        <div className="flex h-around row title-row">
          <div className="flex h-center v-center">
            <a>訂單日期</a>
          </div>
          <div className="flex h-center v-center">
            <a>訂單編號</a>
          </div>
          <div className="flex h-center v-center">
            <a>消費金額</a>
          </div>
          <div className="flex h-center v-center">
            <a>訂單狀態</a>
          </div>
        </div>
        <div className="flex-column list-content">
          {list}
        </div>
      </div>
    );
  };
  const getMobileOrderList = () => {
    const list = [];

    if (orders.length === 0) {
      list.push(
        <div key="order_list_no_order" className="flex row no-order">
          <div key="order_list_div_no_order">
            <a key="order_list_text_no_order">( 無訂單資料 )</a>
          </div>
        </div>
      );
    } else {
      orders.forEach((item, index) => {
        list.push(
          <div
            key={"order_list_item" + index}
            className="flex h-center v-center item"
          >
            <PiPackageDuotone />
            <div
              key={"order_list" + index}
              className="flex-column row"
              onClick={() =>
                navigate("/order-detail/" + item.trackingNumber + "/0")
              }
            >
              <div key={"order_date_div" + index}>
                <a key={"order_date" + index} className="flex v-center">
                  <b>訂單日期:</b>
                  {formatDate(item.date)}
                </a>
              </div>
              <div key={"order_number_div" + index}>
                <a key={"order_number" + index} className="flex v-center">
                  <b>訂單編號:</b>
                  {item.trackingNumber}
                </a>
              </div>
              <div key={"order_price_div" + index}>
                <a key={"order_price" + index} className="flex v-center">
                  <b>消費金額:</b>
                  NT$ {item.totalAmount}
                </a>
              </div>
              <div key={"order_status_div" + index}>
                <a key={"order_status" + index} className="flex v-center">
                  <b>訂單狀態:</b>
                  {item.status}
                </a>
              </div>
            </div>
          </div>
        );
      });
    }

    return (
      <div className="flex-column mobile order-list">
        <div className="flex-column list-content">
          {list}
        </div>
      </div>
    );

  }

  // Format date
  const formatDate = (rawDate) => {
    let date = "";
    date = new Date(rawDate).getFullYear() + "/";
    date += new Date(rawDate).getMonth() < 10 ? "0" : "";
    date += new Date(rawDate).getMonth() + "/";
    date += new Date(rawDate).getDate() < 10 ? "0" : "";
    date += new Date(rawDate).getDate();

    return date;
  };

  // Validate tracking input
  const validateTrackingInput = () => {
    let check = true;
    setValidTracking(true);

    // Tracking number
    if (trackingNumber.current.value.length === 0) {
      setValidTrackingNumber(false);
      check = false;
    } else {
      setValidTrackingNumber(true);
    }

    // Email
    if (
      !/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(
        trackingEmail.current.value
      )
    ) {
      setValidTrackingEmail(false);
      check = false;
    } else {
      setValidTrackingEmail(true);
    }

    if (check) {
      callCheckOrderEndpoint(
        trackingNumber.current.value,
        trackingEmail.current.value
      );
    }
  };

  return (
    <div id="page-tracking" className="flex-column v-center page">
      <div className="flex h-center v-center page-content">
        <div className={`flex-column v-center content${showContent === 2 ? " tracking-by-number" : ""}${signedIn ? " sign-in" : " sign-out"}`}>
          <h2>{showContent === 2 || signedIn ? "訂單查詢" : showSignUp ? "加入會員查詢" : "登入會員查詢"}</h2>
          {!signedIn && (
            <div className="flex h-center tracking">
              {
                showContent === 1 && (
                  <div className="flex-column v-center form">
                    {showSignIn && (
                      <SignIn
                        insidePage={true}
                        page={"order-tracking"}
                        showForgetFileds={true}
                        showSignInAnimation={false}
                        setShowSignIn={setShowSignIn}
                        setShowSignUp={setShowSignUp}
                        setShowForgotPassword={setShowForgotPassword}
                        setShowForgotUserName={setShowForgotUserName}
                        setShowContent={setShowContent}
                        setSignedIn={props.setSignedIn}
                        setUserFullName={props.setUserFullName}
                        setShowSignInAnimation={props.setShowSignInAnimation}
                        setShowSignUpSocial={props.setShowSignUpSocial}
                        setSocialInfo={props.setSocialInfo}
                        setCartQuantity={props.setCartQuantity}
                        setRefreshCart={props.setRefreshCart}
                      />
                    )}
                    {showSignUp && (
                      <SignUp
                        insidePage={true}
                        page={"order-tracking"}
                        setShowSignIn={setShowSignIn}
                        setShowSignInAnimation={() => { }}
                        setShowSignUp={setShowSignUp}
                        setShowContent={setShowContent}
                      />
                    )}
                    {showForgotUserName && (
                      <ForgotUserName
                        insidePage={true}
                        page={"order-tracking"}
                        setShowSignIn={setShowSignIn}
                        setShowForgotUserName={setShowForgotUserName}
                        setShowForgotPassword={setShowForgotPassword}
                        setShowSignInAnimation={() => { }}
                      />
                    )}
                    {showForgotPassword && (
                      <ForgotPassword
                        insidePage={true}
                        page={"order-tracking"}
                        setShowSignIn={setShowSignIn}
                        setShowForgotUserName={setShowForgotUserName}
                        setShowForgotPassword={setShowForgotPassword}
                        setShowSignInAnimation={() => { }}
                      />
                    )}
                  </div>
                )}
              {
                showContent === 2 && (
                  <div className="flex-column v-center form tracking-form">
                    <div className="flex-column v-center">
                      <a className="input-title">
                        訂單號碼<span>*</span>
                      </a>
                      <input
                        type="text"
                        ref={trackingNumber}
                        className={validTrackingNumber ? "" : "invalid-input"}
                      />
                      <a className="input-title">
                        Email<span>*</span>
                      </a>
                      <input
                        type="text"
                        ref={trackingEmail}
                        className={validTrackingEmail ? "" : "invalid-input"}
                      />
                      {!validTracking && <a className="invalid-msg">{error}</a>}
                      <div className="flex h-center">
                        <button className="button1 sign-in-search" onClick={() => setShowContent(1)}>
                          登入會員查詢
                        </button>
                        <button className="button1" onClick={validateTrackingInput}>
                          查詢
                        </button>
                      </div>
                    </div>
                  </div>
                )}
            </div>
          )}
          {signedIn && getOrderList()}
          {signedIn && getMobileOrderList()}
          {/* Pagination */}
          {signedIn &&
            <Pagination
              page={page}
              setPage={setPage}
              totalPages={totalPages}
              setShouldUpdatePage={setShouldUpdatePage}
            />
          }
        </div>
      </div>
      <Footer />
    </div>
  );
}
