import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
// Helper
import { getCookie, clearCookie } from "../../utils/Cookie";
// Footer
import Footer from "../../widgets/Footer/Footer";
// Image
import ThanksForOrderCard from "../../files/images/thanks-for-your-order-card.png";
// Icons
import { BsCheckCircleFill } from "react-icons/bs";
// Css
import "./orderComplete.scss";

export default function OrderComplete(props) {
  const navigate = useNavigate();
  const [orderDetail, setOrderDetail] = useState(null);

  useEffect(() => {
    // Get cookie
    const info = getCookie();

    if (info && info.cart && info.trackingNumber) {
      callOrderCompleteEndpoint(info);
    } else {
      navigate("/");
    }
  }, []);

  // Endpoint
  async function callOrderCompleteEndpoint(info) {
    await axios
      .post(`${process.env.REACT_APP_API}/orders/order-complete`, {
        cartId: info.cart,
        trackingNumber: info.trackingNumber
      }, {
        headers: {
          "x-api-key": process.env.REACT_APP_API_KEY
        }
      })
      .then((res) => {
        if (res.status === 200) {
          setOrderDetail(res.data[0])

          // Clear cookies
          const time = new Date().toUTCString();
          clearCookie("cart", "", time);
          clearCookie("trackingNumber", "", time);

          // Update nav
          setTimeout(() => {
            props.setRefreshCart(true);
          }, 100);
        }
      })
      .catch((err) => {
        console.error(err);
      });
  }

  return (
    <div id="page-order-complete" className="flex-column v-center page">
      <div className="flex-column h-center v-center page-content">
        <h2 className="flex v-center">
          <BsCheckCircleFill />
          交易成功
        </h2>
        <a className="message">
          謝謝, 我們已收到您的訂單, 將會儘快為您處理。
        </a>
        {orderDetail && (
          <div className="flex h-center content">
            {/* Thanks for your order card */}
            <img className="thank-you-card" src={ThanksForOrderCard} alt="感謝您的購買 | 謝謝您支持MIT產品" />
            {/* 訂單資料 */}
            <div className="flex-column order-content">
              <div className="flex-column">
                <h3>交易明細</h3>
                <div className="title-line" />
                <div className="flex-column field">
                  <a className="title">訂單編號:</a>
                  <a className="info">{orderDetail.trackingNumber}</a>
                </div>
                <div className="flex-column field">
                  <a className="title">訂單金額:</a>
                  <a className="info">NT$ {orderDetail.totalAmount}</a>
                </div>
                <div className="flex-column field">
                  <a className="title">付款方式:</a>
                  <a className="info">{orderDetail.paymentMethod}</a>
                </div>
                <div className="flex-column field">
                  <a className="title">聯絡姓名:</a>
                  <a className="info">{orderDetail.fullName}</a>
                </div>
                <div className="flex-column field">
                  <a className="title">聯絡電話:</a>
                  <a className="info">(+886) {orderDetail.phoneNumber}</a>
                </div>
                <div className="flex-column field">
                  <a className="title">地址:</a>
                  <a className="info">
                    {`${orderDetail.zipcode} ${orderDetail.city} ${orderDetail.district} ${orderDetail.address}`}
                  </a>
                </div>
              </div>
            </div>
          </div>
        )}
        <button className="button1" onClick={() => navigate("/")}>回首頁</button>
      </div>
      <Footer />
    </div>
  );
}
