import React, { useEffect, useState } from "react";
import axios from "axios";
// Edit Order Detail
import EditOrderDetail from "./EditOrderDetail";
// Pagination
import Pagination from "../../../widgets/Pagination/Pagination";
// Css
import "./orderSystem.scss";

export default function OrderSystem() {
    // [ Page ]
    const [page, setPage] = useState(1);
    const [shouldUpdatePage, setShouldUpdatePage] = useState(false);
    const [totalPages, setTotalPages] = useState(1);
    // [ Edit order modal ]
    const [email, setEmail] = useState(null);
    const [trackingNumber, setTrackingNumber] = useState(null);
    const [showEditOrderDetail, setShowEditOrderDetail] = useState(false);
    // [ Content ]
    const [data, setData] = useState([]);

    useEffect(() => {
        callGetOrderListEndpoint();
    }, []);

    useEffect(() => {
        if (shouldUpdatePage) {
            // Get order list
            callGetOrderListEndpoint();
        }
    }, [shouldUpdatePage]);

    // Endpoint
    async function callGetOrderListEndpoint() {
        await axios
            .get(`${process.env.REACT_APP_API}/orders/list`, {
                headers: { "x-api-key": process.env.REACT_APP_API_KEY },
                params: { page },
            })
            .then((res) => {
                if (res.status === 200) {
                    setData(res.data.list);
                    setTotalPages(res.data.totalPages);
                    setShouldUpdatePage(false);
                }
            })
            .catch(() => { });
    }

    // Get order list
    const getOrderList = () => {
        const list = [];

        if (data.length > 0) {
            data.forEach((item, index) => {
                list.push(
                    <div
                        key={"log_row" + index}
                        className="flex"
                        onClick={() => {
                            setEmail(item.email);
                            setTrackingNumber(item.trackingNumber);
                            setShowEditOrderDetail(true);
                        }}
                    >
                        <a key={"log_time" + index} className="flex h-center v-center">
                            {formatDate(item.created_at)}
                        </a>
                        <a key={"log_tracking_no" + index} className="flex h-center v-center">
                            {item.trackingNumber}
                        </a>
                        <a key={"log_status" + index} className="flex h-center v-center">
                            {!item.isCanceled ?
                                <span>{item.status}</span> :
                                <span className="canceled">訂單取消</span>
                            }
                        </a>
                        <a key={"log_price" + index} className="flex h-center v-center">
                            NT$ {item.totalAmount}
                        </a>
                        <a key={"log_full_name" + index} className="flex h-center v-center">
                            {item.fullName}
                        </a>
                    </div>
                );
            });
        } else {
            list.push(
                <div key="log_row_no_record" className="flex h-center no-record">
                    沒有紀錄
                </div>
            );
        }

        return list;
    }

    // Fomat date
    const formatDate = (dateStr) => {
        const date = new Date(dateStr);

        const year = date.getFullYear();
        const month = String(date.getMonth() + 1).padStart(2, '0'); // Months are zero-indexed
        const day = String(date.getDate()).padStart(2, '0');
        const hours = String(date.getHours()).padStart(2, '0');
        const minutes = String(date.getMinutes()).padStart(2, '0');

        return `${year}/${month}/${day} ${hours}:${minutes}`;
    }

    return (
        <div id="order-system" className="flex-column v-center">
            {/* Order list */}
            <div className="flex-column order-list">
                {/* Title */}
                <div className="flex title">
                    <a>下單時間</a>
                    <a>訂單編號</a>
                    <a>狀態</a>
                    <a>合計</a>
                    <a>購買者</a>
                </div>
                <div className="order-list-content">
                    {getOrderList()}
                </div>
            </div>
            {/* Pagination */}
            <Pagination
                page={page}
                setPage={setPage}
                totalPages={totalPages}
                setShouldUpdatePage={setShouldUpdatePage}
            />
            {showEditOrderDetail && (
                <EditOrderDetail
                    email={email}
                    trackingNumber={trackingNumber}
                    setShowEditOrderDetail={setShowEditOrderDetail}
                    setShouldUpdatePage={setShouldUpdatePage}
                />
            )}
        </div>
    )
}
