import React, { useState, useRef, useCallback, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
// Footer
import Footer from "../../../../widgets/Footer/Footer";
// Pop up
import PopUp from "../../../../widgets/PopUps/PopUps";
// Icon
import { HiOutlineEye, HiOutlineEyeOff } from "react-icons/hi";
// Css
import "./resetPassword.scss";

export default function ResetPassword(props) {
  const navigate = useNavigate();

  // [ Error ]
  const [error, setError] = useState(null);
  // [ Info ] - user name & vCode
  const [info, setInfo] = useState({});
  // [ Password ]
  const password = useRef("");
  const checkPassword = useRef("");
  const [showPassword, setShowPassword] = useState(false);
  // [ Validation ]
  const [inValid, setInValid] = useState(false);
  // [ Pop up ]
  const [showPopUp, setShowPopUp] = useState(false);

  // Env
  const contactEmail = process.env.REACT_APP_CONTACT_EMAIL;

  // Get url params
  const getParams = () => {
    const str = window.location.search.substr(1);
    const params = str.split("&vCode=");
    if (params.length !== 2) {
      goHomePage();
      return null;
    } else {
      // Get user name and vCode
      const userName = params[0].split("userName=")[1];
      const vCode = params[1];
      if (userName === undefined || vCode === undefined) {
        goHomePage();
        return null;
      } else {
        return { userName, vCode };
      }
    }
  };

  // Update password icon
  const updatePasswordIcon = () => {
    setShowPassword(!showPassword);
  };

  // Go home page
  const goHomePage = () => {
    navigate("/");
  };

  useEffect(() => {
    // Get params from url
    const infoFromParams = getParams();
    setInfo(infoFromParams);

    document.addEventListener("keydown", keyDownHandler);

    return () => {
      document.removeEventListener("keydown", keyDownHandler);
    };
  }, []);
  useEffect(() => {
    if (showPopUp) {
      document.removeEventListener("keydown", keyDownHandler);
    }
  }, [showPopUp]);

  // Key down handlers
  const keyDownHandler = useCallback((event) => {
    if (event.key === "Enter") {
      handleSubmit();
    }
  }, []);

  // Handle submit
  async function handleSubmit() {
    if (password.current.value === "") {
      setInValid(true);
      setError("請輸入密碼");
    } else if (password.current.value.length < 8 || password.current.value.length > 20) {
      setInValid(true);
      setError("您的密碼長度必須在8-20之間");
    } else if (password.current.value.search(/[a-z]/) < 0) {
      setInValid(true);
      setError("您的密碼至少需要包含一個小寫字母");
    } else if (password.current.value.search(/[A-Z]/) < 0) {
      setInValid(true);
      setError("您的密碼至少需要包含一個大寫字母");
    } else if (password.current.value.search(/[0-9]/) < 0) {
      setInValid(true);
      setError("您的密碼至少需要包含一個數字");
    } else if (password.current.value.search(/[!@#$%^&*()_+]/) < 0) {
      setInValid(true);
      setError("您的密碼至少需要包含一個特殊字符");
    } else if (password.current.value !== checkPassword.current.value) {
      setInValid(true);
      setError("輸入的密碼不一致");
    } else {
      setInValid(false);
      setError(null);
      
      await axios
        .put(`${process.env.REACT_APP_API}/accounts/resetPassword`, {
          userName: info.userName,
          vCode: info.vCode,
          password: password.current.value,
        }, {
          headers: { "x-api-key": process.env.REACT_APP_API_KEY },
        })
        .then((res) => {
          if (res.status === 200) {
            setInValid(false);
            setError(null);
            setShowPopUp(true);
          }
        })
        .catch((err) => {
          if (err.response && err.response.status === 498) {
            setError(
              "抱歉, 您的重設密碼連結已失效, 請回到登入頁面重設您的密碼, 若您持續看到此訊息, 請與我們聯絡: " +
              contactEmail
            );
          } else {
            setError(
              "網頁錯誤, 目前無法重設密碼, 若您看到此訊息, 請與我們聯絡: " +
              contactEmail
            );
          }
        });
    }
  }

  // Pop up
  const popUpContent = () => {
    return <a>密碼重設成功!</a>;
  };
  const hidePopUp = () => {
    setShowPopUp(false);
    goHomePage();
    props.setShowSignIn(true);
    props.setShowSignInAnimation(true);
  };

  return (
    <div id="page-reset" className="flex-column v-center page">
      <div className="flex h-center v-center page-content">
        <div className="flex-column content">
          <h2>重設密碼</h2>
          <div className="flex-column text-inputs">
            <div className="flex-column v-start">
              <a>
                密碼<span>*</span>
              </a>
              <input
                type={showPassword ? "text" : "password"}
                ref={password}
                className={inValid ? "invalid-input" : ""}
              />
              {showPassword ? (
                <HiOutlineEyeOff className="icon" onClick={updatePasswordIcon} />
              ) : (
                <HiOutlineEye className="icon" onClick={updatePasswordIcon} />
              )}
            </div>
            <div className="flex-column v-start">
              <a>
                確認密碼<span>*</span>
              </a>
              <input
                type={showPassword ? "text" : "password"}
                ref={checkPassword}
                className={inValid ? "invalid-input" : ""}
              />
            </div>
          </div>
          {error !== null && <a className="error-msg">{error}</a>}
          <button className="button1 submit" onClick={handleSubmit}>
            確認
          </button>
        </div>
      </div>
      {showPopUp && <PopUp popUpContent={popUpContent} hidePopUp={hidePopUp} />}
      <Footer />
    </div>
  );
}
