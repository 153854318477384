import React, { useEffect, useState } from "react";
// Css
import "./pagination.scss";

export default function Pagination(props) {
    const [pageNumbers, setPageNumbers] = useState([]);

    useEffect(() => {
        const { page, totalPages } = props;
        const nums = [];

        // Always add the first page
        nums.push(1);

        // Logic to add ellipses and handle page numbers around the current page
        let lowerLimit = Math.max(2, page - 2);
        let upperLimit = Math.min(totalPages - 1, page + 2);

        if (page - 1 <= 3) {
            upperLimit = 5 < totalPages ? 5 : totalPages - 1;
        }

        if (totalPages - page <= 3) {
            lowerLimit = totalPages - 4 > 1 ? totalPages - 4 : 2;
        }

        if (lowerLimit > 2) {
            nums.push('...');
        }

        for (let i = lowerLimit; i <= upperLimit; i++) {
            nums.push(i);
        }

        if (totalPages > upperLimit + 1) {
            nums.push('...');
        }

        // Always add the last page if we have more than one page
        if (totalPages > 1) {
            nums.push(totalPages);
        }

        setPageNumbers(nums);
    }, [props.page, props.totalPages]); // Include props.page in the dependencies array

    // Handle page change
    const goToPage = (newPage) => {
        if (newPage > 0 && newPage <= props.totalPages) {
            props.setPage(newPage);
            props.setShouldUpdatePage(true);
        }
    };

    return (
        <div id="pagination" className="flex h-center">
            <button onClick={() => goToPage(props.page - 1)} disabled={props.page === 1}>
                Prev
            </button>
            {pageNumbers.map((number, index) =>
                number === '...' ? (
                    <span key={"ellipse-" + index}>...</span> // Use index as key for non-unique items like ellipses
                ) : (
                    <button
                        key={"page-" + index}
                        onClick={() => goToPage(number)}
                        className={`pagination-button ${props.page === number ? 'current' : ''}`}
                    >
                        {number}
                    </button>
                )
            )}
            <button onClick={() => goToPage(props.page + 1)} disabled={props.page === props.totalPages}>
                Next
            </button>
        </div>
    );
}