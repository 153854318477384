import React, { useState, useEffect, useRef } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import HCaptcha from "@hcaptcha/react-hcaptcha";
// Zipcode
import { zipCodes, cities, districts } from "use-tw-zipcode";
// Footer
import Footer from "../../../widgets/Footer/Footer";
// Pop up
import PopUp from "../../../widgets/PopUps/PopUps";
// Helper
import { getCookie, clearCookie } from "../../../utils/Cookie";
// Icons
import { BsFillPersonFill } from "react-icons/bs";
import { HiOutlineEye, HiOutlineEyeOff } from "react-icons/hi";
import { AiFillCaretDown, AiFillCaretUp } from "react-icons/ai";
import { MdLibraryAdd, MdRemove } from "react-icons/md";
// Css
import "./userInfo.scss";

export default function UserInfo(props) {
  const navigate = useNavigate();

  // [ Mobile ]
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 925);
  // [ Captcha ]
  const [token, setToken] = useState(null);
  // [ Pop up ]
  const [showPopUp, setShowPopUp] = useState(false);
  const [popUpType, setPopUpType] = useState(false);
  // [ Error ]
  const [error, setError] = useState(null);
  // [ Auth ]
  const [validAuth, setValidAuth] = useState(false);
  // [ User info ]
  // Info
  const [userInfo, setUserInfo] = useState({});
  const [data, setData] = useState("");
  // User name
  const [userName, setUserName] = useState("");
  // Password
  const [showOldPassword, setShowOldPassword] = useState(false);
  const [oldPassword, setOldPassword] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const password = useRef("");
  const checkPassword = useRef("");
  // Company
  const [company, setCompany] = useState("");
  // Full name
  const [fullName, setFullName] = useState("");
  // gender
  const [gender, setGender] = useState("");
  // dob
  const [dob, setDob] = useState("");
  // email
  const [email, setEmail] = useState("");
  // Phone
  const [lines, setLines] = useState(1);
  const [phoneType, setPhoneType] = useState(["手機"]);
  const [updatePhoneType, setUpdatePhoneType] = useState(false);
  const [phoneNumber, setPhoneNumber] = useState([""]);
  const [updatePhoneNumber, setUpdatePhoneNumber] = useState(false);
  // Address
  const [address, setAddress] = useState("");
  const [city, setCity] = useState("");
  const [district, setDistrict] = useState("");
  const [zipcode, setZipcode] = useState("--");
  // Need verification
  const [needVerify, setNeedVerify] = useState(false);
  // Validation
  const [isValid, setIsValid] = useState({
    oldPassword: true,
    password: [true, true],
    passwordValidInfo: "",
    fullName: true,
    dob: true,
    email: true,
    phoneNumber: [true],
    address: true,
    city: true,
    district: true,
  });
  // [ Dropdowns ]
  // Gender
  const [showGenderDropdown, setShowGenderDropdown] = useState(false);
  // Phone type
  const [showTypeDropdown, setShowTypeDropdown] = useState(false);
  const [typeDropdownIndex, setTypeDropdownIndex] = useState(null);
  // City
  const [showCityDropdown, setShowCityDropdown] = useState(false);
  // District
  const [showDistrictDropdown, setShowDistrictDropdown] = useState(false);
  // [ Event listener ]
  const [click, setClick] = useState(null);
  const [enter, setEnter] = useState(false);
  // [ Hint ]
  const [hint, setHint] = useState(null);

  // Env
  const contactEmail = process.env.REACT_APP_CONTACT_EMAIL;
  const environment = process.env.REACT_APP_ENVIRONMENT;

  useEffect(() => {
    // Check for auth
    const info = getCookie();
    setUserInfo(info);

    if (info) {
      callGetUserInfoEndpoint(info);
    } else {
      setShowPopUp(true);
      setPopUpType(1);
    }

    // Event listener - mouse click
    const handleClick = (event) => {
      setClick(event);
    };
    const keyDownHandler = (event) => {
      if (event.key === "Enter") {
        setEnter(true);
      }
    };

    document.addEventListener("click", handleClick);
    document.addEventListener("keydown", keyDownHandler);
    window.addEventListener('resize', handleResize);

    return () => {
      document.removeEventListener("click", handleClick);
      document.removeEventListener("keydown", keyDownHandler);
      window.removeEventListener('resize', handleResize);
    };
  }, []);
  // Phone input change
  useEffect(() => {
    // Phone type
    if (updatePhoneType) {
      setUpdatePhoneType(false);
    }

    // Phone number
    if (updatePhoneNumber) {
      setUpdatePhoneNumber(false);
    }

    // Click event
    if (click) {
      hideDropdowns(click);
      setClick(null);
    }

    // Enter key press event
    if (enter) {
      handleSubmit();
      setEnter(false);
    }
  }, [updatePhoneType, updatePhoneNumber, click, enter]);

  // Handle window resize
  const handleResize = () => {
    setIsMobile(window.innerWidth <= 925);
  };

  // Validation
  const validation = () => {
    let allValid = true;
    const newValid = isValid;

    for (const key of Object.keys(isValid)) {
      // Password
      if (key === "oldPassword" || key === "passwordValidInfo") {
        continue;
      } else if (key === "password") {
        if (oldPassword === "" && password.current.value === "" && checkPassword.current.value === "") {
          continue;
        }

        if (oldPassword === "") {
          allValid = false;
          newValid.oldPassword = false;
          newValid.passwordValidInfo = "請輸入舊密碼";
        }

        if (password.current.value === "") {
          allValid = false;
          newValid[key][0] = false;
          newValid.passwordValidInfo = "請輸入新密碼";
        }

        if (checkPassword.current.value === "") {
          allValid = false;
          newValid[key][1] = false;
          newValid.passwordValidInfo = password.current.value === "" ? newValid.passwordValidInfo : "請再次輸入新密碼";
          continue;
        }

        if (password.current.value.length < 8 || password.current.value.length > 20) {
          allValid = false;
          newValid[key] = [false, false];
          newValid.passwordValidInfo = "您的密碼長度必須在8-20之間";
          continue;
        }

        if (password.current.value.search(/[a-z]/) < 0) {
          allValid = false;
          newValid[key] = [false, false];
          newValid.passwordValidInfo = "您的密碼至少需要包含一個小寫字母";
          continue;
        }

        if (password.current.value.search(/[A-Z]/) < 0) {
          allValid = false;
          newValid[key] = [false, false];
          newValid.passwordValidInfo = "您的密碼至少需要包含一個大寫字母";
          continue;
        }

        if (password.current.value.search(/[0-9]/) < 0) {
          allValid = false;
          newValid[key] = [false, false];
          newValid.passwordValidInfo = "您的密碼至少需要包含一個數字";
          continue;
        }

        if (password.current.value.search(/[!@#$%^&*()_+]/) < 0) {
          allValid = false;
          newValid[key] = [false, false];
          newValid.passwordValidInfo = "您的密碼至少需要包含一個特殊字符";
          continue;
        }

        if (password.current.value !== checkPassword.current.value) {
          allValid = false;
          newValid[key] = [false, false];
          newValid.passwordValidInfo = "您輸入的密碼不一致";
        } else {
          newValid[key] = [true, true];
          newValid.passwordValidInfo = "";
        }
      }
      // DOB
      else if (key === "dob") {
        const validStartTime = new Date();
        validStartTime.setFullYear(validStartTime.getFullYear() - 160);

        if (
          dob.length === 0 ||
          new Date(dob) > new Date() ||
          new Date(dob) < new Date(validStartTime)
        ) {
          allValid = false;
          newValid[key] = false;
        } else {
          newValid[key] = true;
        }
      }
      // Email
      else if (key === "email") {
        if (/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(email)) {
          newValid[key] = true;
        } else {
          allValid = false;
          newValid[key] = false;
        }
      }
      // Phone number
      else if (key === "phoneNumber") {
        for (let i = 0; i < phoneNumber.length; i++) {
          let regex = null;

          if (phoneType[i] === "市話") {
            regex = /^(2|3|4|5|6|7|8)[-\s]?[0-9]{3,4}[-\s]?[0-9]{4}$/;
          } else if (phoneType[i] === "手機") {
            regex = /^9[-\s]?[0-9]{2}[-\s]?[0-9]{3}[-\s]?[0-9]{3}$/;
          }
          // Skip check if doesn't specify type
          else {
            continue;
          }

          if (!regex.test(phoneNumber[i])) {
            allValid = false;
            newValid[key][i] = false;
          } else {
            newValid[key][i] = true;
          }
        }
      }
      // Full name, Address, City, District
      else {
        let field = null;

        if (key === "fullName") {
          field = fullName;
        } else if (key === "address") {
          field = address;
        } else if (key === "city") {
          field = city;
        } else if (key === "district") {
          field = district;
        }

        if (field === "") {
          allValid = false;
          newValid[key] = false;
        } else {
          newValid[key] = true;
        }
      }
    }
    setIsValid(newValid);

    return allValid;
  };
  const determineInvalid = (field, index) => {
    // Password & Phone
    if (index !== undefined) {
      return isValid[field][index] ? "" : " invalid-input";
    }
    // Zipcode
    else if (field === "zipcode") {
      return isValid.city && isValid.district ? "" : " invalid-input";
    }
    // Others
    else {
      return isValid[field] ? "" : " invalid-input";
    }
  };

  const getInvalidMsg = (key, field) => {
    // Password
    if (key === "password") {
      return isValid.passwordValidInfo;
    }
    // DOB
    else if (key === "dob") {
      const { value } = dob.current;
      const validStartTime = new Date();
      validStartTime.setFullYear(validStartTime.getFullYear() - 160);

      if (
        new Date(value) > new Date() ||
        new Date(value) < new Date(validStartTime)
      ) {
        return `請輸入合理的${field}`;
      } else {
        return isValid[key] ? "" : `請輸入${field}`;
      }
    }
    // Email
    else if (key === "email") {
      return isValid[key] || error
        ? ""
        : `請輸入${field}或確認您的格式是否正確`;
    }
    // Phone number
    else if (key === "phoneNumber") {
      let allValid = true;
      isValid[key].forEach((elmnt) => {
        if (!elmnt) {
          allValid = false;
        }
      });
      return allValid ? "" : `請輸入${field}或確認您的格式是否正確`;
    }
    // Zipcode
    else if (key === "zipcode") {
      return isValid.city && isValid.district ? "" : `請輸入${field}`;
    }
    // Others
    else {
      return isValid[key] ? "" : `請輸入${field}`;
    }
  };

  // Save current change
  async function handleSubmit() {
    // Validation
    const valid = validation();

    if (valid) {
      if (environment === "prod") {
        const data =
          `secret=${process.env.REACT_APP_CAPTCHA_SECRET_KEY}` +
          `&response=${token}`;
        await axios
          .post(process.env.REACT_APP_CAPTCHA_VERIFY_URL, data, {
            headers: { "x-api-key": process.env.REACT_APP_API_KEY },
          })
          .then((res) => {
            const { success } = res.data;

            if (success) {
              callUpdateUserInfoEndpoint();
            } else {
              setError(
                "請完成驗證, 如果您認為有問題, 請與我們聯繫: " + contactEmail
              );
            }
          })
          .catch((err) => {
            console.log(err);
          });
      } else {
        callUpdateUserInfoEndpoint();
      }
    }
  };

  // Reset states
  const handleCancel = () => {
    updateUserInfo(data);
    setShowPassword(false);
    setOldPassword("");
    setIsValid({
      oldPassword: true,
      password: [true, true],
      fullName: true,
      dob: true,
      email: true,
      phoneNumber: [true],
      address: true,
      city: true,
      district: true,
    });
    setError(null);
    setHint(null);
    setNeedVerify(false);
  };

  // Format phone
  const formatPhone = (phoneNumber) => {
    return phoneNumber.map((item) => {
      let format = item.replaceAll("-", "");
      format = format.replaceAll(" ", "");
      return format;
    });
  };

  // Endpoint
  async function callGetUserInfoEndpoint(info) {
    await axios
      .post(
        `${process.env.REACT_APP_API}/accountInfo/getInfo`,
        {
          userName: info.user,
        },
        {
          headers: {
            "x-access-token": info.x,
            "x-api-key": process.env.REACT_APP_API_KEY
          },
        }
      )
      .then((res) => {
        if (res.status === 200) {
          props.setUserFullName(info.name);
          props.setSignedIn(true);
          setValidAuth(true);

          // Update user info
          setData(res.data);
          updateUserInfo(res.data);
        }
      })
      .catch(() => {
        setShowPopUp(true);
        setPopUpType(1);
      });
  }
  async function callUpdateUserInfoEndpoint() {
    const info = {
      userName,
      oldPassword: oldPassword === "" ? undefined : oldPassword,
      password: password.current.value ? undefined : password.current.value,
      fullName,
      gender,
      company,
      dob,
      phoneType,
      phoneNumber: formatPhone(phoneNumber),
      email,
      zipcode,
      city,
      district,
      address,
    };

    setError(null);
    await axios
      .post(`${process.env.REACT_APP_API}/accountInfo/updateInfo`, info, {
        headers: {
          "x-access-token": userInfo.x,
          "x-api-key": process.env.REACT_APP_API_KEY
        },
      })
      .then((res) => {
        if (res.status === 204) {
          setIsValid((old) => ({
            ...old,
            oldPassword: false,
          }));
          setError("密碼錯誤 (請注意: 多次輸入錯誤的密碼會使您的帳號被鎖住)");
        } else if (res.status === 200) {
          if (res.data.needVerify) {
            setNeedVerify(true);
            setPopUpType(1);
          } else {
            setPopUpType(2);
          }
          setShowPopUp(true);
        }
      })
      .catch((err) => {
        if (err.response && err.response.status === 429) {
          setIsValid((old) => ({
            ...old,
            oldPassword: false,
          }));
          setError(err.response.data);
        } else if (err.response && err.response.status === 409) {
          setIsValid((old) => {
            old.email = false;
            return old;
          });
          setError("此信箱已被註冊");
        } else {
          setError(
            "網頁錯誤, 目前無法更新您的資料, 請稍後重試, 或與我們聯絡: " +
            contactEmail
          );
          console.log(err)
        }
      });
  }

  // Update user info
  const updateUserInfo = (data) => {
    const { info, phones } = data;

    setUserName(info.userName);
    setFullName(info.fullName);
    setGender(info.gender);
    setDob(info.dob);
    setCompany(info.company);
    setEmail(info.email);
    setZipcode(info.zipcode);
    setCity(info.city);
    setDistrict(info.district);
    setAddress(info.address);

    // Phones
    const phoneTs = [];
    const phoneNs = [];
    phones.forEach((item) => {
      let num = item.number;
      if (item.type === "手機") {
        num = num.slice(0, 3) + "-" + num.slice(3, 6) + "-" + num.slice(6, 9);
      } else {
        num = num[0] + " " + num.slice(1, -4) + "-" + num.slice(-4);
      }

      phoneTs.push(item.type);
      phoneNs.push(num);
    });
    setLines(phones.length);
    setPhoneType(phoneTs);
    setPhoneNumber(phoneNs);
  };

  // Pop up
  const popUpContent1 = () => {
    return (
      <a>
        {needVerify
          ? "資料更新成功, 由於您註冊了新的email, 請驗證後重新登入"
          : "抱歉, 您的登入驗證已過期, 請重新登入"}
      </a>
    );
  };
  const popUpContent2 = () => {
    return <a>資料更新成功</a>;
  };
  const hidePopUp1 = () => {
    // Clear cookies
    const time = new Date().toUTCString();
    clearCookie("name", "", time);
    clearCookie("user", "", time);
    clearCookie("x", "", time);
    // Sign out
    props.setSignedIn(false);
    props.setUserFullName(null);
    props.setShowSignInAnimation(true);
    setError(null);
    setNeedVerify(false);
    setPopUpType(null);
    setShowPopUp(false);
    // Go home page
    navigate("/");
  };
  const hidePopUp2 = () => {
    // Reset states
    setShowPassword(false);
    setOldPassword("");
    setShowPopUp(false);
    setError(null);
    setPopUpType(null);
    setNeedVerify(false);
  };

  // Phone
  const getLine = () => {
    const list = [];

    for (let i = 0; i < lines; i++) {
      list.push(
        <div
          key={"phone-line" + i}
          className="flex h-between v-start phone-line"
        >
          <div key={"phone-input" + i} className="flex h-between phone-input">
            <div key={"phone_div" + i} className="flex-column phone-item edit">
              <div className="flex h-between v-center line-sub-div">
                <div className="flex-column line-sub-wrap">
                  <a key={"phone_title" + i} className="title">
                    類型
                    <span>*</span>
                  </a>
                  <div
                    key={"phone-type-dropdown" + i}
                    className="flex-column dropdown"
                  >
                    <div
                      key={"phone-type-dropdown-text-div" + i}
                      className="flex h-between v-center dropdown-text"
                    >
                      <a
                        key={"phone-type-dropdown-text" + i}
                        id="dropdown-type-text"
                        onClick={() => toggleTypeDropdown(i)}
                      >
                        {phoneType[i]}
                      </a>
                      {showTypeDropdown && typeDropdownIndex == i ? (
                        <AiFillCaretUp
                          id="dropdown-type-icon"
                          onClick={() => toggleTypeDropdown(i)}
                        />
                      ) : (
                        <AiFillCaretDown
                          id="dropdown-type-icon"
                          onClick={() => toggleTypeDropdown(i)}
                        />
                      )}
                    </div>
                    <div
                      key={"phone-type-dropdown-content" + i}
                      id="dropdown-type-content"
                      className={`flex-column dropdown-content${showTypeDropdown && typeDropdownIndex == i ? " active" : ""}`}
                    >
                      <a
                        key={"phone-type-dropdown-content-phone" + i}
                        onClick={() => {
                          setPhoneType((old) => {
                            old[i] = "手機";
                            return old;
                          });
                          setUpdatePhoneType(true);
                        }}
                      >
                        手機
                      </a>
                      <a
                        key={"phone-type-dropdown-content-landline" + i}
                        onClick={() => {
                          setPhoneType((old) => {
                            old[i] = "市話";
                            return old;
                          });
                          setUpdatePhoneType(true);
                        }}
                      >
                        市話
                      </a>
                    </div>
                  </div>
                </div>
                <div className={`flex v-end phone-input-wrap${determineInvalid("phoneNumber", i)}`}>
                  <div className="flex-column phone-country-code">
                    <a key={"phone_title_886" + i} className="title">
                      電話
                      <span>*</span>
                    </a>
                    <input
                      value="(+886)"
                      disabled
                    />
                  </div>
                  <input
                    key={"phone_input_number" + i}
                    className="phone-number"
                    type="text"
                    value={phoneNumber[i]}
                    onChange={(event) => {
                      setPhoneNumber((arr) => {
                        arr[i] = event.target.value;
                        return arr;
                      });
                      setUpdatePhoneNumber(true);
                    }}
                  />
                </div>
                {i === 0 ? (
                  <MdLibraryAdd
                    key={"phone-icon" + i}
                    className="icon icon-add"
                    onClick={() => {
                      updatePhoneLine("add");
                    }}
                  />
                ) : (
                  <MdRemove
                    key={"phone-icon" + i}
                    className="icon icon-remove"
                    onClick={() => {
                      updatePhoneLine("delete", i);
                    }}
                  />
                )}
              </div>
            </div>
          </div>
        </div>
      );
    }

    return list;
  };
  const updatePhoneLine = (action, index) => {
    if (action === "add") {
      setLines(lines + 1);
      setPhoneType((old) => {
        old.push("手機");
        return old;
      });
      setPhoneNumber((old) => {
        old.push("");
        return old;
      });
      setIsValid((old) => {
        old.phoneNumber.push(true);
        return old;
      });
    } else {
      setLines(lines - 1);
      setPhoneType((old) => {
        old.splice(index, 1);
        return old;
      });
      setPhoneNumber((old) => {
        old.splice(index, 1);
        return old;
      });
      setIsValid((old) => {
        old.phoneNumber.splice(index, 1);
        return old;
      });
    }
  };

  // City
  const getCities = () => {
    const list = [];

    for (let i = 0; i < cities.length; i++) {
      list.push(
        <a
          key={"city-dropdown-content-text" + i}
          onClick={() => {
            setCity(cities[i]);
            setDistrict("");
            setZipcode("--");
          }}
        >
          {cities[i]}
        </a>
      );
    }

    return (
      <div className="flex-column dropdown column">
        <a className="flex v-center title">
          縣市
          <span>*</span>
        </a>
        <div
          className={
            "flex h-between v-center dropdown-text" + determineInvalid("city")
          }
        >
          <a
            id="dropdown-city-text"
            onClick={() => setShowCityDropdown(!showCityDropdown)}
          >
            {city === "" ? "--" : city}
          </a>
          {showCityDropdown ? (
            <AiFillCaretUp
              id="dropdown-city-icon"
              onClick={() => setShowCityDropdown(!showCityDropdown)}
            />
          ) : (
            <AiFillCaretDown
              id="dropdown-city-icon"
              onClick={() => setShowCityDropdown(!showCityDropdown)}
            />
          )}
        </div>
        <div
          id="dropdown-city-content"
          className={`flex-column dropdown-content${showCityDropdown ? " active" : ""}`}
        >
          {list}
        </div>
      </div>
    );
  };

  // Distrct
  const getDistrict = () => {
    const list = [];
    const districtList = districts[city] ? districts[city] : [];

    for (let i = 0; i < districtList.length; i++) {
      list.push(
        <a
          key={"district-dropdown-content-text" + i}
          onClick={() => {
            setDistrict(districtList[i]);
            setZipcode(zipCodes[city][districtList[i]]);
          }}
        >
          {districtList[i]}
        </a>
      );
    }

    return (
      <div className="flex-column dropdown column">
        <a className="flex v-center title">
          行政區
          <span>*</span>
        </a>
        <div className={"flex h-between v-center dropdown-text" + determineInvalid("district")}>
          <a
            id="dropdown-district-text"
            style={{ cursor: districtList.length > 0 ? "pointer" : "auto" }}
            onClick={() => setShowDistrictDropdown(districtList.length > 0 ? !showDistrictDropdown : false)}
          >
            {district === "" ? "--" : district}
          </a>
          {districtList.length > 0 &&
            (showDistrictDropdown ? (
              <AiFillCaretUp
                id="dropdown-district-icon"
                onClick={() => setShowDistrictDropdown(!showDistrictDropdown)}
              />
            ) : (
              <AiFillCaretDown
                id="dropdown-district-icon"
                onClick={() => setShowDistrictDropdown(!showDistrictDropdown)}
              />
            ))}
        </div>
        <div
          id="dropdown-district-content"
          className={`flex-column dropdown-content${showDistrictDropdown ? " active" : ""}`}
        >
          {list}
        </div>
      </div>
    );
  };

  // Dropdowns
  const toggleTypeDropdown = (i) => {
    if (typeDropdownIndex === i) {
      setShowTypeDropdown(!showTypeDropdown);
    } else {
      setShowTypeDropdown(true);
    }
    setTypeDropdownIndex(i);
  };
  const hideDropdowns = (event) => {
    const { id } = event.target;
    const parentId = event.target.parentElement
      ? event.target.parentElement.id
      : null;

    // Gender dropdowns
    if (
      showGenderDropdown &&
      id !== "dropdown-gender-text" &&
      id !== "dropdown-gender-content" &&
      id !== "dropdown-gender-icon" &&
      parentId !== "dropdown-gender-icon"
    ) {
      setShowGenderDropdown(false);
    }

    // Phone type dropdowns
    if (
      showTypeDropdown &&
      id !== "dropdown-type-text" &&
      id !== "dropdown-type-content" &&
      id !== "dropdown-type-icon" &&
      parentId !== "dropdown-type-icon"
    ) {
      setShowTypeDropdown(false);
      setTypeDropdownIndex(null);
    }

    // City dropdown
    if (
      showCityDropdown &&
      id !== "dropdown-city-text" &&
      id !== "dropdown-city-content" &&
      id !== "dropdown-city-icon" &&
      parentId !== "dropdown-city-icon"
    ) {
      setShowCityDropdown(false);
    }

    // District dropdown
    if (
      showDistrictDropdown &&
      id !== "dropdown-district-text" &&
      id !== "dropdown-district-content" &&
      id !== "dropdown-district-icon" &&
      parentId !== "dropdown-district-icon"
    ) {
      setShowDistrictDropdown(false);
    }
  };

  return (
    <div id="page-user-info" className="flex-column v-center page">
      <div className="flex-column v-center page-content">
        {validAuth && (
          <div className="flex-column v-center page-content-main">
            <BsFillPersonFill className="main-icon" />
            <div className="flex-column content">
              <div className="flex h-center v-center page-main-title">
                <h2>個人資料</h2>
              </div>
              <div className="flex-column field account-field">
                <div className="flex v-center main-title">
                  <a>帳號</a>
                </div>
                <div
                  className="flex-column"
                >
                  {/* 帳號 */}
                  <div className="flex-column">
                    <a className="title">
                      帳號
                      <span>*</span>
                    </a>
                    <input
                      className="input"
                      type="text"
                      value={userName}
                      disabled
                    />
                  </div>
                  {/* 修改密碼 */}
                  <div className="flex password">
                    <div className="flex-column password-content">
                      <div className="flex-column old-password">
                        <a className="title">舊密碼</a>
                        <input
                          className={
                            "password-input" + determineInvalid("oldPassword")
                          }
                          type={showOldPassword ? "text" : "password"}
                          value={oldPassword}
                          onChange={(event) => setOldPassword(event.target.value)}
                        />
                        {!showOldPassword ? (
                          <HiOutlineEyeOff
                            onClick={() => setShowOldPassword(!showOldPassword)}
                          />
                        ) : (
                          <HiOutlineEye
                            onClick={() => setShowOldPassword(!showOldPassword)}
                          />
                        )}
                      </div>
                      <div className="flex v-center new-password">
                        <div className="flex-column">
                          <a className="title">新密碼</a>
                          <input
                            className={
                              "password-input" + determineInvalid("password", 0)
                            }
                            type={showPassword ? "text" : "password"}
                            ref={password}
                          />
                        </div>
                        <div className="flex-column">
                          <a className="title">確認密碼</a>
                          <input
                            className={determineInvalid("password", 1)}
                            type={showPassword ? "text" : "password"}
                            ref={checkPassword}
                          />
                        </div>
                      </div>
                      {!showPassword ? (
                        <HiOutlineEyeOff
                          onClick={() => setShowPassword(!showPassword)}
                        />
                      ) : (
                        <HiOutlineEye
                          onClick={() => setShowPassword(!showPassword)}
                        />
                      )}
                    </div>
                  </div>
                </div>
                {hint && <a className="password-msg">{hint}</a>}
                <a className="invalid-msg">{getInvalidMsg("password", "密碼")}</a>
              </div>
              <div className="flex-column field">
                <div className="flex v-center main-title">
                  <a>
                    會員資料
                  </a>
                </div>
                {/* 公司名稱 */}
                <div className="flex-column">
                  <a className="title">公司名稱</a>
                  <input
                    type="text"
                    value={company ? company : ""}
                    onChange={(event) => setCompany(event.target.value)}
                    placeholder={company === "" ? "(無)" : ""}
                  />
                </div>
                {/* 會員姓名 */}
                <div className="flex-column">
                  <a className="title">
                    會員姓名
                    <span>*</span>
                  </a>
                  <input
                    className={determineInvalid("fullName")}
                    type="text"
                    value={fullName}
                    onChange={(event) => setFullName(event.target.value)}
                  />
                </div>
                <div className="flex h-between row">
                  {/* 性別 */}
                  <div className="flex-column gender">
                    <a className="flex title">
                      性別
                      <span>*</span>
                    </a>
                    <div className="flex-column dropdown">
                      <div className="flex h-between v-center dropdown-text">
                        <a
                          id="dropdown-gender-text"
                          onClick={() => setShowGenderDropdown(!showGenderDropdown)}
                        >
                          {gender === "小姐" ? "女士" : gender}
                        </a>
                        {showGenderDropdown ? (
                          <AiFillCaretUp
                            id="dropdown-gender-icon"
                            onClick={() => setShowGenderDropdown(!showGenderDropdown)}
                          />
                        ) : (
                          <AiFillCaretDown
                            id="dropdown-gender-icon"
                            onClick={() => setShowGenderDropdown(!showGenderDropdown)}
                          />
                        )}
                        <div
                          id="dropdown-gender-content"
                          className={`flex-column dropdown-content${showGenderDropdown ? " active" : ""}`}
                        >
                          <a
                            onClick={() => {
                              setGender("先生");
                              setShowGenderDropdown(!showGenderDropdown);
                            }}
                          >
                            先生
                          </a>
                          <a
                            onClick={() => {
                              setGender("小姐");
                              setShowGenderDropdown(!showGenderDropdown);
                            }}
                          >
                            女士
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                  {/* 生日 */}
                  <div className="flex-column dob">
                    <a className="flex title">
                      生日
                      <span>*</span>
                    </a>
                    <input
                      className={determineInvalid("dob")}
                      type="date"
                      value={dob}
                      onChange={(event) => setDob(event.target.value)}
                    />
                  </div>
                </div>
              </div>
              <div className="flex-column field">
                <div className="flex-column main-title">
                  <a>聯絡資訊</a>
                </div>
                {/* 聯絡電話 */}
                <div className="flex row phones">
                  <div className="flex-column line-div">{getLine()}</div>
                </div>
                <div className="flex row">
                  {/* 聯絡信箱 */}
                  <div className="flex-column email">
                    <a className="title">
                      聯絡信箱
                      <span>*</span>
                    </a>
                    <input
                      className={determineInvalid("email")}
                      type="text"
                      value={email}
                      onChange={(event) => setEmail(event.target.value)}
                    />
                  </div>
                </div>
                {/* 聯絡地址 */}
                <div className="flex-column address">
                  <a className="flex v-center title">聯絡地址</a>
                  <input
                    className={
                      "input-address" + determineInvalid("address")
                    }
                    type="text"
                    value={address}
                    onChange={(event) => setAddress(event.target.value)}
                  />
                </div>
                <div className="flex row v-center address">
                  <div className="flex-column address-content">
                    <div className="flex h-between v-center address-detail">
                      {getCities()}
                      {getDistrict()}
                      <div className="flex-column column">
                        <a className="flex v-center title">
                          郵遞區號
                          <span>*</span>
                        </a>
                        <input
                          className={
                            "input-zipcode" + determineInvalid("zipcode")
                          }
                          type="text"
                          value={zipcode}
                          onChange={(event) => setZipcode(event.target.value)}
                          disabled
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div className="flex-column v-center">
                  {error !== null && <a className="error-msg">{error}</a>}
                  <div className="flex h-center captcha">
                    <HCaptcha
                      size={isMobile ? "compact" : "normal"}
                      sitekey={process.env.REACT_APP_CAPTCHA_SITE_KEY}
                      onError={() =>
                        setError("網頁錯誤, 請刷新頁面或與我們聯絡: " + contactEmail)
                      }
                      onExpire={() => setError("驗證過期, 請刷新頁面")}
                      onVerify={setToken}
                    />
                  </div>
                  <button
                    className="button1 submit"
                    style={{ marginTop: error === null ? "5px" : "13px" }}
                    onClick={handleSubmit}
                  >
                    保存
                  </button>
                  <a className="cancel" onClick={handleCancel}>
                    取消
                  </a>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
      <Footer />
      {showPopUp && (
        <div id="sign-out">
          <PopUp
            popUpContent={popUpType === 1 ? popUpContent1 : popUpContent2}
            hidePopUp={popUpType === 1 ? hidePopUp1 : hidePopUp2}
          />
        </div>
      )}
    </div>
  );
}
