import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
// Helper
import { getCookie, setCookie } from "../../../utils/Cookie";
// Pop up
import PopUp from "../../../widgets/PopUps/PopUps";

export default function SignInLine(props) {
  const navigate = useNavigate();
  // Full name
  const [fullName, setFullName] = useState("");
  // [ Pop up ]
  const [showPopUp, setShowPopUp] = useState(false);
  const [popUpType, setPopUpType] = useState(null);
  // [ Social ]
  const [socialType, setSocialType] = useState(null);
  const [socialInfo, setSocialInfo] = useState(null);

  useEffect(() => {
    getAccessToken();
  }, []);

  const getAccessToken = () => {
    let code = null;

    const token = {};
    const { href } = window.location;
    let query = href.split("?")[1];
    query = query.split("&");

    for (const item of query) {
      const pair = item.split("=");
      if (pair[0] === "code") {
        token[pair[0]] = pair[1];
        break;
      }
    }

    if (token.code) {
      // Cookie
      const time = new Date(
        Date.now() + 20 * 60 * 1000
      ).toUTCString();
      setCookie("lt", token.code, time);
      code = token.code;

      // Refresh
      navigate("/line");
    }

    if (code) {
      getLineToken(code);
    } else {
      // Go to home page
      navigate("/");
    }
  };

  async function getLineToken(code) {
    const cookie = getCookie();

    await axios
      .post(
        "https://api.line.me/oauth2/v2.1/token",
        `grant_type=authorization_code&` +
        `code=${code}&` +
        `redirect_uri=${process.env.REACT_APP_DOMAIN_URL}/line&` +
        `client_id=${process.env.REACT_APP_LINE_APP_IP}&` +
        `client_secret=${process.env.REACT_APP_LINE_APP_SECRET_IP}`,
        {
          headers: { "Content-Type": "application/x-www-form-urlencoded" },
        }
      )
      .then((res) => {
        callSignInWithSocialEndpoint(res.data.id_token, "line", cookie);
        setCookie("lt", null, new Date().toUTCString());
      })
      .catch((err) => {
        console.error(err);
      });
  }

  async function callSignInWithSocialEndpoint(token, source, cookie) {
    await axios
      .post(`${process.env.REACT_APP_API}/accounts/signInWithSocial`, {
        token,
        source,
        cart: cookie && cookie.cart ? cookie.cart : null,
      }, {
        headers: { "x-api-key": process.env.REACT_APP_API_KEY },
      })
      .then((res) => {
        // Sign in
        if (res.status === 200) {
          // Not register - go to sign up page
          if (res.data.email) {
            setPopUpType(2);
            setShowPopUp(true);
            setSocialType(source);
            setSocialInfo({ email: res.data.email });
          }
          // Sign in success
          else {
            // Cookie
            const time = new Date(res.data.expires).toUTCString();
            setCookie("name", res.data.fullName, time);
            setCookie("user", res.data.userName, time);
            setCookie("x", res.headers["x-access-token"], time);

            // [ Cart ]
            const cart = res.data.cart.length > 0 ? res.data.cart[0] : null;
            if (cart && cart.id && cart.expires) {
              setCookie("cart", cart.id, new Date(cart.expires));
              // Update cart detail
              props.setCartQuantity(res.data.quantity);
              props.setRefreshCart(true);
            }

            // Show sign in complete pop up
            setFullName(res.data.fullName);
            setPopUpType(1);
            setShowPopUp(true);

            // Set social info
            props.setSocialInfo({ source });
          }
        }
      })
      .catch(() => {});
  }

  // Pop up
  const popUpContent1 = () => {
    return <a>登入成功!</a>;
  };
  const popUpContent2 = () => {
    return (
      <div className="redirect-to-enroll">
        <a>
          系統找不到您的註冊紀錄, 點擊下方確認鍵將跳轉至註冊頁面, 將用您的
          {socialType}帳號於本系統註冊。
        </a>
      </div>
    );
  };
  const hidePopUp1 = () => {
    setShowPopUp(false);
    props.setShowSignIn(false);
    props.setUserFullName(fullName);
    props.setSignedIn(true);
    navigate("/")
  };
  const hidePopUp2 = () => {
    const info = socialInfo;
    info.source = socialType;

    props.setShowSignIn(false);
    props.setShowSignUpSocial(true);
    props.setSocialInfo(info);
    setShowPopUp(false);
  };

  return (
    <div id="page-enroll">
      {showPopUp && (
        <PopUp
          popUpContent={popUpType === 1 ? popUpContent1 : popUpContent2}
          hidePopUp={popUpType === 1 ? hidePopUp1 : hidePopUp2}
        />
      )}
    </div>
  );
}
