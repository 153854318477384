import React, { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import axios from "axios";
import { Buffer } from "buffer";
// Footer
import Footer from "../../widgets/Footer/Footer";
// Icons
import { RiCloseFill } from "react-icons/ri";
import { MdRemove, MdAdd } from "react-icons/md";
import { VscTriangleRight } from "react-icons/vsc";
// Css
import "./cart.scss";

export default function Cart(props) {
  const navigate = useNavigate();

  // Cart
  const [updateQuantity, setUpdateQuantity] = useState(false);
  const [updateField, setUpdateField] = useState(false);
  // Deals
  const [dealsList, setDealsList] = useState([]);
  // Summary
  const [originalPrice, setOriginalPrice] = useState(0);
  const [save, setSave] = useState(0);
  const [total, setTotal] = useState(0);

  useEffect(() => {
    callGetCartEndpoint();
  }, []);

  useEffect(() => {
    if (props.refreshCart) {
      callGetCartEndpoint();
    }

    if (updateField) {
      callUpdateCartEndpoint();
      setUpdateField(false);
    }

    if (updateQuantity) {
      setUpdateQuantity(false);
    }
  }, [props.refreshCart, updateQuantity, updateField]);

  // Endpoint
  async function callGetCartEndpoint() {
    const cartId = getCartFromCookie();

    if (cartId) {
      await axios
        .get(`${process.env.REACT_APP_API}/carts/get?`, {
          params: { cartId },
          headers: { "x-api-key": process.env.REACT_APP_API_KEY },
        })
        .then((res) => {
          if (res.status === 200) {
            let originalAmount = 0;
            let totalAmount = 0;
            let saveAmount = 0;
            let deals = new Map();

            // Set quantity & total amount for each item
            res.data.forEach((item) => {
              originalAmount += item.quantity * item.price;
              totalAmount += item.quantity * item.sale;
              saveAmount += (item.price - item.sale) * item.quantity;

              item.deals.forEach((deal) => {
                deals.set(deal.id, deal.name)
              })
            });

            // Set original, total & save amount
            setOriginalPrice(originalAmount);
            setTotal(totalAmount);
            setSave(saveAmount);

            // Update cart detail
            props.setCart(res.data);
            props.setCartQuantity(res.data.length);
            props.setUpdateQuantity(true);

            // Set deals list
            deals = [...deals.values()]
            if (totalAmount >= 1000) {
              deals.push("消費滿NT$ 1,000免運")
            }
            setDealsList(deals);
          }
        })
        .catch(() => { });
    } else {
      props.setCart([]);
      props.setCartQuantity(0);
      props.setUpdateQuantity(true);
    }
  }
  async function callUpdateCartEndpoint() {
    const cartId = getCartFromCookie();
    const expires = new Date(new Date().getTime() + 7 * 24 * 60 * 60 * 1000);

    await axios
      .post(`${process.env.REACT_APP_API}/carts/update`, {
        cartId,
        cart: props.cart.map(item => ({
          productId: item.productId,
          colorId: item.colorId,
          size: item.size,
          quantity: item.quantity
        })),
        expires,
      }, {
        headers: { "x-api-key": process.env.REACT_APP_API_KEY },
      })
      .then((res) => {
        if (res.status === 200) {
          // Update cart quantity
          props.setRefreshCart(true);
        }
      })
      .catch(() => { });
  }

  // Get cart from cookie
  const getCartFromCookie = () => {
    let id = null;
    let { cookie } = document;
    cookie = cookie.split("; ");

    if (cookie.length > 0 && cookie[0] !== "") {
      for (let item of cookie) {
        item = item.split("=");
        if (item[0] === "cart") {
          id = item[1];
          break;
        }
      }
    }

    return id;
  };

  // Quantity
  const handleQuantity = (number, index) => {
    let newValue = null;
    number = parseInt(number);

    if (number < 1) {
      newValue = 1;
    } else if (number > 99) {
      newValue = 99;
    } else {
      newValue = number;
    }

    props.setCart((old) => {
      old[index].quantity = newValue;
      return old;
    });
    setUpdateQuantity(true);
    setUpdateField(true);
  };
  const handleQuantityInput = (event, index) => {
    const { value } = event.target;

    if (value === "") {
      props.setCart((old) => {
        old[index].quantity = value;
        return old;
      });
      setUpdateQuantity(true);
    } else if (/^[0-9]+$/.test(value)) {
      handleQuantity(value, index);
    }
  };

  // Delete item
  const deleteItem = (targetIndex) => {
    props.setCart((old) => {
      const newCart = [];
      old.forEach((item, index) => {
        if (index !== targetIndex) {
          newCart.push(item);
        }
      });

      return newCart;
    });
    setUpdateField(true);
  };

  // Go to product info page
  const goProductInfoPage = (index) => {
    const { productId, colorId } = props.cart[index];
    navigate("/product/" + productId + "/0?colorId=" + colorId);
  }

  // Get cart list
  const getCartList = () => {
    const list = [];

    if (props.cart.length > 0) {
      props.cart.forEach((item, index) => {
        const { name, model, colorName, price, sale, size, quantity, image } =
          item;

        list.push(
          <div key={"cart" + index} className="flex-column row item">
            <div key={"cart-row" + index} className="flex h-between v-center item-content">
              <div key={"cart-row" + index} className="flex item-content">
                <div key={"cart-content" + index} className="flex item-content">
                  <img
                    key={"cart-image" + index}
                    src={
                      image
                        ? `data:image/png;base64, ${new Buffer.from(image).toString(
                          "base64"
                        )}`
                        : null
                    }
                    onClick={() => goProductInfoPage(index)}
                  />
                  <div key={"cart-mobile-info" + index} className="flex-column h-center info">
                    {/* Product name & model */}
                    <div
                      key={"cart-name-div" + index}
                      className="flex v-center name"
                      onClick={() => goProductInfoPage(index)}
                    >
                      <a key={"cart-name" + index}>{`${name}`}</a>
                      <a key={"cart-model" + index}>{`(型號: ${model})`}</a>
                    </div>
                    {/* Color & Size */}
                    <div key={"cart-specs-div" + index} className="flex v-center specs-div">
                      <a key={"cart-specs-title" + index}>規格: </a>
                      <a key={"cart-specs-detail" + index}>{colorName} / {size}</a>
                    </div>
                    {/* Size & Quantity */}
                    <div key={"cart-price-div" + index} className="flex h-between price-div">
                      <div key={`cart-price-cont${index}`} className="flex v-center">
                        {sale !== price && (
                          <a key={"cart-original-price" + index} className="price">
                            ${(quantity * price).toLocaleString("en-US")}
                          </a>
                        )}
                        <a key={"cart-total" + index} className="sale">
                          ${(quantity * sale).toLocaleString("en-US")}
                        </a>
                      </div>
                      <div key={`cart-quantity-cont${index}`} className="flex quantity-cont">
                        <button
                          key={`cart-subtract-icon${index}`}
                          className="flex v-center"
                          onClick={() => handleQuantity(quantity - 1, index)}
                        >
                          <MdRemove />
                        </button>
                        <input
                          key={"cart-quantity" + index}
                          type="text"
                          value={quantity}
                          onChange={(event) => handleQuantityInput(event, index)}
                        />
                        <button
                          key={`cart-add-icon${index}`}
                          className="flex v-center"
                          onClick={() => handleQuantity(quantity + 1, index)}
                        >
                          <MdAdd />
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
                {/* Delete */}
                <RiCloseFill
                  key={"cart-delete" + index}
                  className="delete"
                  onClick={() => deleteItem(index)}
                />
              </div>
            </div>
          </div>
        );
      });
    } else {
      list.push(
        <div key="cart-no-item" className="flex h-center row no-item">
          購物車內無商品
        </div>
      );
    }

    return list;
  };

  // Get deals llist
  const getDealsList = () => {
    return dealsList.map((item, index) => (
      <div key={`deal-div${index}`} className="flex v-center deal">
        <div key={`deal${index}`} className="flex v-center">
          <a key={`deal-title${index}`}>活動</a>
          <VscTriangleRight />
        </div>
        <a key={`deal-content${index}`} className="text">{item}</a>
      </div>
    ))
  }

  return (
    <div id="page-cart" className="flex-column v-center page">
      <div className="flex-column v-center page-content">
        <div className="flex-column v-center content">
          <h2>購物車</h2>
          <div className="flex h-center cart-content">
            <div className="flex-column table">
              {/* Cart list */}
              {getCartList()}
              {/* Deals */}
              {props.cart.length > 0 &&
                <div className="flex deals-list">
                  <div className="flex-column deals-content">
                    {getDealsList()}
                  </div>
                </div>
              }
              {/* Summary - mobile */}
              <div className="flex-column row mobile summary">
                <div className="flex h-between v-center row">
                  <a className="no-wrap">小計:</a>
                  <a className="text">NT$ {originalPrice.toLocaleString("en-US")}</a>
                </div>
                <div className="flex h-between v-center row">
                  <a className="no-wrap save">折扣:</a>
                  <a className="text save">- NT$ {save.toLocaleString("en-US")}</a>
                </div>
                <div className="flex h-between v-center row total-div">
                  <a>合計:</a>
                  <a className="text">NT$ {total.toLocaleString("en-US")}</a>
                </div>
                <div className="flex h-center v-center row check-out">
                  {props.cart.length > 0 ? (
                    <Link to="/shipping-info" state={{ cart: props.cart }} className="button1">
                      立即結帳
                    </Link>
                  ) : (
                    <a className="button1 no-item">立即結帳</a>
                  )}
                </div>
              </div>
            </div>
            {/* Summary - desktop */}
            <div className="flex-column row desktop summary">
              <div className="flex h-between v-center row">
                <a className="no-wrap">小計:</a>
                <a className="text">NT$ {originalPrice.toLocaleString("en-US")}</a>
              </div>
              <div className="flex h-between v-center row">
                <a className="no-wrap save">折扣:</a>
                <a className="text save">-NT$ {save.toLocaleString("en-US")}</a>
              </div>
              <div className="divider"></div>
              <div className="flex h-between v-center row total-div">
                <a>合計:</a>
                <a className="text">NT$ {total.toLocaleString("en-US")}</a>
              </div>
              <div className="flex h-center v-center row check-out">
                {props.cart.length > 0 ? (
                  <Link to="/shipping-info" state={{ cart: props.cart }} className="button1">
                    立即結帳
                  </Link>
                ) : (
                  <a className="button1 no-item">立即結帳</a>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
}
