import React from "react";
// Footer
import Footer from "../../widgets/Footer/Footer";
// Css
import "./privacy.scss";

export default function Privacy() {
  // Contact email
  const contactEmail = process.env.REACT_APP_CONTACT_EMAIL;

  return (
    <div id="page-privacy" className="flex-column v-center page">
      <div className="flex-column v-center page-content">
        <div className="flex-column content">
          <h2>隱私權及網站使用條款</h2>
          {/* 隱私權保護政策的適用範圍 */}
          <h3>一、隱私權保護政策的適用範圍</h3>
          <a>
            隱私權保護政策內容，包括本網站如何處理在您使用網站服務時收集到的個人識別資料。隱私權保護政策不適用於本網站以外的相關連結網站，也不適用於非本網站所委託或參與管理的人員。
          </a>
          {/* 個人資料的蒐集、處理及利用方式 */}
          <h3>二、個人資料的蒐集、處理及利用方式</h3>
          <a>
            當您造訪本網站或使用本網站所提供之功能服務時，我們將視該服務功能性質，請您提供必要的個人資料，並在該特定目的範圍內處理及利用您的個人資料；非經您書面同意，本網站不會將個人資料用於其他用途。
            本網站在您使用服務信箱、問卷調查等互動性功能時，會保留您所提供的姓名、電子郵件地址、聯絡方式及使用時間等。
            於一般瀏覽時，伺服器會自行記錄相關行徑，包括您使用連線設備的IP位址、使用時間、使用的瀏覽器、瀏覽及點選資料記錄等，做為我們增進網站服務的參考依據，此記錄為內部應用，決不對外公佈。
            為提供精確的服務，我們會將收集的問卷調查內容進行統計與分析，分析結果之統計數據或說明文字呈現，除供內部研究外，我們會視需要公佈統計數據及說明文字，但不涉及特定個人之資料。
          </a>
          <a>
            當您於購物網站下訂單時，我們會運用您的個人資料來處理與完成訂購程序。我們可能會向您傳送Email以確認或更新訂單狀態。若我們對於您的訂單產生疑問，亦可能透過郵件、Email或電話的方式與您聯絡。我們亦會使用此個人資料回覆您詢問客服方面的問題。
          </a>
          <a>
            我們也會運用您的個人資料來經營與改進網站，提供您曾要求過的產品服務。若您下訂單、建立帳號、提供您的Email信箱、參加競賽活動、接收廣告函或網站其他促銷活動，我們均會運用您所提供的資料進行以下事項:
            於適用情況下，透過Email、平信或簡訊向您傳送關於產品、服務或您可能有興趣瞭解的其他競賽與促銷活動訊息；運用您的產品喜好、購物習慣及購物資料，向您推薦您可能有興趣的其他產品、服務或促銷活動
            (包括其他公司的訊息) ，以提供客製化的購物體驗。
          </a>
          {/* 資料之保護 */}
          <h3>三、資料之保護</h3>
          <a>
            本網站主機均設有防火牆、防毒系統等相關的各項資訊安全設備及必要的安全防護措施，加以保護網站及您的個人資料採用嚴格的保護措施，只由經過授權的人員才能接觸您的個人資料，相關處理人員皆簽有保密合約，如有違反保密義務者，將會受到相關的法律處分。
            如因業務需要有必要委託其他單位提供服務時，本網站亦會嚴格要求其遵守保密義務，並且採取必要檢查程序以確定其將確實遵守。
          </a>
          {/* 網站對外的相關連結 */}
          <h3>四、網站對外的相關連結</h3>
          <a>
            本網站的網頁提供其他網站的網路連結，您也可經由本網站所提供的連結，點選進入其他網站。但該連結網站不適用本網站的隱私權保護政策，您必須參考該連結網站中的隱私權保護政策。
          </a>
          {/* 與第三人共用個人資料之政策 */}
          <h3>五、與第三人共用個人資料之政策</h3>
          <a>
            本網站絕不會提供、交換、出租或出售任何您的個人資料給其他個人、團體、私人企業或公務機關，但有法律依據或合約義務者，不在此限。
          </a>
          <a>前項但書之情形包括不限於: </a>
          <div className="flex-column list">
            <li>經由您書面同意。</li>
            <li>法律明文規定。</li>
            <li>為免除您生命、身體、自由或財產上之危險。</li>
            <li>
              與公務機關或學術研究機構合作，基於公共利益為統計或學術研究而有必要，且資料經過提供者處理或蒐集著依其揭露方式無從識別特定之當事人。
            </li>
            <li>
              當您在網站的行為，違反服務條款或可能損害或妨礙網站與其他使用者權益或導致任何人遭受損害時，經網站管理單位研析揭露您的個人資料是為了辨識、聯絡或採取法律行動所必要者。
            </li>
            <li>有利於您的權益。</li>
            <li>
              本網站委託廠商協助蒐集、處理或利用您的個人資料時，將對委外廠商或個人善盡監督管理之責。
            </li>
          </div>
          {/* Cookie之使用 */}
          <h3>六、Cookie之使用</h3>
          <a>
            為了提供您最佳的服務，本網站會在您的電腦中放置並取用我們的Cookie，若您不願接受Cookie的寫入，您可在您使用的瀏覽器功能項中設定隱私權等級為高，即可拒絕Cookie的寫入，但可能會導致網站某些功能無法正常執行
            。
          </a>
          {/* 如何取消訂閱電子報或更改資料 */}
          <h3>七、如何取消訂閱電子報或更改資料</h3>
          <a>
            只有在您願意收到我們訊息的情況下，我們才能與您通訊。您有權確認我們所保有的您的資料，並且修正、更新或更改此類資料。若您希望不再收到我們的訊息，或希望修正、更新或更改您的個人資料或喜好，請遵循以下說明指示。
          </a>
          <a>
            如欲取消訂閱電子報，請遵循您所收到的任何電子報所列指示。您可傳送Email至
            {" " + contactEmail + " "}
            提出申請,請務必於要求中備註您的全名、使用者名稱及帳號Email信箱。請注意，若您拒絕由我們保留與運用您的資料，可能會使我們無法向您提供某些產品或服務。
          </a>
          {/* 同意書 */}
          <h3>八、同意書</h3>
          <a>
            當你使用我們的網站時，同意我們收集和使用你的信息，並合意我們在本隱私及保密政策中所描述的保護您的權益。本網站隱私權保護政策將因應需求隨時進行修正，修正後的條款將刊登於網站上。
          </a>
        </div>
      </div>
      <Footer />
    </div>
  );
}
