import React, { useEffect } from "react";
// Css
import "./popUps.scss";

export default function PopUps(props) {
  // Detect Enter key press
  useEffect(() => {
    const keyDownHandler = (event) => {
      if (event.key === "Enter") {
        props.hidePopUp();
      }
    };

    document.addEventListener("keydown", keyDownHandler);

    return () => {
      document.removeEventListener("keydown", keyDownHandler);
    };
  }, []);

  return (
    <div id="pop-up" className="flex h-center v-center">
      <div className={`flex-column v-center content${props.parentPopUpName ? props.parentPopUpName : ""}`}>
        {props.popUpContent()}
        <button className="button1 confirmation" onClick={props.hidePopUp}>
          確認
        </button>
      </div>
    </div>
  );
}
