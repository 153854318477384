import React, { useState, useRef, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { Buffer } from "buffer";
import axios from "axios";
// Captcha
import HCaptcha from "@hcaptcha/react-hcaptcha";
import { verifyCaptcha } from "../../utils/Captcha";
// Zipcode
import { zipCodes, cities, districts } from "use-tw-zipcode";
// Helper
import { getCookie } from "../../utils/Cookie";
// Footer
import Footer from "../../widgets/Footer/Footer";
// Icons
import { FaChevronUp, FaChevronDown } from "react-icons/fa";
import { AiFillCaretDown, AiFillCaretUp } from "react-icons/ai";
import logo_7eleven from "../../files/images/logo-7eleven.png";
// Css
import "./shippingInfo.scss";

export default function ShippingInfo(props) {
  const navigate = useNavigate();

  //  Mobile
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 925);
  // Cart
  const [cartId, setCartId] = useState([]);
  const [cart, setCart] = useState([]);
  // Toggle cart
  const [showCart, setShowCart] = useState(true);
  // Quantity
  const [quantity, setQuantity] = useState(0);
  // Inputs
  const [company, setCompany] = useState("");
  const [fullName, setFullName] = useState("");
  const [gender, setGender] = useState("先生");
  const [email, setEmail] = useState("");
  const [lines, setLines] = useState(1);
  const [phoneType, setPhoneType] = useState(["手機"]);
  const [updatePhoneType, setUpdatePhoneType] = useState(false);
  const [phoneNumber, setPhoneNumber] = useState([""]);
  const [updatePhoneNumber, setUpdatePhoneNumber] = useState(false);
  const [address, setAddress] = useState("");
  const [city, setCity] = useState("");
  const [district, setDistrict] = useState("");
  const [zipcode, setZipcode] = useState("--");
  const note = useRef("");
  // Dropdowns
  const [showTypeDropdown, setShowTypeDropdown] = useState(false);
  const [typeDropdownIndex, setTypeDropdownIndex] = useState(null);
  const [showCityDropdown, setShowCityDropdown] = useState(false);
  const [showDistrictDropdown, setShowDistrictDropdown] = useState(false);
  // Validation
  const [isValid, setIsValid] = useState({
    fullName: true,
    email: true,
    phoneNumber: [true],
    address: true,
    city: true,
    district: true,
    shipping: true,
  });
  const [mobileValidMsg, setMobileValidMsg] = useState(false);
  const [error, setError] = useState(null);
  // Summary
  const [originalTotal, setOriginalTotal] = useState(0);
  const [total, setTotal] = useState(0);
  // Shipping - 7-ELEVEN/萊爾富/全家/OK mart/郵寄寄送/貨到付款
  const shippingMethod = [
    "7-ELEVEN",
    "宅配",
  ];
  const shippingPrice = [38, 65];
  const [shipping, setShipping] = useState(38);
  const [freeShipping, setFreeShipping] = useState(false);
  const [method, setMethod] = useState(0);
  const mapFormRef = useRef(null);
  const [mapAddressInfo, setMapAddressInfo] = useState({});
  // Payment
  const [initPayment, setInitPayment] = useState(false);
  const [merchantTradeNumber, setMerchantTradeNumber] = useState(null);
  const paymentFormRef = useRef(null);
  // Captcha
  const [token, setToken] = useState(null);
  const [passCaptcha, setPassCaptcha] = useState(false);
  // Event listener
  const [click, setClick] = useState(null);

  // Env
  const contactEmail = process.env.REACT_APP_CONTACT_EMAIL;
  const environment = process.env.NODE_ENV;

  useEffect(() => {
    // Get cookie
    const info = getCookie();

    if (info) {
      // Get cart info
      if (info.cart) {
        const id = getInfoCookie("cart", info.cart);
        setCartId(id);
        callGetCartEndpoint(id);
      } else {
        navigate("/cart");
      }

      // Get user info for logged in user
      if (info.user && info.x) {
        callGetUserInfoEndpoint(info);
      }

      // Get store data
      if (info.storeData) {
        const storeDataFromCookie = getInfoCookie("store_data", info.storeData)
        setMapAddressInfo(storeDataFromCookie);
      }
    } else {
      navigate("/cart");
    }

    // [ Event listener - mouse click & resize ]
    const handleClick = (event) => {
      setClick(event);
    };
    document.addEventListener("click", handleClick);
    window.addEventListener('resize', handleResize);

    return () => {
      document.removeEventListener("click", handleClick);
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  useEffect(() => {
    // Init payment
    if (initPayment) {
      callSendPaymentEndpoint();
      setInitPayment(false);
    }
  }, [initPayment])

  // Phone input change, summary change, click event
  useEffect(() => {
    // Phone type
    if (updatePhoneType) {
      setUpdatePhoneType(false);
    }

    // Phone number
    if (updatePhoneNumber) {
      setUpdatePhoneNumber(false);
    }

    // Click event
    if (click) {
      hideDropdowns(click);
      setClick(null);
    }

    // Captcha
    if (passCaptcha) {
      setPassCaptcha(false);
      callAddOrderEndpoint();
    }
  }, [updatePhoneType, updatePhoneNumber, click, passCaptcha]);

  // Handle window resize
  const handleResize = () => {
    setIsMobile(window.innerWidth <= 925);
  };

  // Endpoint
  async function callGetCartEndpoint(cartId) {
    await axios
      .get(`${process.env.REACT_APP_API}/carts/get?`, {
        params: { cartId },
        headers: { "x-api-key": process.env.REACT_APP_API_KEY },
      })
      .then((res) => {
        if (res.status === 200) {
          // Set cart list state
          setCart(res.data);

          if (res.data.length < 1) {
            navigate("/cart");
          } else {
            // Set original total, total amount & quantity
            let originalAmount = 0;
            let totalAmount = 0;
            let totalQuantity = 0;

            res.data.forEach((item) => {
              originalAmount += item.quantity * item.price;
              totalAmount += item.quantity * item.sale;
              totalQuantity += item.quantity;
            });
            setOriginalTotal(originalAmount);
            setTotal(totalAmount);
            setQuantity(totalQuantity);

            // Set default shipping method
            if (totalQuantity > 2) {
              setMethod(1);
              setShipping(shippingPrice[1]);
            }

            // 滿 NT$880 免運
            if (totalAmount >= 880) {
              setFreeShipping(true);
            }

            setInitPayment(true);
          }
        }
      })
      .catch(() => {
        navigate("/cart");
      });

  }
  async function callGetUserInfoEndpoint(info) {
    await axios
      .post(
        `${process.env.REACT_APP_API}/accountInfo/getInfo`,
        {
          userName: info.user,
        },
        {
          headers: {
            "x-access-token": info.x,
            "x-api-key": process.env.REACT_APP_API_KEY
          },
        }
      )
      .then((res) => {
        if (res.status === 200) {
          // Update user info
          updateUserInfo(res.data);
        }
      })
      .catch((err) => {
        console.error(err);
      });
  }
  async function handleChooseStore() {
    if (mapFormRef.current) {
      mapFormRef.current.submit();
    }
  }
  async function callAddOrderEndpoint() {
    const info = getCookie();
    const header = {
      headers: { "x-api-key": process.env.REACT_APP_API_KEY }
    };
    const data = {
      merchantTradeNumber,
      cart,
      company,
      fullName,
      gender,
      email,
      phoneType: phoneType[0],
      phoneNumber: phoneNumber[0],
      address,
      city,
      district,
      zipcode,
      shipping: freeShipping ? 0 : shipping,
      method: shippingMethod[method],
      pickUpStoreId: quantity <= 2 && mapAddressInfo && mapAddressInfo.CVSStoreID ? mapAddressInfo.CVSStoreID : null,
      pickUpStoreName: quantity <= 2 && mapAddressInfo && mapAddressInfo.CVSStoreName ? mapAddressInfo.CVSStoreName : null,
      pickUpStoreAddress: quantity <= 2 && mapAddressInfo && mapAddressInfo.CVSAddress ? mapAddressInfo.CVSAddress : null,
      originalTotal,
      total,
      note: note.current.value,
    };

    if (info) {
      if (info.x) {
        header.headers["x-access-token"] = info.x;
      }

      if (info.user) {
        data.userName = info.user;
      }

      if (info.cart) {
        data.cartId = info.cart;
      }
    }

    await axios
      .post(`${process.env.REACT_APP_API}/orders/add`, data, header)
      .then((res) => {
        if (res.status === 200) {
          if (paymentFormRef.current) {
            paymentFormRef.current.submit();
          }
        }
      })
      .catch((err) => {
          console.error(err);
      });
  }
  async function callSendPaymentEndpoint() {
    await axios
      .post(`${process.env.REACT_APP_API}/proxy/pay`, {
        cartId,
        cartItems: getCartItems(),
        totalAmount: getTotalAmount(),
      }, {
        headers: {
          "x-api-key": process.env.REACT_APP_API_KEY
        }
      })
      .then((res) => {
        // Set tracking number
        setMerchantTradeNumber(res.data.MerchantTradeNo);

        // Set form values
        Object.keys(res.data).forEach(key => {
          if (paymentFormRef.current) {
            const input = document.createElement('input');
            input.type = 'hidden';
            input.name = key;
            input.value = res.data[key];
            paymentFormRef.current.appendChild(input);
          }
        });
      })
      .catch((err) => {
        console.error(err);
      });
  }

  // Get cart/store data from cookie
  const getInfoCookie = (type, data) => {
    if (type === "store_data") {
      return JSON.parse(decodeURIComponent(data));
    } else {
      return data;
    }
  };

  // Update user info
  const updateUserInfo = (data) => {
    const { info, phones } = data;

    setFullName(info.fullName);
    setGender(info.gender);
    setCompany(info.company);
    setEmail(info.email);
    setZipcode(info.zipcode);
    setCity(info.city);
    setDistrict(info.district);
    setAddress(info.address);

    // Phones
    const phoneTs = [];
    const phoneNs = [];
    phones.forEach((item) => {
      let num = item.number;
      if (item.type === "手機") {
        num = num.slice(0, 3) + "-" + num.slice(3, 6) + "-" + num.slice(6, 9);
      } else {
        num = num[0] + " " + num.slice(1, -4) + "-" + num.slice(-4);
      }

      phoneTs.push(item.type);
      phoneNs.push(num);
    });
    setLines(phones.length);
    setPhoneType(phoneTs);
    setPhoneNumber(phoneNs);
  };

  // Get cart list
  const getCartItems = () => {
    let strs = "";

    cart.forEach((item) => {
      const { name, size, quantity } = item;
      const text = `${name} [尺寸: ${size}] x${quantity}`;

      strs += strs ? `#${text}` : text;
    })

    return strs;
  }
  const getCartList = () => {
    const list = [];

    if (cart.length > 0) {
      cart.forEach((item, index) => {
        const { name, model, colorName, price, sale, size, quantity, image } =
          item;

        list.push(
          <div key={"cart" + index}>
            <div key={"cart-row-mobile" + index} className="flex h-between v-center row item">
              <div key={"cart-mobile-content" + index} className="flex v-center item-content">
                <img
                  key={"cart-image" + index}
                  src={
                    image
                      ? `data:image/png;base64, ${new Buffer.from(image).toString(
                        "base64"
                      )}`
                      : null
                  }
                />
                <div key={"cart-mobile-info" + index} className="flex-column h-center info">
                  {/* Product name & model */}
                  <div
                    key={"cart-name-div" + index}
                    className="flex v-center name"
                  >
                    <a key={"cart-name" + index}>{`${name}`}</a>
                    <a key={"cart-model" + index}>{`(型號: ${model})`}</a>
                  </div>
                  {/* Color & Size */}
                  <div key={"cart-specs-div" + index} className="flex v-center specs-div">
                    <a key={"cart-specs-title" + index}>規格: </a>
                    <a key={"cart-specs-detail" + index}>{colorName} / {size}</a>
                  </div>
                  {/* Quantity */}
                  <div key={"cart-quantity-div" + index} className="flex v-center specs-div">
                    <a key={"cart-quantity-title" + index}>數量: </a>
                    <a key={"cart-quantity" + index}>{quantity}</a>
                  </div>
                  {/* Size */}
                  <div key={"cart-price-div" + index} className="flex h-between price-div">
                    <div key={`cart-price-cont${index}`} className="flex v-center">
                      {sale !== price && (
                        <a key={"cart-original-price" + index} className="price">
                          ${(quantity * price).toLocaleString("en-US")}
                        </a>
                      )}
                      <a key={"cart-total" + index} className="sale">
                        ${(quantity * sale).toLocaleString("en-US")}
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        );
      });
    } else {
      list.push(
        <div key="cart-no-item" className="flex h-center row no-item">
          購物車內無商品
        </div>
      );
    }

    return list;
  };

  // Dropdowns
  const toggleTypeDropdown = (i) => {
    if (typeDropdownIndex === i) {
      setShowTypeDropdown(!showTypeDropdown);
    } else {
      setShowTypeDropdown(true);
    }
    setTypeDropdownIndex(i);
  };
  const hideDropdowns = (event) => {
    const { id } = event.target;
    const parentId = event.target.parentElement
      ? event.target.parentElement.id
      : null;

    // Phone type dropdowns
    if (
      showTypeDropdown &&
      id !== "dropdown-type-text" &&
      id !== "dropdown-type-content" &&
      id !== "dropdown-type-icon" &&
      parentId !== "dropdown-type-icon"
    ) {
      setShowTypeDropdown(false);
      setTypeDropdownIndex(null);
    }

    // City dropdown
    if (
      showCityDropdown &&
      id !== "dropdown-city-text" &&
      id !== "dropdown-city-content" &&
      id !== "dropdown-city-icon" &&
      parentId !== "dropdown-city-icon"
    ) {
      setShowCityDropdown(false);
    }

    // District dropdown
    if (
      showDistrictDropdown &&
      id !== "dropdown-district-text" &&
      id !== "dropdown-district-content" &&
      id !== "dropdown-district-icon" &&
      parentId !== "dropdown-district-icon"
    ) {
      setShowDistrictDropdown(false);
    }
  };

  // Phone
  const getLine = () => {
    const list = [];

    for (let i = 0; i < lines; i++) {
      list.push(
        <div
          key={"phone-line" + i}
          style={{ marginTop: i > 0 ? "-15px" : "0" }}
          className="flex h-between v-start"
        >
          <div key={"phone-input" + i} className="flex h-between phone-input">
            <div
              key={"phone-type" + i}
              className="flex-column v-start phone-input-type"
            >
              <a key={"phone-type-txt" + i}>
                類型<span>*</span>
              </a>
              <div
                key={"phone-type-dropdown" + i}
                className="flex-column dropdown"
              >
                <div
                  key={"phone-type-dropdown-text-div" + i}
                  style={{
                    borderRadius:
                      showTypeDropdown && typeDropdownIndex == i
                        ? "5px 5px 0 0"
                        : "5px",
                  }}
                  className="flex h-between v-center dropdown-text"
                >
                  <a
                    key={"phone-type-dropdown-text" + i}
                    id="dropdown-type-text"
                    onClick={() => {
                      toggleTypeDropdown(i);
                    }}
                  >
                    {phoneType[i]}
                  </a>
                  {showTypeDropdown && typeDropdownIndex == i ? (
                    <AiFillCaretUp
                      id="dropdown-type-icon"
                      className="icon "
                      onClick={() => {
                        toggleTypeDropdown(i);
                      }}
                    />
                  ) : (
                    <AiFillCaretDown
                      id="dropdown-type-icon"
                      className="icon"
                      onClick={() => {
                        toggleTypeDropdown(i);
                      }}
                    />
                  )}
                </div>
                <div
                  key={"phone-type-dropdown-content" + i}
                  id="dropdown-type-content"
                  className={`flex-column dropdown-content${showTypeDropdown && typeDropdownIndex == i ? " active" : ""}`}
                >
                  <a
                    key={"phone-type-dropdown-content-phone" + i}
                    onClick={() => {
                      setPhoneType((old) => {
                        old[i] = "手機";
                        return old;
                      });
                      setUpdatePhoneType(true);
                    }}
                  >
                    手機
                  </a>
                  <a
                    key={"phone-type-dropdown-content-landline" + i}
                    onClick={() => {
                      setPhoneType((old) => {
                        old[i] = "市話";
                        return old;
                      });
                      setUpdatePhoneType(true);
                    }}
                  >
                    市話
                  </a>
                </div>
              </div>
            </div>
            <div
              key={"phone-number" + i}
              className="flex-column v-start phone-input-number"
            >
              <a key={"phone-number-text" + i}>
                電話<span>*</span>
              </a>
              <div className="flex">
                <input
                  key={"phone-number-area-code" + i}
                  className={determineInvalid("phoneNumber", i)}
                  type="text"
                  value="(+886)"
                  disabled
                />
                <input
                  key={"phone-number-input" + i}
                  className={determineInvalid("phoneNumber", i)}
                  type="text"
                  placeholder={"聯絡電話 ex. 911222333"}
                  value={phoneNumber[i]}
                  onChange={(event) => {
                    phoneNumber[i] = event.target.value;
                    setPhoneNumber(phoneNumber);
                    setUpdatePhoneNumber(true);
                  }}
                />
              </div>
            </div>
          </div>
        </div>
      );
    }

    return list;
  };

  // City
  const getCities = () => {
    const list = [];

    for (let i = 0; i < cities.length; i++) {
      list.push(
        <a
          key={"city-dropdown-content-text" + i}
          onClick={() => {
            setCity(cities[i]);
            setDistrict("");
            setZipcode("--");
          }}
        >
          {cities[i]}
        </a>
      );
    }

    return (
      <div className="flex-column dropdown">
        <div
          style={{
            borderRadius: showCityDropdown ? "5px 5px 0 0" : "5px",
          }}
          className={
            "flex h-between v-center dropdown-text" + determineInvalid("city")
          }
        >
          <a
            id="dropdown-city-text"
            onClick={() => setShowCityDropdown(!showCityDropdown)}
          >
            {city === "" ? "--" : city}
          </a>
          {showCityDropdown ? (
            <AiFillCaretUp
              id="dropdown-city-icon"
              className="icon "
              onClick={() => setShowCityDropdown(!showCityDropdown)}
            />
          ) : (
            <AiFillCaretDown
              id="dropdown-city-icon"
              className="icon"
              onClick={() => setShowCityDropdown(!showCityDropdown)}
            />
          )}
        </div>
        <div
          id="dropdown-city-content"
          className={`flex-column dropdown-content${showCityDropdown ? " active" : ""}`}
        >
          {list}
        </div>
      </div>
    );
  };

  // Distrct
  const getDistrict = () => {
    const list = [];
    const districtList = districts[city] ? districts[city] : [];

    for (let i = 0; i < districtList.length; i++) {
      list.push(
        <a
          key={"district-dropdown-content-text" + i}
          onClick={() => {
            setDistrict(districtList[i]);
            setZipcode(zipCodes[city][districtList[i]]);
          }}
        >
          {districtList[i]}
        </a>
      );
    }

    return (
      <div className="flex-column dropdown">
        <div
          style={{
            borderRadius: showDistrictDropdown ? "5px 5px 0 0" : "5px",
          }}
          className={
            "flex h-between v-center dropdown-text" +
            determineInvalid("district")
          }
        >
          <a
            id="dropdown-district-text"
            style={{ cursor: districtList.length > 0 ? "pointer" : "auto" }}
            onClick={() => {
              setShowDistrictDropdown(
                districtList.length > 0 ? !showDistrictDropdown : false
              );
            }}
          >
            {district === "" ? "--" : district}
          </a>
          {districtList.length > 0 &&
            (showDistrictDropdown ? (
              <AiFillCaretUp
                id="dropdown-district-icon"
                className="icon "
                onClick={() => setShowDistrictDropdown(!showDistrictDropdown)}
              />
            ) : (
              <AiFillCaretDown
                id="dropdown-district-icon"
                className="icon"
                onClick={() => setShowDistrictDropdown(!showDistrictDropdown)}
              />
            ))}
        </div>
        <div
          id="dropdown-district-content"
          className={`flex-column dropdown-content${showDistrictDropdown ? " active" : ""}`}
        >
          {list}
        </div>
      </div>
    );
  };

  // Validation
  const validation = () => {
    let allValid = true;
    const newValid = isValid;

    for (const key of Object.keys(isValid)) {
      // Email
      if (key === "email") {
        if (/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(email)) {
          newValid[key] = true;
        } else {
          allValid = false;
          newValid[key] = false;
        }
      }
      // Phone number
      else if (key === "phoneNumber") {
        for (let i = 0; i < phoneNumber.length; i++) {
          let regex = null;

          if (phoneType[i] === "市話") {
            regex = /^(2|3|4|5|6|7|8)[-\s]?[0-9]{3,4}[-\s]?[0-9]{4}$/;
          } else if (phoneType[i] === "手機") {
            regex = /^9[-\s]?[0-9]{2}[-\s]?[0-9]{3}[-\s]?[0-9]{3}$/;
          }
          // Skip check if doesn't specify type
          else {
            continue;
          }

          if (!regex.test(phoneNumber[i])) {
            allValid = false;
            newValid[key][i] = false;
          } else {
            newValid[key][i] = true;
          }
        }
      }
      // Shipping method
      else if (key === "shipping") {
        if (method === 0 && JSON.stringify(mapAddressInfo) === "{}") {
          allValid = false;
          newValid[key] = false;
        }
      }
      // Full name, Address, City, District
      else {
        let field = null;

        if (key === "fullName") {
          field = fullName;
        } else if (key === "address") {
          field = address;
        } else if (key === "city") {
          field = city;
        } else if (key === "district") {
          field = district;
        }

        if (field === "") {
          allValid = false;
          newValid[key] = false;
        } else {
          newValid[key] = true;
        }
      }
    }
    setIsValid(newValid);
    setMobileValidMsg(allValid);
    setError(null);

    return allValid;
  };
  const determineInvalid = (field, index) => {
    // Phone
    if (index !== undefined) {
      return isValid[field][index] ? "" : " invalid-input";
    }
    // Zip code
    else if (field === "zipcode") {
      return isValid.city && isValid.district ? "" : " invalid-input";
    }
    // Shipping method
    else if (field === "shipping") {
      return isValid[field] ? "" : " invalid-store-selection";
    }
    // Others
    else {
      return isValid[field] ? "" : " invalid-input";
    }
  };
  const getInvalidMsg = (key, field) => {
    // Email
    if (key === "email") {
      return isValid[key] || error
        ? ""
        : `請輸入${field}或確認您的格式是否正確`;
    }
    // Phone number
    else if (key === "phoneNumber") {
      let allValid = true;
      isValid[key].forEach((elmnt) => {
        if (!elmnt) {
          allValid = false;
        }
      });
      return allValid ? "" : `請輸入${field}或確認您的格式是否正確`;
    }
    // Zipcode
    else if (key === "zipcode") {
      return isValid.city && isValid.district ? "" : `請輸入${field}`;
    }
    // Shipping method
    else if (key === "shipping") {
      return isValid[key] ? "" : `請選擇${field}`;
    }
    // Others
    else {
      return isValid[key] ? "" : `請輸入${field}`;
    }
  };

  // Get total amount
  const getTotalAmount = () => {
    let totalAmount = total;

    if (!freeShipping) {
      totalAmount += shipping;
    }

    return totalAmount;
  }

  // Submit
  async function handleSubmit(event) {
    event.preventDefault();

    // Validation
    const valid = validation();

    if (valid) {
      if (environment === "production") {
        verifyCaptcha(token, setPassCaptcha, setError);
      } else {
        callAddOrderEndpoint();
      }
    }
  }

  return (
    <div id="page-shipping" className="flex-column v-center page">
      <div className="flex-column v-center page-content">
        <h2>配送與付款方式</h2>
        <div className="flex h-between content">
          <div className="flex-column content-left">
            {/* 送貨資料 */}
            <div className="flex-column block">
              <div className="flex h-between v-center title">
                <h3>送貨資料</h3>
              </div>
              <div className="flex h-between input-row company">
                <div className="flex-column v-start input-field">
                  <a>公司名稱</a>
                  <input
                    type="text"
                    value={company ? company : ""}
                    onChange={(event) => setCompany(event.target.value)}
                  />
                  <a className="invalid-msg"></a>
                </div>
              </div>
              <div className="flex h-between input-row input-row-2column">
                <div className="flex-column v-start input-field">
                  <a>
                    姓名<span>*</span>
                  </a>
                  <input
                    type="text"
                    value={fullName}
                    onChange={(event) => setFullName(event.target.value)}
                    className={determineInvalid("fullName")}
                  />
                  <a className="invalid-msg">
                    {getInvalidMsg("fullName", "姓名")}
                  </a>
                </div>
                <div className="flex-column v-start input-field">
                  <a>
                    性別<span>*</span>
                  </a>
                  <div className="flex h-center gender">
                    <div className="flex h-end v-center">
                      <input
                        type="radio"
                        name="gender"
                        checked={gender === "先生"}
                        onChange={() => setGender("先生")}
                      />
                      <label onClick={() => setGender("先生")}>先生</label>
                    </div>
                    <div className="flex v-center">
                      <input
                        type="radio"
                        name="gender"
                        checked={gender === "小姐"}
                        onChange={() => setGender("小姐")}
                      />
                      <label onClick={() => setGender("小姐")}>女士</label>
                    </div>
                  </div>
                </div>
              </div>
              <div className="flex h-between input-row">
                <div className="flex-column v-start input-field">
                  <a>
                    聯絡信箱<span>*</span>
                  </a>
                  <input
                    type="email"
                    value={email}
                    onChange={(event) => setEmail(event.target.value)}
                    className={determineInvalid("email")}
                  />
                  <a className="invalid-msg">
                    {getInvalidMsg("email", "信箱")}
                  </a>
                </div>
              </div>
              <div className="flex h-between input-row">
                <div className="flex-column v-start input-field phone">
                  {getLine()}
                  <a className="invalid-msg">
                    {getInvalidMsg("phoneNumber", "電話號碼")}
                  </a>
                </div>
              </div>
              <div className="flex h-between input-row">
                <div className="flex-column v-start input-field">
                  <a>
                    聯絡地址<span>*</span>
                  </a>
                  <input
                    type="text"
                    value={address}
                    onChange={(event) => setAddress(event.target.value)}
                    className={determineInvalid("address")}
                  />
                  <a className="invalid-msg">
                    {getInvalidMsg("address", "地址")}
                  </a>
                </div>
              </div>
              <div className="flex h-between input-row input-row-3column">
                <div className="flex-column v-start input-field">
                  <a>
                    縣市<span>*</span>
                  </a>
                  {getCities()}
                  <a className="invalid-msg">{getInvalidMsg("city", "縣市")}</a>
                </div>
                <div className="flex-column v-start input-field">
                  <a>
                    行政區<span>*</span>
                  </a>
                  {getDistrict()}
                  <a className="invalid-msg">
                    {getInvalidMsg("district", "行政區")}
                  </a>
                </div>
                <div className="flex-column v-start input-field zipcode">
                  <a>
                    郵遞區號<span>*</span>
                  </a>
                  <input
                    type="text"
                    value={zipcode}
                    className={determineInvalid("zipcode")}
                    disabled
                  />
                  <a className="invalid-msg">
                    {getInvalidMsg("zipcode", "縣市和行政區")}
                  </a>
                </div>
              </div>
              <div className="flex h-between input-row">
                <div className="flex-column v-start input-field">
                  <a>其他注意事項</a>
                  <textarea
                    placeholder={"若您有任何需要我們注意的事項, 請在這裡填寫。"}
                    ref={note}
                  />
                </div>
              </div>
            </div>
            {/* 送貨方式 */}
            <div className="flex-column block shipping-method">
              <div className="flex h-between v-center title">
                <h3>送貨方式</h3>
              </div>
              <div className={`flex-column${determineInvalid("shipping")}`}>
                <div className="flex-column v-start options">
                  {/* 7-11 */}
                  {quantity <= 2 &&
                    <div className="flex v-center">
                      <div
                        className={"flex v-center option-item" + (method === 0 ? " select" : "")}
                        onClick={() => {
                          setMethod(0);
                          setShipping(shippingPrice[0]);
                        }}
                      >
                        <input
                          type="radio"
                          name="method"
                          checked={method === 0}
                          onChange={() => {
                            setMethod(0);
                            setShipping(shippingPrice[0]);
                          }}
                        />
                        <img src={logo_7eleven} />
                        <a className="text">7-ELEVEN</a>
                        <a className={`price${freeShipping ? " cross-out" : ""}`}>( NT${shippingPrice[0]} )</a>
                        {freeShipping && <a className="free-shipping">免運</a>}
                      </div>
                      <a className="select-store" onClick={handleChooseStore}>選擇門市</a>
                      <a className="invalid-msg">
                        {getInvalidMsg("shipping", "門市")}
                      </a>
                      {/* Hidden map */}
                      <form id="map-hidden-form" ref={mapFormRef} method="post" action="https://logistics.ecpay.com.tw/Express/map">
                        <input key="0" name="MerchantID" defaultValue={process.env.REACT_APP_EC_MERCHANT_ID} /><br />
                        <input key="1" name="MerchantTradeNo" defaultValue={`map${Math.floor(Date.now() / 1000)}`} /><br />
                        <input key="2" name="LogisticsType" defaultValue="CVS" /><br />
                        <input key="3" name="LogisticsSubType" defaultValue="UNIMARTC2C" /><br />
                        <input key="4" name="IsCollection" defaultValue="N" /><br />
                        <input key="5" name="ServerReplyURL" defaultValue={`${process.env.REACT_APP_API}/proxy/map`} /><br />
                        <input type="submit" defaultValue="送出參數" />
                      </form>
                    </div>
                  }
                  {
                    quantity <= 2 && mapAddressInfo && mapAddressInfo.CVSStoreID &&
                    (
                      <div className="flex-column map-address">
                        <a><b>超商店號:</b> {mapAddressInfo.CVSStoreID}</a>
                        <a><b>超商店名:</b> {mapAddressInfo.CVSStoreName}</a>
                        <a><b>超商地址:</b> {mapAddressInfo.CVSAddress}</a>
                      </div>
                    )
                  }
                  {/* 宅配 */}
                  <div className="flex v-center">
                    <div
                      className={"flex v-center option-item" + (method === 1 ? " select" : "")}
                      onClick={() => {
                        setMethod(1);
                        setShipping(shippingPrice[1]);
                      }}
                    >
                      <input
                        type="radio"
                        name="method"
                        checked={method === 1}
                        onChange={() => {
                          setMethod(1);
                          setShipping(shippingPrice[1]);
                        }}
                      />
                      <a className="text text-noimage">宅配</a>
                      <a className={`price${freeShipping ? " cross-out" : ""}`}>( NT${shippingPrice[1]} )</a>
                      {freeShipping && <a className="free-shipping">免運</a>}
                    </div>
                  </div>
                </div>
                {freeShipping && <a className="free-shipping-text">恭喜, 您已到達NT$ 1,000免運門檻了</a>}
              </div>
            </div>
            {/* 付款方式 */}
            <div className="flex-column block">
              <div className="flex h-between v-center title">
                <h3>付款方式</h3>
              </div>
              <div className="flex v-center payment-method">
                <input
                  type="radio"
                  name="payment"
                  checked
                  onChange={() => { }}
                />
                <a className="text text-noimage">使用綠界ECPay付款</a>
              </div>
            </div>
          </div>
          <div className="flex-column content-right">
            {/* 商品清單 */}
            <div className="flex-column block product-list">
              <div className="flex h-between v-center title">
                <h3 className="flex v-center">商品清單 ({cart.length})</h3>
                {showCart ? (
                  <FaChevronUp onClick={() => setShowCart(!showCart)} />
                ) : (
                  <FaChevronDown onClick={() => setShowCart(!showCart)} />
                )}
              </div>
              <div className={`flex-column table${showCart ? " show" : ""}`}>
                {getCartList()}
              </div>
            </div>
            {/* Calculation */}
            <div className="flex-column calculation">
              <div className="flex h-between">
                <a>小計:</a>
                <a>NT$ {originalTotal.toLocaleString("en-US")}</a>
              </div>
              {
                (originalTotal - total) > 0 &&
                <div className="flex h-between discount">
                  <a>折扣:</a>
                  <a>- NT$ {(originalTotal - total).toLocaleString("en-US")}</a>
                </div>
              }
              <div className="flex h-between">
                <a>運費:</a>
                <a>{freeShipping ? "NT$ 0" : "NT$ " + shipping.toLocaleString("en-US")}</a>
              </div>
            </div>
            {/* Summary */}
            <div className="flex h-between summary">
              <a>合計:</a>
              <a>NT$ {getTotalAmount().toLocaleString("en-US")}</a>
            </div>
            <div className="flex h-center captcha">
              <HCaptcha
                size="compact"
                sitekey={process.env.REACT_APP_CAPTCHA_SITE_KEY}
                onError={() =>
                  setError("網頁錯誤, 請刷新頁面或與我們聯絡: " + contactEmail)
                }
                onExpire={() => setError("驗證過期, 請刷新頁面")}
                onVerify={setToken}
              />
            </div>
            {error !== null && <a className="error-msg">{error}</a>}
            <form
              ref={paymentFormRef}
              method="post"
              action={
                process.env.NODE_ENV === "development" ?
                "https://payment-stage.ecpay.com.tw/Cashier/AioCheckOut/V5" :
                "https://payment.ecpay.com.tw/Cashier/AioCheckOut/V5"
              }
            >
              <button className="place-order button1 checkout" onClick={handleSubmit}>
                結帳
              </button>
            </form>
          </div >
          {!mobileValidMsg && <a className="flex h-center mobile invalid-msg mobile-msg-warning">您尚有未輸入的資料</a>}
        </div >
      </div >
      <Footer />
    </div >
  );
}
