import React, { useState, useEffect, useRef } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { Buffer } from "buffer";
// Loading Animation
import LoadingAnimation from "../../widgets/Animations/Loading";
// Footer
import Footer from "../../widgets/Footer/Footer";
// Category side bar
import CategorySideBar from "../../widgets/CategorySideBar/CategorySideBar";
// Image magnifier
import ImageMagnifier from "./ImageMagnifier";
// Helper
import { getCookie, setCookie } from "../../utils/Cookie";
// Images
import ShopRuten from "../../files/images/shop-ruten.png";
import ShopShopee from "../../files/images/shop-shopee.jpeg";
import ShopPChome from "../../files/images/shop-pchome.jpeg";
import ShopMomo from "../../files/images/shop-momo.jpeg";
import ShopRakuten from "../../files/images/shop-rakuten.jpeg";
// Icons
import { AiTwotoneCrown } from "react-icons/ai";
import { VscTriangleRight } from "react-icons/vsc";
import { MdRemove, MdAdd } from "react-icons/md";
import { FaCartShopping, FaChevronLeft, FaChevronRight } from "react-icons/fa6";
import { BsArrowDownShort } from "react-icons/bs";
// Css
import "./productInfo.scss";

export default function Productinfo(props) {
  const navigate = useNavigate();
  // [ Loading ]
  const [loading, setLoading] = useState(true);
  // [ Product info ]
  const [info, setInfo] = useState({});
  const [currentProduct, setCurrentProduct] = useState(0);
  // [ Product images ]
  const [currentImage, setCurrentImage] = useState(0);
  const startX = useRef(0);
  const endX = useRef(0);
  // [ Description ]
  const [currentDescription, setCurrentDescription] = useState(0);
  // [ Quantity ]
  const [quantity, setQuantity] = useState(1);
  // [ Size ]
  const [currentSize, setCurrentSize] = useState(null);
  const [validSize, setValidSize] = useState(true);

  useEffect(() => {
    // Init product info
    let colorId = null;
    let { pathname, search } = window.location;
    pathname = pathname.split("/");

    // Update index
    let index = Number.isInteger(parseInt(pathname[3])) ? parseInt(pathname[3]) : 0;
    setCurrentProduct(index);

    // Handle landing on product info page with colorId
    if (search.includes("?colorId=")) {
      colorId = search.split("?colorId=");
      colorId = Number.isInteger(parseInt(colorId[1])) ? parseInt(colorId[1]) : 0;
    }
    callProductInfoEndpoint(pathname[2], colorId);

    window.scrollTo(0, 0);
  }, []);

  // Endpoint
  async function callProductInfoEndpoint(id, colorId) {
    await axios
      .post(`${process.env.REACT_APP_API}/products/info`, {
        productId: id,
      }, {
        headers: { "x-api-key": process.env.REACT_APP_API_KEY },
      })
      .then((res) => {
        if (res.status === 200) {
          handleData(res.data, colorId);
          setLoading(false);
        }
      })
      .catch(() => { });
  }
  async function callAddToCartEndpoint(item, expires) {
    const cookie = getCookie();

    if (cookie && cookie.user && cookie.x) {
      await axios
        .post(
          `${process.env.REACT_APP_API}/carts/add`,
          {
            user: cookie.user,
            cart: cookie.cart,
            item,
            expires,
          },
          {
            headers: {
              "x-access-token": cookie.x,
              "x-api-key": process.env.REACT_APP_API_KEY
            },
          }
        )
        .then((res) => {
          if (res.status === 200) {
            const { cart, quantity } = res.data;

            // Update cookie
            setCookie("cart", cart, expires.toUTCString());
            // Update cart detail
            props.setCartQuantity(quantity);
            props.setUpdateQuantity(true);
            props.setToggleCart(true);
          }
        })
        .catch(() => { });
    } else {
      await axios
        .post(`${process.env.REACT_APP_API}/carts/add`, {
          cart: (cookie && cookie.cart) ? cookie.cart : null,
          item,
          expires,
        }, {
          headers: { "x-api-key": process.env.REACT_APP_API_KEY },
        })
        .then((res) => {
          if (res.status === 200) {
            const { cart, quantity } = res.data;

            // Update cookie
            setCookie("cart", cart, expires.toUTCString());
            // Update cart detail
            props.setCartQuantity(quantity);
            props.setUpdateQuantity(true);
            props.setToggleCart(true);
          }
        })
        .catch(() => { });
    }
  }

  // Handle data
  const handleData = (data, currentColorId) => {
    const { product, product_image, detail_image, size_info, product_deal, size_and_quantity } = data;
    const cMap = new Map();
    const sizeMap = new Map();
    let productInfo = {};

    size_and_quantity.forEach((item) => {
      const { productId, colorId, size, quantity } = item;
      const code = productId + "-" + colorId;

      if (sizeMap.has(code)) {
        sizeMap.get(code).push({ size, quantity });
      } else {
        sizeMap.set(code, [{ size, quantity }]);
      }
    });

    product.forEach((item, index) => {
      const {
        id,
        name,
        model,
        description,
        colorId,
        color,
        colorCode,
        price,
        sale,
        rutenLink,
        pcstoreLink,
        shopeeLink,
        rakutenLink,
        momoLink,
      } = item;
      const code = id + "-" + colorId;

      if (index === 0) {
        productInfo = {
          id,
          name,
          model,
          price: [price],
          sale: [sale],
          colorId: [colorId],
          color: [color],
          colorCode: [colorCode],
          sizes: [sizeMap.get(code)],
          rutenLink: [rutenLink],
          pcstoreLink: [pcstoreLink],
          shopeeLink: [shopeeLink],
          rakutenLink: [rakutenLink],
          momoLink: [momoLink],
          shouldShowLinks: rutenLink || pcstoreLink || shopeeLink || rakutenLink || momoLink,
          description: JSON.parse(description),
          images: [[]],
        };
      } else {
        productInfo.price.push(price);
        productInfo.sale.push(sale);
        productInfo.colorId.push(colorId);
        productInfo.color.push(color);
        productInfo.colorCode.push(colorCode);
        productInfo.sizes.push(sizeMap.get(code));
        productInfo.images.push([]);
        productInfo.rutenLink.push(rutenLink);
        productInfo.pcstoreLink.push(pcstoreLink);
        productInfo.shopeeLink.push(shopeeLink);
        productInfo.rakutenLink.push(rakutenLink);
        productInfo.momoLink.push(momoLink);
      }
      cMap.set(colorId, index);
    });

    product_image.forEach((item) => {
      const { colorId, image } = item;
      const index = cMap.get(colorId);
      productInfo.images[index].push(image);
    });

    detail_image.forEach((item) => {
      const { image } = item;
      productInfo.details
        ? productInfo.details.push(image)
        : (productInfo.details = [image]);
    });

    productInfo.sizeInfo = size_info;

    product_deal.forEach((item) => {
      productInfo.deal
        ? productInfo.deal.push(item.deal)
        : (productInfo.deal = [item.deal]);
    });

    // In stock status
    const inStockStatus = [];
    productInfo.sizes.forEach(item => {
      let inStock = false;

      for (const info of item) {
        if (info.quantity !== 0) {
          inStock = true;
          break;
        }
      }

      inStockStatus.push(inStock);
    })
    productInfo.inStock = inStockStatus;

    // Update current product
    if (currentColorId) {
      setCurrentProduct(cMap.get(currentColorId));
    }

    setInfo(productInfo);
  };

  // [ Images ]
  const scrollToThumbnail = (index) => {
    const thumbnailContainer = document.getElementById("thumbnail-container");
    const thumbnailInit = document.getElementById(`thumbnail-0`);
    const thumbnail = document.getElementById(`thumbnail-${index}`);

    if (thumbnailInit && thumbnail) {
      const scrollPosition = thumbnail.offsetLeft - thumbnailInit.offsetLeft;

      thumbnailContainer.scrollTo({ left: scrollPosition, behavior: 'smooth' });
    }
  };
  const updateImageIndex = (index) => {
    const { length } = info.images[currentProduct];
    let newIndex = -1;

    if (index < 0) {
      newIndex = length - 1;
    } else if (index >= length) {
      newIndex = 0
    } else {
      newIndex = index;
    }

    setCurrentImage(newIndex);
    scrollToThumbnail(newIndex);
  };
  const getImages = (isMobile) => {
    const list = [];

    if (info.images) {
      info.images[currentProduct].forEach((item, index) => {
        const base64String = new Buffer.from(item).toString("base64");

        if (isMobile) {
          list.push(
            <div
              key={"carousel-item" + index}
              className={`carousel-item${currentImage === index ? " active" : ""}`}
            >
              <img
                key={"mobile-img" + index}
                className={currentImage === index ? "current" : ""}
                src={`data:image/png;base64, ${base64String}`}
                alt={`Slide ${index}`}
              />
            </div>
          );
        } else {
          list.push(
            <img
              key={"desktop-img" + index}
              id={`thumbnail-${index}`}
              className={currentImage === index ? "current" : ""}
              src={`data:image/png;base64, ${base64String}`}
              alt={`Thumbnail ${index}`}
              onClick={() => setCurrentImage(index)}
            />
          );
        }
      });
    }

    return list;
  };
  const handleTouchStart = (event) => {
    startX.current = event.touches[0].clientX;
  };
  const handleTouchMove = (event) => {
    endX.current = event.touches[0].clientX;
  };
  const handleTouchEnd = () => {
    if (startX.current > endX.current) {
      moveSlide(1);
    } else if (startX.current < endX.current) {
      moveSlide(-1);
    }
  };
  const moveSlide = (direction) => {
    let newIndex = currentImage + direction;

    if (info.images) {
      if (newIndex < 0) {
        newIndex = info.images[currentProduct].length - 1;
      } else if (newIndex >= info.images[currentProduct].length) {
        newIndex = 0;
      }

      setCurrentImage(newIndex);
    }
  }
  const getImageIndicator = () => {
    if (info.images) {
      return (currentImage + 1) + "/" + info.images[currentProduct].length;
    }
  }

  // [ Price ]
  const getFormattedPrice = (number) => {
    return new Intl.NumberFormat('en-US').format(number);
  }

  // [ Feature ]
  const getFeature = () => {
    const list = [];
    const { short } = info.description;

    short.forEach((item, index) => {
      list.push(
        <div key={"feature_row" + index} className="flex v-center">
          <a key={"bullet" + index} className="bullet" />
          <a key={"feature" + index}>{item}</a>
        </div>
      );
    });

    return list;
  };

  // [ Colors ]
  const getColors = () => {
    const list = [];

    if (info.color) {
      info.colorCode.forEach((item, index) => {
        list.push(
          <div
            key={"color_container" + index}
            className="flex h-center v-center color"
            style={{
              border:
                currentProduct === index
                  ? "1px solid var(--dark5)"
                  : "1px solid transparent",
            }}
            onClick={() => {
              setCurrentProduct(index);
            }}
          >
            <div key={"color" + index} style={{ backgroundColor: item }}></div>
          </div>
        );
      });
    }

    return list;
  };

  // [ Sizes ]
  const getSizes = () => {
    return info.sizes && info.sizes[currentProduct].map((item, index) => (
      <button
        key={`size-btn${index}`}
        className={`flex h-center v-center${currentSize === index ? " selected" : ""}`}
        disabled={item.quantity === 0}
        onClick={() => setCurrentSize(index)}
      >
        {item.size}
      </button>
    ));
  }

  // [ Quantity ]
  const handleQuantity = (number) => {
    number = parseInt(number);

    if (number < 1) {
      setQuantity(1);
    } else if (number > 99) {
      setQuantity(99);
    } else {
      setQuantity(number);
    }
  };
  const handleQuantityInput = (event) => {
    const { value } = event.target;

    if (value === "") {
      setQuantity(value);
    } else if (/^[0-9]+$/.test(value)) {
      handleQuantity(value);
    }
  };

  // [ Descriptions ]
  const getDescription = () => {
    const field1 = [];
    const field2 = [];
    const field3 = [];

    if (info.description) {
      Object.keys(info.description).forEach((item, index) => {
        if (item !== "short") {
          // Make \n into new line
          const parts = item.split("\n");
          const advantages = info.description[item].advantage.split("\n");
          const materials = info.description[item].material.split("\n");
          const maxLength = Math.max(
            parts.length,
            advantages.length,
            materials.length
          );

          field1.push(
            <div
              key={"table_row_container" + index + "-1"}
              className="flex-column column"
            >
              {getSubDescription(parts, maxLength, index, 1)}
            </div>
          );
          field2.push(
            <div
              key={"table_row_container" + index + "-2"}
              className="flex-column column"
            >
              {getSubDescription(advantages, maxLength, index, 2)}
            </div>
          );
          field3.push(
            <div
              key={"table_row_container" + index + "-3"}
              className="flex-column column"
            >
              {getSubDescription(materials, maxLength, index, 3)}
            </div>
          );
        } else {
          field1.push(
            <div key={"table_row_container" + index + "-1"} className="column">
              <a key={"table_row" + index + "-1"}>部位</a>
            </div>
          );
          field2.push(
            <div key={"table_row_container" + index + "-2"} className="column">
              <a key={"table_row" + index + "-2"}>材質說明</a>
            </div>
          );
          field3.push(
            <div key={"table_row_container" + index + "-3"} className="column">
              <a key={"table_row" + index + "-3"}>優點</a>
            </div>
          );
        }
      });
    }

    return (
      <div className="flex table">
        <div className="flex table-wrapper">
          <div className="flex-column row">{field1}</div>
          <div className="flex-column row">{field2}</div>
          <div className="flex-column row">{field3}</div>
        </div>
      </div>
    );
  };
  const getSubDescription = (info, length, row, column) => {
    const list = [];

    for (let i = 0; i < length; i++) {
      // Replace ^ with power of
      const content = [];
      const powerNum = [];
      let text = i < info.length ? info[i] : "";
      text = text.split("^(");
      if (text.length > 1) {
        text.forEach((item, index) => {
          if (index > 0) {
            const numPos = item.indexOf(")");
            powerNum.push(item.slice(0, numPos));
            text[index] = item.slice(numPos + 1);
          }
        });
      }

      text.forEach((item, index) => {
        if (index === 0) {
          content.push(
            <a key={"table_text" + row + "_" + column + "_" + i + "_" + index}>
              {item}
            </a>
          );
        } else {
          content.push(
            <a
              key={"table_num" + row + "_" + column + "_" + i + "_" + index}
              className="number"
            >
              {powerNum[index - 1]}
            </a>
          );
          content.push(
            <a key={"table_text" + row + "_" + column + "_" + i + "_" + index}>
              {item}
            </a>
          );
        }
      });

      list.push(
        <div
          key={"table_column" + row + "_" + column + "_" + i}
          className="flex v-center"
        >
          {content}
        </div>
      );
    }

    return list;
  };
  const getProductInfoImages = () => {
    const list = [];

    if (info.details) {
      info.details.forEach((item, index) => {
        const base64String = new Buffer.from(item).toString("base64");
        list.push(
          <img
            key={"detail_image" + index}
            src={`data:image/png;base64, ${base64String}`}
          />
        );
      });
    }

    return list;
  };
  const getSizeInfoImage = () => {
    if (info.sizeInfo) {
      const base64String = new Buffer.from(info.sizeInfo).toString("base64");

      return <img src={`data:image/png;base64, ${base64String}`} />;
    }

    return null;
  };

  // Add to cart
  const addToCart = (goToCart) => {
    let isValidSize = true;

    // Validation
    if (currentSize !== null) {
      // Expires in 7 days
      const expires = new Date(new Date().getTime() + 7 * 24 * 60 * 60 * 1000);
      const item = {
        productId: info.id,
        colorId: info.colorId[currentProduct],
        size: info.sizes[currentProduct][currentSize].size,
        quantity,
      };

      setValidSize(true);

      // Add item to cart database
      callAddToCartEndpoint(item, expires);
    } else {
      isValidSize = false;
      setValidSize(false);
    }

    if (goToCart && isValidSize) {
      setTimeout(() => navigate("/cart"), 300);
    }
  };

  // Validate shop link
  const validLink = (type) => {
    return info[type + "Link"] && info[type + "Link"][currentProduct]
      ? ""
      : " invalid";
  };
  // Go shop link
  const goLink = (type) => {
    if (info[type + "Link"] && info[type + "Link"][currentProduct]) {
      window.open(info[type + "Link"][currentProduct], "_blank");
    }
  };

  return (
    <div id="page-product-info" className="flex-column v-center page">
      <div className="flex h-between page-content">
        <div className="flex-column side-bar">
          {/* Category */}
          <CategorySideBar />
        </div>
        {/* Loading animation */}
        {loading &&
          <div className="flex h-center loader-cont">
            <LoadingAnimation />
          </div>
        }
        {/* Product info */}
        {!loading && (
          <div className="flex-column info">
            <div className="flex info-wrapper">
              {/* Images */}
              <div className="flex-column v-center product-images desktop">
                <div className="flex h-center v-center current-img-container">
                  <ImageMagnifier
                    imageSrc={info.images
                      ? `data:image/png;base64, ${new Buffer.from(
                        info.images[currentProduct][currentImage]
                      ).toString("base64")}`
                      : null}
                    altText="Product image"
                    zoomLevel={2}
                    squareSize={100}
                  />
                </div>
                <div className="flex image-list-container">
                  <button onClick={() => updateImageIndex(currentImage - 1)}>
                    <FaChevronLeft />
                  </button>
                  <div id="thumbnail-container" className="flex image-list">
                    {getImages()}
                  </div>
                  <button onClick={() => updateImageIndex(currentImage + 1)}>
                    <FaChevronRight />
                  </button>
                </div>
              </div>
              <div className="flex-column v-center product-images mobile">
                <div
                  className="carousel"
                  onTouchStart={handleTouchStart}
                  onTouchMove={handleTouchMove}
                  onTouchEnd={handleTouchEnd}
                >
                  <div
                    className="carousel-inner"
                    style={{
                      width: info.images && info.images[currentProduct] ? info.images[currentProduct].length + "00%" : "100%",
                      transform: `translateX(-${currentImage * 100}%)`
                    }}
                  >
                    {getImages(true)}
                  </div>
                </div>
                <a className="flex h-center image-indicator">{getImageIndicator()}</a>
              </div>
              <div className="flex-column detail">
                {/* Name & Model */}
                <h2>
                  {info.name} <a>(型號: {info.model})</a>
                </h2>
                {/* Deal */}
                {info.deal && (
                  <div className="flex v-center deal">
                    <div className="flex v-center">
                      <a>活動</a>
                      <VscTriangleRight />
                    </div>
                    <a className="text">{info.deal}</a>
                  </div>
                )}
                {/* Price */}
                {info.price && info.sale && (
                  <div className="flex-column price">
                    <div className="flex-column original-price">
                      <a>市售價</a>
                      <a className="flex strikethrough">
                        <span className="currency">NT$</span>
                        <span className="number">{info.price && getFormattedPrice(info.price[currentProduct])}</span>
                      </a>
                    </div>
                    <div className="flex-column sale-price">
                      <a>促銷價</a>
                      <a className="flex sale">
                        <span className="currency">NT$</span>
                        <span className="number">{info.sale && getFormattedPrice(info.sale[currentProduct])}</span>
                      </a>
                    </div>
                  </div>
                )}
                {/* Color */}
                <div className="flex-column h-center block color-wrapper">
                  <a className="title">顏色</a>
                  <div className="flex v-center content">
                    <div className="flex">{getColors()}</div>
                    <a className="current_color">
                      {info.price && info.color[currentProduct]}
                    </a>
                  </div>
                </div>
                {/* Size */}
                <div className="flex-column h-center size">
                  <div className="flex-column h-center block">
                    <a className="title">尺寸</a>
                    <div className="flex content">
                      {getSizes()}
                    </div>
                  </div>
                  {!validSize && <a className="size-warning">請選擇一個尺寸</a>}
                </div>
                {/* Quantity */}
                <div className="flex-column block quantity">
                  <a className="title">數量</a>
                  <div className="flex v-center content">
                    <button
                      className="flex v-center"
                      onClick={() => handleQuantity(quantity - 1)}
                    >
                      <MdRemove />
                    </button>
                    <input
                      type="text"
                      value={quantity}
                      onChange={handleQuantityInput}
                    />
                    <button
                      className="flex v-center"
                      onClick={() => handleQuantity(quantity + 1)}
                    >
                      <MdAdd />
                    </button>
                  </div>
                </div>
                {/* Feature */}
                {info.description && (
                  <div className="flex-column feature">{getFeature()}</div>
                )}
                {/* Add to cart & Check out */}
                <div className="flex ac-buttons buttons">
                  <button
                    className="flex h-center v-center add-to-cart"
                    disabled={!info.inStock[currentProduct]}
                    onClick={() => addToCart(false)}
                  >
                    <FaCartShopping />
                    加入購物車
                  </button>
                  <button
                    className="check-out"
                    disabled={!info.inStock[currentProduct]}
                    onClick={() => addToCart(true)}
                  >
                    立即結帳
                  </button>
                </div>
              </div>
            </div>
            {/* Shops - Ruten, PChome, Shopee */}
            <div className="flex-column v-center shops">
              <a className="discount" style={{ opacity: info.shouldShowLinks ? "1" : "0.4" }}>也可以到我們其他購物商城購買</a>
              <div className="flex h-center divider" style={{ opacity: info.shouldShowLinks ? "1" : "0.4" }}>
                <BsArrowDownShort />
                <BsArrowDownShort />
                <BsArrowDownShort />
                <BsArrowDownShort />
                <BsArrowDownShort />
                <BsArrowDownShort />
                <BsArrowDownShort />
                <BsArrowDownShort />
                <BsArrowDownShort />
                <BsArrowDownShort />
                <BsArrowDownShort />
              </div>
              <div className="flex h-center buttons">
                <button
                  className={"flex h-center v-center" + validLink("ruten")}
                  onClick={() => goLink("ruten")}
                >
                  <img src={ShopRuten} />
                  <a>露天拍賣</a>
                </button>
                <button
                  className={"flex h-center v-center" + validLink("shopee")}
                  onClick={() => goLink("shopee")}
                >
                  <img src={ShopShopee} />
                  <a>蝦皮拍賣</a>
                </button>
                <button
                  className={"flex h-center v-center" + validLink("pcstore")}
                  onClick={() => goLink("pcstore")}
                >
                  <img src={ShopPChome} />
                  <div className="flex-column">
                    <a>PChome</a>
                    <a>商店街</a>
                  </div>
                </button>
                <button
                  className={"flex h-center v-center" + validLink("momo")}
                  onClick={() => goLink("momo")}
                >
                  <img src={ShopMomo} />
                  <div className="flex-column">
                    <a>momo</a>
                    <a>購物網</a>
                  </div>
                </button>
                <button
                  className={"flex h-center v-center" + validLink("rakuten")}
                  onClick={() => goLink("rakuten")}
                >
                  <img src={ShopRakuten} />
                  <a>樂天市場</a>
                </button>
              </div>
            </div>
            {/* Descriptions */}
            <div className="flex-column description">
              {/* Title list */}
              <div className="flex title-list">
                <a
                  style={{
                    fontWeight: currentDescription === 0 ? "bold" : "normal",
                  }}
                  onClick={() => setCurrentDescription(0)}
                >
                  商品資訊
                </a>
                <a
                  style={{
                    fontWeight: currentDescription === 1 ? "bold" : "normal",
                  }}
                  onClick={() => setCurrentDescription(1)}
                >
                  尺寸指南
                </a>
              </div>
              {/* Descriptions */}
              {/* [ Product info ] */}
              {currentDescription === 0 && (
                <div className="flex-column content">
                  {/* Detail images */}
                  <div className="flex-column block detail-images">
                    {getProductInfoImages()}
                  </div>
                  {/* 商品規格 */}
                  <div className="flex-column block">
                    <a className="title">商品規格</a>
                    {getDescription()}
                  </div>
                  {/* 安心小檔案 */}
                  <div className="flex-column block file">
                    <a className="title">安心小檔案</a>
                    <div className="flex v-center">
                      <AiTwotoneCrown />
                      <a>台灣製MIT微笑標章產品</a>
                    </div>
                    <div className="flex v-center">
                      <AiTwotoneCrown />
                      <a>符合CNS 20345國家標準規範</a>
                    </div>
                    <div className="flex v-center">
                      <AiTwotoneCrown />
                      <a>通過ISO 9001國際品保認證</a>
                    </div>
                    <div className="flex v-center">
                      <AiTwotoneCrown />
                      <a>取得經濟部標檢局驗證登錄證書</a>
                    </div>
                    <div className="flex v-center">
                      <AiTwotoneCrown />
                      <a>每雙鞋款均投保1000萬產品責任險</a>
                    </div>
                  </div>
                </div>
              )}
              {/* [ Size info ] */}
              {currentDescription === 1 && (
                <div className="flex-column content">
                  <div className="flex-column v-center block size-info">
                    {getSizeInfoImage()}
                  </div>
                </div>
              )}
            </div>
          </div>
        )}
      </div>
      <Footer />
    </div>
  );
}
