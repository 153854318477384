import React, { useState, useEffect, useCallback, useRef } from "react";
import axios from "axios";
// Captcha
import HCaptcha from "@hcaptcha/react-hcaptcha";
import { verifyCaptcha } from "../../../utils/Captcha";
// Icons
import { CgClose } from "react-icons/cg";
// Css
import "./signInUp.scss";

export default function ForgotPassword(props) {
  const { insidePage } = props;

  // [ Mobile ]
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 925);
  // [ Captcha ]
  const [token, setToken] = useState(null);
  const [passCaptcha, setPassCaptcha] = useState(false);
  // [ User info ]
  // User name
  const userName = useRef("");
  // Validation
  const [inValid, setInValid] = useState(false);
  // [ Error ]
  const [error, setError] = useState(null);
  // [ Complete ] - email sent message
  const [showSendEmail, setShowSendEmail] = useState(false);

  // Env
  const contactEmail = process.env.REACT_APP_CONTACT_EMAIL;
  const environment = process.env.NODE_ENV;

  // Detect Enter key press
  useEffect(() => {
    document.addEventListener("keydown", keyDownHandler1);
    window.addEventListener('resize', handleResize);

    return () => {
      document.removeEventListener("keydown", keyDownHandler2);
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  useEffect(() => {
    // Handle keydown event
    if (showSendEmail) {
      document.removeEventListener("keydown", keyDownHandler1);
      document.addEventListener("keydown", keyDownHandler2);
    }

    // Captcha
    if (passCaptcha) {
      callForgotPasswordEndpoint();
      setPassCaptcha(false);
    }
  }, [showSendEmail, passCaptcha]);

  // Handle window resize
  const handleResize = () => {
    setIsMobile(window.innerWidth <= 925);
  };

  // Key down handlers
  const keyDownHandler1 = useCallback((event) => {
    if (event.key === "Enter") {
      handleSubmit();
    }
  }, []);
  const keyDownHandler2 = useCallback((event) => {
    if (event.key === "Enter") {
      showSignIn();
    }
  }, []);

  // Submit
  async function handleSubmit() {
    if (environment === "production") {
      verifyCaptcha(token, setPassCaptcha, setError);
    } else {
      callForgotPasswordEndpoint();
    }
  }

  // Endpoint
  async function callForgotPasswordEndpoint() {
    await axios
      .get(`${process.env.REACT_APP_API}/accounts/forgotPassword?`, {
        params: {
          userName: userName.current.value,
        },
        headers: { "x-api-key": process.env.REACT_APP_API_KEY },
      })
      .then((res) => {
        if (res.status === 204) {
          setInValid(true);
          setError("帳號不存在, 如果您忘記帳號, 可以點擊上方忘記帳號找回帳號");
        } else if (res.status === 200) {
          // Show send email message
          setShowSendEmail(true);
        }
      })
      .catch((err) => {
        setError(
          "網頁錯誤, 目前無法找回密碼, 若您看到此訊息, 請與我們聯絡: " +
          contactEmail
        );
      });
  }

  // Show sign in modal
  const showSignIn = () => {
    props.setShowForgotPassword(false);
    props.setShowSignInAnimation(false);
    props.setShowSignIn(true);
  };

  // Show forgot user name modal
  const showForgotUserName = () => {
    props.setShowForgotPassword(false);
    props.setShowForgotUserName(true);
  };

  // Close forgot password modal
  const closeForgotPassword = () => {
    props.setShowForgotPassword(false);
  };

  return (
    <div
      id="page-enroll"
      style={{
        position: insidePage ? "static" : "fixed",
        height: insidePage ? "auto" : "100vh",
        width: insidePage ? "100%" : "100vw",
        zIndex: insidePage ? 0 : 50,
        backgroundColor: insidePage ? "var(--light3)" : "var(--dark6-99)",
      }}
      className="flex h-center v-center"
    >
      <div
        id="page-forgot"
        style={{
          margin: 0,
          paddingTop: insidePage ? 0 : "40px",
          boxShadow: insidePage ? "none" : "5px 5px 10px var(--shadow)",
          overflowY: insidePage ? "auto" : "scroll",
        }}
        className="flex-column v-center content"
      >
        {!insidePage && (
          <CgClose className="close" onClick={closeForgotPassword} />
        )}
        {!showSendEmail && (
          <div className="flex-column v-center w-100">
            <h2>忘記密碼</h2>
            <div className="flex-column v-start text-inputs">
              <a>
                帳號<span>*</span>
              </a>
              <input
                type="text"
                ref={userName}
                className={inValid ? "invalid-input" : ""}
              />
              <div className="flex h-center forgot">
                <a className="forgot-pass" onClick={showForgotUserName}>
                  忘記帳號
                </a>
              </div>
              <div className="flex h-center captcha">
                <HCaptcha
                  size={isMobile ? "compact" : "normal"}
                  sitekey={process.env.REACT_APP_CAPTCHA_SITE_KEY}
                  onError={() =>
                    setError(
                      "網頁錯誤, 請刷新頁面或與我們聯絡: " + contactEmail
                    )
                  }
                  onExpire={() => setError("驗證過期, 請刷新頁面")}
                  onVerify={setToken}
                />
              </div>
            </div>
            {error !== null && <a className="error-msg">{error}</a>}
            <button className="button1 submit" onClick={handleSubmit}>
              確認
            </button>
            <a className="go-back" onClick={showSignIn}>
              返回
            </a>
          </div>
        )}
        {showSendEmail && (
          <div className="flex-column remind-msg">
            <a>
              重設密碼信件將在10分鐘內發送到您註冊的Email, 請前往您的Email驗證。
            </a>
            <div className="flex">
              <a>-</a>
              <a>
                若是您等了10分鐘還是沒有收到郵件,
                可以先確認您的垃圾郵件夾。如果您確定沒有收到我們的郵件,
                可以與我們聯繫: {contactEmail}
              </a>
            </div>
            <button className="button1 submit" onClick={showSignIn}>
              確認
            </button>
          </div>
        )}
      </div>
    </div>
  );
}
