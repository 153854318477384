import React from "react";
import { useNavigate } from "react-router-dom";
// Category
import { category } from "../../pages/Data";
// Icons
import { TbCaretRight } from "react-icons/tb";
// Css
import "./categorySideBar.scss";

export default function CategorySideBar(props) {
    const navigate = useNavigate();

    const handleClickOnCategory = (name) => {
        if (props.isFromProductList) {
            props.setUpdateCategory(true);
        }
        navigate("/product-list/" + name)
        window.scrollTo(0, 0);
    }

    // Get category
    const getCategory = () => {
        const list = [];

        for (let i = 0; i < category.length; i++) {
            const subList = [];
            const { type } = category[i];

            for (let j = 0; j < type.length; j++) {
                subList.push(
                    <a
                        key={"type" + i + "_" + j}
                        className="flex v-center sub-list"
                        onClick={() => handleClickOnCategory(type[j])}
                    >
                        <TbCaretRight /> {type[j]}
                    </a>
                );
            }

            list.push(
                <div key={"category_div" + i} className="flex-column">
                    <a
                        key={"category_text" + i}
                        className="main-list"
                        onClick={() => handleClickOnCategory(category[i].name)}
                    >
                        {category[i].name}
                    </a>
                    {subList}
                </div>
            );
        }

        return list;
    };

    return (
        <div id="category-side-bar" className="flex-column" >
            <div id="category-content" className="flex-column v-center">
                <a className="title">商品類別</a>
                {getCategory()}
            </div>
        </div >
    )
}