import React, { useState, useEffect } from "react";
import { Buffer } from "buffer";
import axios from "axios";
// Footer
import Footer from "../../../widgets/Footer/Footer";
// Helper
import { getCookie } from "../../../utils/Cookie";
// Icon
import { FaQuestionCircle } from "react-icons/fa";
// Css
import "./orderDetail.scss";

export default function OrderTracking() {
  // Order detail
  const [order, setOrder] = useState([]);
  // Shipping & Contact detail
  const [shipping, setShipping] = useState({});
  const [isByCS, setIsByCS] = useState(false);
  // Order cancellation
  const [orderCanceled, setOrderCanceled] = useState(false);
  // Order doesn't exist
  const [notExist, setNotExist] = useState(true);
  // Status
  const [statusNum, setSatusNum] = useState(-1);

  useEffect(() => {
    let path = window.location.pathname;
    path = path.split("/");
    const orderNumber = path[2];
    const email = path[3];

    // Call endpoint
    callGetOrderDetailEndpoint(orderNumber, email);
  }, []);

  // Endpoint
  async function callGetOrderDetailEndpoint(orderNumber, email) {
    const info = getCookie();

    await axios
      .get(
        `${process.env.REACT_APP_API}/orders/orderDetail?trackingNumber=${orderNumber}&email=${email}&user=${info && info.user ? info.user : null}`,
        {
          headers: { "x-api-key": process.env.REACT_APP_API_KEY },
        }
      )
      .then((res) => {
        if (res.status === 200) {
          // Update shipping & order info
          const { shippingInfo } = res.data;

          setShipping({
            trackingNumber: orderNumber,
            fullName: shippingInfo[0].fullName,
            gender: shippingInfo[0].gender,
            phoneNumber: shippingInfo[0].phoneNumber,
            email: shippingInfo[0].email,
            address: shippingInfo[0].address,
            city: shippingInfo[0].city,
            district: shippingInfo[0].district,
            zipcode: shippingInfo[0].zipcode,
            shipping: shippingInfo[0].shipping,
            originalTotal: shippingInfo[0].originalTotalAmount,
            total: shippingInfo[0].totalAmount,
            shippingMethod: shippingInfo[0].shippingMethod,
            pickUpStoreId: shippingInfo[0].pickUpStoreId,
            pickUpStoreName: shippingInfo[0].pickUpStoreName,
            pickUpStoreAddress: shippingInfo[0].pickUpStoreAddress,
            expectedDeliveryDate: shippingInfo[0].expectedDeliveryDate ? shippingInfo[0].expectedDeliveryDate : "",
            carrier: shippingInfo[0].carrier,
            carrierTrackingNumber: shippingInfo[0].carrierTrackingNumber,
            note: shippingInfo[0].note,
            status: shippingInfo[0].status,
            date: shippingInfo[0].date,
            payment: "信用卡",
          });
          setOrder(res.data.order);
          setNotExist(false);
          setIsByCS(shippingInfo[0].shippingMethod === "7-ELEVEN");

          // Set order cancellation state
          setOrderCanceled(shippingInfo[0].isCanceled);

          // Set status bar
          handleStatus(shippingInfo[0].status, shippingInfo[0].isCanceled);
        }
      })
      .catch((err) => {
        if (err.response && err.response.status === 403) {
          setNotExist(true);
        }
      });
  }

  // Get order list
  const getOrderList = () => {
    const list = [];

    order.forEach((item, index) => {
      const { name, model, colorName, price, sale, size, quantity, image } =
        item;

      list.push(
        <div key={"order" + index}>
          <div key={"order-row-mobile" + index} className="flex h-between v-center row item">
            <div key={"order-mobile-content" + index} className="flex v-center item-content">
              <img
                key={"order-image" + index}
                src={
                  image
                    ? `data:image/png;base64, ${new Buffer.from(image).toString(
                      "base64"
                    )}`
                    : null
                }
              />
              <div key={"order-mobile-info" + index} className="flex-column h-center info">
                {/* Product name & model */}
                <div
                  key={"order-name-div-desktop" + index}
                  className="flex v-center desktop name"
                >
                  <a key={"order-name" + index}>{`${name}`}</a>
                  <a key={"order-model" + index}>{`(型號: ${model})`}</a>
                </div>
                <div
                  key={"order-name-div-mobile" + index}
                  className="mobile name"
                >
                  <a key={"order-name" + index}>{`${name}`}</a>
                  <a key={"order-model" + index}>{`(型號: ${model})`}</a>
                </div>
                {/* Color & Size */}
                <div key={"order-specs-div" + index} className="flex v-center specs-div">
                  <a key={"order-specs-title" + index}>規格: </a>
                  <a key={"order-specs-detail" + index}>{colorName} / {size}</a>
                </div>
                {/* Quantity */}
                <div key={"order-quantity-div" + index} className="flex v-center specs-div">
                  <a key={"order-quantity-title" + index}>數量: </a>
                  <a key={"order-quantity" + index}>{quantity}</a>
                </div>
                {/* Size */}
                <div key={"order-price-div" + index} className="flex h-between price-div">
                  <div key={`order-price-cont${index}`} className="flex v-center">
                    {sale !== price && (
                      <a key={"order-original-price" + index} className="price">
                        ${(quantity * price).toLocaleString("en-US")}
                      </a>
                    )}
                    <a key={"order-total" + index} className="sale">
                      ${(quantity * sale).toLocaleString("en-US")}
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      );
    });

    return list;
  };

  // Get order detail list
  const getOrderDetailList = () => {
    const list = [];

    if (shipping.details) {
      Object.keys(shipping.details).forEach((item, index) => {
        list.push(
          <div
            key={"order-detail-div" + index}
            className="flex v-center"
          >
            <a key={"order-detail" + index}>{formatDateTime(shipping.details[item])}</a>
            <a key={"order-detail" + index}>{item}</a>
          </div>
        )
      })
    }

    return list;
  }

  // Format date
  const formatDate = (dateStr) => {
    const date = new Date(dateStr);

    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, '0'); // Months are zero-indexed
    const day = String(date.getDate()).padStart(2, '0');

    return `${year}/${month}/${day}`;
  };

  // Handle status
  const handleStatus = (status, isCanceled) => {
    if (isCanceled) {
      setSatusNum(-1);
    } else if (status === "待付款") {
      setSatusNum(0);
    } else if (status === "付款完成") {
      setSatusNum(1);
    } else if (status === "訂單處理中") {
      setSatusNum(2);
    } else if (status === "已發貨") {
      setSatusNum(3);
    } else if (status === "商品送達") {
      setSatusNum(4);
    }
  }

  return (
    <div id="page-order-detail" className="flex-column v-center page">
      <div className="flex-column v-center page-content">
        <h2>訂單詳情</h2>
        {!notExist &&
          order.length > 0 &&
          Object.keys(shipping).length > 5 && (
            <div className="flex-column v-center content">
              {/* Status */}
              <div className="flex h-center status">
                <svg width="100%" height="100%" viewBox="0 0 610 80" xmlns="http://www.w3.org/2000/svg">
                  {/* Base line */}
                  <line x1="10" y1="10" x2="600" y2="10" stroke="var(--dark12)" strokeWidth="8" />
                  {/* Progress bar */}
                  <line x1="10" y1="10" x2={statusNum >= 0 ? 150 * statusNum + 5 : 10} y2="10" stroke="var(--sub4)" strokeWidth="8" />
                  {/* Step 1 */}
                  <circle cx="10" cy="10" r="10" fill={statusNum >= 0 ? "var(--sub4" : "var(--dark12)"} />
                  <text x="10" y="48" fill="var(--dark5)" fontSize="16" textAnchor="middle">待付款</text>
                  {/* Step 2 */}
                  <circle cx="150" cy="10" r="10" fill={statusNum >= 1 ? "var(--sub4" : "var(--dark12)"} />
                  <text x="150" y="48" fill="var(--dark5)" fontSize="16" textAnchor="middle">付款完成</text>
                  {/* Step 3 */}
                  <circle cx="300" cy="10" r="10" fill={statusNum >= 2 ? "var(--sub4" : "var(--dark12)"} />
                  <text x="300" y="48" fill="var(--dark5)" fontSize="16" textAnchor="middle">訂單處理中</text>
                  {/* Step 4 */}
                  <circle cx="450" cy="10" r="10" fill={statusNum >= 3 ? "var(--sub4" : "var(--dark12)"} />
                  <text x="450" y="48" fill="var(--dark5)" fontSize="16" textAnchor="middle">已發貨</text>
                  {/* Step 5 */}
                  <circle cx="600" cy="10" r="10" fill={statusNum >= 4 ? "var(--sub4" : "var(--dark12)"} />
                  <text x="600" y="48" fill="var(--dark5)" fontSize="16" textAnchor="middle">商品送達</text>
                </svg>
              </div>
              <div className="flex h-between content-wrap">
                <div className="flex-column content-left">
                  <div className="flex h-between info-wrap">
                    {/* Tracking info */}
                    <div className="flex-column block tracking">
                      <div className="field-title">
                        <h3>訂單資訊</h3>
                      </div>
                      <div className="flex-column field">
                        <div className="flex-column h-center column">
                          <a className="title">訂單編號:</a>
                          <a className="field-content">{shipping.trackingNumber}</a>
                        </div>
                        <div className="flex-column h-center column">
                          <a className="title">訂單日期:</a>
                          <a className="field-content">{formatDate(shipping.date)}</a>
                        </div>
                        <div className="flex-column h-center column">
                          <a className="title">訂單狀態:</a>
                          <a className="field-content">
                            {!orderCanceled ?
                              <span>{shipping.status}</span> :
                              <span className="canceled">訂單已取消</span>
                            }
                          </a>
                        </div>
                        {statusNum < 4 &&
                          <div className="flex-column h-center column">
                            <a className="title">預計到貨日:</a>
                            <a className="field-content">
                              {shipping.expectedDeliveryDate
                                ? formatDate(shipping.expectedDeliveryDate)
                                : "(待定)"}
                            </a>
                          </div>
                        }
                        {!orderCanceled && !isByCS && (
                          <div className="flex-column h-center column">
                            <a className="title">貨運公司:</a>
                            <a className="field-content">{shipping.carrier ? shipping.carrier : "--"}</a>
                          </div>
                        )}
                        {!orderCanceled && !isByCS && (
                          <div className="flex-column h-center column">
                            <a className="title">物流單號:</a>
                            <a className="field-content">{shipping.carrierTrackingNumber ? shipping.carrierTrackingNumber : "--"}</a>
                          </div>
                        )}
                      </div>
                    </div>
                    {/* Shipping info */}
                    <div className="flex-column block shipping">
                      <div className="field-title">
                        <h3>購買資訊</h3>
                      </div>
                      <div className="flex-column field">
                        <div className="flex-column h-center column">
                          <a className="title">聯絡姓名:</a>
                          <a className="field-content">{shipping.fullName} {shipping.gender}</a>
                        </div>
                        <div className="flex-column h-center column">
                          <a className="title">聯絡電話:</a>
                          <a className="field-content">(+886) {shipping.phoneNumber}</a>
                        </div>
                        <div className="flex-column h-center column">
                          <a className="title">Email:</a>
                          <a className="field-content">{shipping.email}</a>
                        </div>
                        <div className="flex-column h-center column">
                          <a className="title">付款方式:</a>
                          <a className="field-content">{shipping.payment}</a>
                        </div>
                        <div className="flex-column h-center column">
                          <a className="title">送貨方式:</a>
                          <a className="field-content">{shipping.shippingMethod}</a>
                        </div>
                        {isByCS ?
                          <div className="flex-column h-center column">
                            <a className="title">取貨門市:</a>
                            <div className="flex-column h-center column store-content">
                              <a className="field-content"><b>超商店號:</b> {shipping.pickUpStoreId}</a>
                              <a className="field-content"><b>超商店名:</b> {shipping.pickUpStoreName}</a>
                              <a className="field-content"><b>超商地址:</b> {shipping.pickUpStoreAddress}</a>
                            </div>
                          </div> :
                          <div className="flex-column h-center column">
                            <a className="title">送貨地址:</a>
                            <a className="field-content">{shipping.zipcode} {shipping.city} {shipping.district} {shipping.address}</a>
                          </div>
                        }
                      </div>
                    </div>
                  </div>
                  {/* Note */}
                  <div className="flex-column block note">
                    <div className="field-title">
                      <h3>備註</h3>
                    </div>
                    <div className="flex v-baseline field">
                      <a>{shipping.note ? shipping.note : "(無)"}</a>
                    </div>
                  </div>
                  {/* Order details */}
                  <div className="flex-column block order-detail">
                    <div className="field-title">
                      <h3>商品資訊</h3>
                    </div>
                    <div className="flex-column field">
                      {getOrderList()}
                    </div>
                  </div>
                </div>
                <div className="flex-column content-right summary-wrap">
                  {/* Calculation */}
                  <div className="flex-column calculation">
                    <div className="flex h-between">
                      <a>小計:</a>
                      <a>NT$ {shipping.originalTotal.toLocaleString("en-US")}</a>
                    </div>
                    <div className="flex h-between discount">
                      <a>折扣:</a>
                      <a>
                        - NT${" "}
                        {(shipping.originalTotal - shipping.total).toLocaleString(
                          "en-US"
                        )}
                      </a>
                    </div>
                    <div className="flex h-between">
                      <a>運費:</a>
                      <a>NT$ {shipping.shipping.toLocaleString("en-US")}</a>
                    </div>
                  </div>
                  {/* Summary */}
                  <div className="flex h-between summary">
                    <a>合計:</a>
                    <a>
                      NT${" "}
                      {(shipping.total + shipping.shipping).toLocaleString(
                        "en-US"
                      )}
                    </a>
                  </div>
                </div>
              </div>
              {/* Contact us */}
              <div className="flex v-center contact-us">
                <FaQuestionCircle />
                <p>(有任何問題請<a href="/contact/聯絡我們" target="_blank">聯絡我們</a>)</p>
              </div>
            </div>
          )}
        {notExist &&
          <div className="flex-column v-center content no-record">
            <a>很抱歉, 找不到您要查的訂單。</a>
            {/* Contact us */}
            <div className="flex v-center contact-us">
              <FaQuestionCircle />
              <p>(有任何問題請<a href="/contact/聯絡我們" target="_blank">聯絡我們</a>)</p>
            </div>
          </div>
        }
      </div>
      <Footer />
    </div>
  );
}
