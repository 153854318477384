import React, { useState } from "react";
// Footer
import Footer from "../../widgets/Footer/Footer";
// Map
import TWMap from "./TWMap";
// Location List
import { locationList } from "../Data";
// Icons
import { IoLocationSharp } from "react-icons/io5";
import { FcPhoneAndroid } from "react-icons/fc";
// Images
import location1 from "../../files/images/location-1.jpeg";
import location2 from "../../files/images/location-2.jpeg";
// Css
import "./locations.scss";

export default function Locations() {
  // Catalog menu - 專業工作鞋/舒適生活鞋
  const [activeList, setActiveList] = useState(1);
  // Map
  const [loadMap, setLoadMap] = useState(0);
  // Hovering on location event
  const [hoverIndex, setHoverIndex] = useState(2);
  const [neverHovered, setNeverHovered] = useState(true);

  // Get location list
  const getLocations = () => {
    const locations = [];

    for (let i = 0; i < locationList.length; i++) {
      locations.push(
        <div
          key={"location" + i}
          className="flex-column h-center v-center location"
          style={{
            opacity: neverHovered ? 0 : 1,
            padding: hoverIndex === i ? "20px 30px" : "10px 30px",
            animation: neverHovered
              ? `slide-down1 0.4s ${0.4 * (i + 1)}s forwards`
              : "none",
          }}
          onMouseOver={() => {
            setHoverIndex(i);
            hovered(i);
          }}
        >
          <iframe
            src={locationList[i].src}
            style={{
              display: hoverIndex === i ? "flex" : "none",
              border: 0,
            }}
            width="400"
            height="200"
            loading="eager"
            onLoad={() => setLoadMap(loadMap + 1)}
          ></iframe>
          <a
            key={"location_info" + i}
            className="name"
            style={{
              margin: hoverIndex === i ? "-3px 0 8px 0" : "0",
            }}
          >
            {locationList[i].info}
          </a>
          <a
            key={"location_address" + i}
            style={{
              display: hoverIndex === i ? "flex" : "none",
            }}
            className="v-center address"
          >
            <IoLocationSharp /> {locationList[i].address}
          </a>
          <div
            className="flex h-between contact"
            style={{
              display: hoverIndex === i ? "flex" : "none",
            }}
          >
            <p key={"location_phone" + i} className="flex v-center">
              <FcPhoneAndroid />
              <a
                key={"location_phone_text" + i}
                href={"tel:" + locationList[i].phone}
              >
                {locationList[i].phone}
              </a>
            </p>
            <a key={"location_fax" + i}>
              <span>Fax:</span> {locationList[i].fax}
            </a>
          </div>
          <button
            style={{
              display: hoverIndex === i ? "flex" : "none",
            }}
            onClick={() => copyAddress(locationList[i].address)}
          >
            複製地址
          </button>
        </div>
      );
    }

    return locations;
  };

  // Copy address
  const copyAddress = (text) => {
    navigator.clipboard.writeText(text);
    alert("地址複製成功！");
  };

  // Set hover over city state
  const hovered = (index) => {
    setNeverHovered(false);
    setHoverIndex(index);
  };

  // Update active menu list
  const updateActiveList = (index) => {
    setActiveList(index);
    setNeverHovered(true);
  };

  // Determine list shown event
  const getActiveList = (current) => {
    if (activeList === current) {
      return "menu-active";
    } else {
      return "";
    }
  };

  return (
    <div id="page-location" className="flex-column v-center page">
      <div className="flex-column v-center page-content">
        <h2>公司據點</h2>
        {/* List menu */}
        <div className="flex v-center list-menu">
          <div className="flex v-center menu-content">
            <a className={getActiveList(1)} onClick={() => updateActiveList(1)}>
              公司據點
            </a>
            <div className="divider"></div>
            <a className={getActiveList(2)} onClick={() => updateActiveList(2)}>
              經銷商據點
            </a>
          </div>
        </div>
        {/* 公司據點 */}
        {activeList === 1 && (
          <div className="flex h-center v-center map-container">
            <TWMap
              neverHovered={neverHovered}
              setNeverHovered={setNeverHovered}
              hoverIndex={hoverIndex}
              setHoverIndex={setHoverIndex}
              hovered={hovered}
            />
            <div className="flex-column v-center location-list">
              {getLocations()}
            </div>
          </div>
        )}
        {/* 經銷商據點 */}
        {activeList === 2 && (
          <div className="flex h-around img-container">
            <img src={location1} />
            <img src={location2} />
          </div>
        )}
      </div>
      <Footer />
    </div>
  );
}
