import React, { useEffect } from 'react';
import { jwtDecode } from 'jwt-decode';
// Icon
import { FaGoogle } from "react-icons/fa";

export default function SignInGoogle(props) {
    useEffect(() => {
        const script = document.createElement('script');
        script.src = 'https://accounts.google.com/gsi/client';
        script.async = true;
        script.defer = true;
        document.body.appendChild(script);

        script.onload = () => {
            window.google.accounts.id.initialize({
                client_id: process.env.REACT_APP_GOOGLE_CLIENT_ID,
                callback: handleCredentialResponse,
            });
        };

        return () => {
            document.body.removeChild(script);
        };
    }, []);


    const handleCredentialResponse = (response) => {
        const decodedToken = jwtDecode(response.credential);
        props.responseGoogle(decodedToken)
    };


    return (
        <button
            className="flex h-center v-center button2 button-google"
            onClick={() => window.google.accounts.id.prompt()}
        >
            <FaGoogle />
            {props.text}
        </button>
    );
}