import React, { useEffect, useState } from "react";
import { Buffer } from "buffer";
import axios from "axios";
// Pop up
import PopUp from "../../../widgets/PopUps/PopUps";
// Icons
import { CgClose } from "react-icons/cg";
import { AiFillCaretDown, AiFillCaretUp } from "react-icons/ai";
// Css
import "./editOrderDetail.scss";

export default function EditOrderDetail(props) {
    // Order detail
    const [order, setOrder] = useState([]);
    const [editOrder, setEditOrder] = useState(false);
    const [selectedOrderStatus, setSelectedOrderStatus] = useState("");
    const [showOrderStatusDropdown, setShowOrderStatusDropdown] = useState(false);
    const [expectedDeliveryDate, setExpectedDeliveryDate] = useState("");
    const [carrier, setCarrier] = useState("");
    const [carrierTrackingNumber, setCarrierTrackingNumber] = useState("");
    // Shipping & Contact detail
    const [shipping, setShipping] = useState({});
    const [isByCS, setIsByCS] = useState(false);
    // Order cancellation
    const [orderCanceled, setOrderCanceled] = useState(false);
    // Status
    const [statusNum, setSatusNum] = useState(-1);
    // Pop up
    const [showPopUp, setShowPopUp] = useState(false);
    const [popUpType, setPopUpType] = useState(null);
    // Refresh page
    const [shouldRefresh, setShouldRefresh] = useState(false);
    // Event listener
    const [click, setClick] = useState(null);
    // Loading
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        // Call endpoint
        callGetOrderDetailEndpoint();

        // [ Event listener - mouse click & resize ]
        const handleClick = (event) => {
            setClick(event);
        };
        document.addEventListener("click", handleClick);

        return () => {
            document.removeEventListener("click", handleClick);
        };
    }, []);

    useEffect(() => {
        // Click event
        if (click) {
            hideDropdowns(click);
            setClick(null);
        }
        // Refresh page
        else if (shouldRefresh) {
            callGetOrderDetailEndpoint();
            setShouldRefresh(false);
            props.setShouldUpdatePage(true);
        }
    }, [click, shouldRefresh]);

    // Endpoints
    async function callGetOrderDetailEndpoint() {
        const { trackingNumber, email } = props;

        await axios
            .get(
                `${process.env.REACT_APP_API}/orders/orderDetail?trackingNumber=${trackingNumber}&email=${email}`,
                {
                    headers: {
                        "x-access-token": null,
                        "x-api-key": process.env.REACT_APP_API_KEY
                    },
                }
            )
            .then((res) => {
                if (res.status === 200) {
                    // Update shipping & order info
                    const { shippingInfo } = res.data;
                    let expDate = "";

                    setShipping({
                        trackingNumber: trackingNumber,
                        fullName: shippingInfo[0].fullName,
                        gender: shippingInfo[0].gender,
                        phoneNumber: shippingInfo[0].phoneNumber,
                        email: shippingInfo[0].email,
                        address: shippingInfo[0].address,
                        city: shippingInfo[0].city,
                        district: shippingInfo[0].district,
                        zipcode: shippingInfo[0].zipcode,
                        shipping: shippingInfo[0].shipping,
                        originalTotal: shippingInfo[0].originalTotalAmount,
                        total: shippingInfo[0].totalAmount,
                        shippingMethod: shippingInfo[0].shippingMethod,
                        pickUpStoreId: shippingInfo[0].pickUpStoreId,
                        pickUpStoreName: shippingInfo[0].pickUpStoreName,
                        pickUpStoreAddress: shippingInfo[0].pickUpStoreAddress,
                        expectedDeliveryDate: shippingInfo[0].expectedDeliveryDate ? shippingInfo[0].expectedDeliveryDate : "",
                        carrier: shippingInfo[0].carrier,
                        carrierTrackingNumber: shippingInfo[0].carrierTrackingNumber,
                        note: shippingInfo[0].note,
                        status: shippingInfo[0].status,
                        date: shippingInfo[0].date,
                        payment: "信用卡",
                        details: JSON.parse(shippingInfo[0].details),
                    });
                    setOrder(res.data.order);
                    setIsByCS(shippingInfo[0].shippingMethod === "7-ELEVEN");

                    // Format date
                    if (shippingInfo[0].expectedDeliveryDate) {
                        expDate = formatDate(shippingInfo[0].expectedDeliveryDate);
                        expDate = expDate.replace(/\//g, '-');
                    }

                    // Set initial inputs
                    setSelectedOrderStatus(shippingInfo[0].status);
                    setExpectedDeliveryDate(expDate);
                    setCarrier(shippingInfo[0].carrier);
                    setCarrierTrackingNumber(shippingInfo[0].carrierTrackingNumber);

                    // Set order cancellation state
                    setOrderCanceled(shippingInfo[0].isCanceled);

                    // Set status bar
                    handleStatus(shippingInfo[0].status);

                    // Set loading state
                    setLoading(false);
                }
            })
            .catch((err) => {
                if (err.response && err.response.status === 403) {
                    window.alert("出了一些錯誤, 若您看見此消息, 請聯絡網頁工程師, 謝謝!")
                }
            });
    }
    async function callUpdateOrderDetailEndpoint(requestCancel) {
        const { trackingNumber, email } = props;
        const params = { trackingNumber, email };

        if (requestCancel) {
            params.requestCancel = requestCancel;
        } else {
            params.status = selectedOrderStatus;
            params.expectedDeliveryDate = expectedDeliveryDate;
            params.carrier = carrier;
            params.carrierTrackingNumber = carrierTrackingNumber;
        }

        await axios
            .post(
                `${process.env.REACT_APP_API}/orders/update`,
                params,
                {
                    headers: { "x-api-key": process.env.REACT_APP_API_KEY },
                }
            )
            .then((res) => {
                if (res.status === 200) {
                    if (requestCancel) {
                        setShouldRefresh(true);
                        props.setShouldUpdatePage(true);
                        setShowPopUp(false);
                    }
                    else {
                        setShowPopUp(true);
                        setPopUpType(2);
                    }
                }
            })
            .catch((err) => {
                if (err.response && err.response.status !== 200) {
                    window.alert("請重試一次, 若是您重複看到此消息, 請聯絡網路工程師, 謝謝!");
                }
            });
    }

    // Get order list
    const getOrderList = () => {
        const list = [];

        order.forEach((item, index) => {
            const { name, model, colorName, price, sale, size, quantity, image } =
                item;

            list.push(
                <div key={"order" + index}>
                    <div key={"order-row-mobile" + index} className="flex h-between v-center row item">
                        <div key={"order-mobile-content" + index} className="flex v-center item-content">
                            <img
                                key={"order-image" + index}
                                src={
                                    image
                                        ? `data:image/png;base64, ${new Buffer.from(image).toString(
                                            "base64"
                                        )}`
                                        : null
                                }
                            />
                            <div key={"order-mobile-info" + index} className="flex-column h-center info">
                                {/* Product name & model */}
                                <div
                                    key={"order-name-div-desktop" + index}
                                    className="flex v-center desktop name"
                                >
                                    <a key={"order-name" + index}>{`${name}`}</a>
                                    <a key={"order-model" + index}>{`(型號: ${model})`}</a>
                                </div>
                                <div
                                    key={"order-name-div-mobile" + index}
                                    className="mobile name"
                                >
                                    <a key={"order-name" + index}>{`${name}`}</a>
                                    <a key={"order-model" + index}>{`(型號: ${model})`}</a>
                                </div>
                                {/* Color & Size */}
                                <div key={"order-specs-div" + index} className="flex v-center specs-div">
                                    <a key={"order-specs-title" + index}>規格: </a>
                                    <a key={"order-specs-detail" + index}>{colorName} / {size}</a>
                                </div>
                                {/* Quantity */}
                                <div key={"order-quantity-div" + index} className="flex v-center specs-div">
                                    <a key={"order-quantity-title" + index}>數量: </a>
                                    <a key={"order-quantity" + index}>{quantity}</a>
                                </div>
                                {/* Size */}
                                <div key={"order-price-div" + index} className="flex h-between price-div">
                                    <div key={`order-price-cont${index}`} className="flex v-center">
                                        {sale !== price && (
                                            <a key={"order-original-price" + index} className="price">
                                                ${(quantity * price).toLocaleString("en-US")}
                                            </a>
                                        )}
                                        <a key={"order-total" + index} className="sale">
                                            ${(quantity * sale).toLocaleString("en-US")}
                                        </a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            );
        });

        return list;
    };

    // Get order detail list
    const getOrderDetailList = () => {
        const list = [];

        if (shipping.details) {
            Object.keys(shipping.details).forEach((item, index) => {
                list.push(
                    <div
                        key={"order-detail-div" + index}
                        className="flex v-center"
                    >
                        <a key={"order-detail-time" + index}>{formatDateTime(shipping.details[item])}</a>
                        <a key={"order-detail-content" + index}>{item}</a>
                    </div>
                )
            })
        }

        return list;
    }

    // Get order status list
    const getOrderStatusList = () => {
        const options = ["待付款", "付款完成", "訂單處理中", "已發貨", "商品送達"];

        return options.map((item, index) => (
            <a
                key={`order_status_${index}`}
                onClick={() => {
                    setSelectedOrderStatus(item);
                    handleStatus(item);
                }}
            >
                {item}
            </a>
        ));
    }

    // Format date
    const formatDate = (dateStr) => {
        const date = new Date(dateStr);

        const year = date.getFullYear();
        const month = String(date.getMonth() + 1).padStart(2, '0'); // Months are zero-indexed
        const day = String(date.getDate()).padStart(2, '0');

        return `${year}/${month}/${day}`;
    };
    const formatDateTime = (dateStr) => {
        const date = new Date(dateStr);

        const year = date.getFullYear();
        const month = String(date.getMonth() + 1).padStart(2, '0'); // Months are zero-indexed
        const day = String(date.getDate()).padStart(2, '0');
        const hours = String(date.getHours()).padStart(2, '0');
        const minutes = String(date.getMinutes()).padStart(2, '0');

        return `${year}/${month}/${day} ${hours}:${minutes}`;
    };

    // Dropdowns
    const hideDropdowns = (event) => {
        const { id } = event.target;
        const parentId = event.target.parentElement
            ? event.target.parentElement.id
            : null;

        // Order status dropdown
        if (
            showOrderStatusDropdown &&
            id !== "dropdown-status-text" &&
            id !== "dropdown-status-content" &&
            id !== "dropdown-status-icon" &&
            parentId !== "dropdown-status-icon"
        ) {
            setShowOrderStatusDropdown(false);
        }
    };

    // Pop ups
    const popUpContent1 = () => {
        return (
            <div className="flex-column v-center">
                <a>您是否確定要取消這筆訂單?</a>
                <a>(取消後將無法復原)</a>
                <div className="flex selection">
                    <button className="button1" onClick={() => callUpdateOrderDetailEndpoint(true)}>是</button>
                    <button className="button1" onClick={hidePopUp1}>否</button>
                </div>
            </div>
        );
    };
    const popUpContent2 = () => {
        return <a>更新訂單資料成功。</a>;
    };
    const hidePopUp1 = () => {
        setEditOrder(false);
        setShowPopUp(false);
    };
    const hidePopUp2 = () => {
        setEditOrder(false);
        setShouldRefresh(true);
        props.setShouldUpdatePage(true);
        setShowPopUp(false);
    };

    // Handle status
    const handleStatus = (status) => {
        if (status === "待付款") {
            setSatusNum(0);
        } else if (status === "付款完成") {
            setSatusNum(1);
        } else if (status === "訂單處理中") {
            setSatusNum(2);
        } else if (status === "已發貨") {
            setSatusNum(3);
        } else if (status === "商品送達") {
            setSatusNum(4);
        }
    }

    return (
        <div id="edit-order-detail" className="flex h-center v-center modal">
            <div className="flex-column modal-content">
                <CgClose
                    className="icon close"
                    onClick={() => props.setShowEditOrderDetail(false)}
                />
                <h2>訂單資訊</h2>
                {!loading && (
                    <div className="flex h-between content-wrap">
                        <div className="flex-column content-wrap">
                            <div className="flex h-between info-wrap">
                                {/* Tracking info */}
                                <div className="flex-column block tracking">
                                    <div className="flex h-between field-title">
                                        <h3>訂單資訊</h3>
                                        {!orderCanceled && !editOrder && <button className="edit" onClick={() => setEditOrder(true)}>編輯</button>}
                                        {editOrder &&
                                            <button className="confirm" onClick={() => callUpdateOrderDetailEndpoint(false)}>
                                                保存編輯
                                            </button>
                                        }
                                    </div>
                                    <div className="flex-column field">
                                        <div className="flex-column h-center column">
                                            <a className="title">訂單編號:</a>
                                            <a className="content">{shipping.trackingNumber}</a>
                                        </div>
                                        <div className="flex-column h-center column">
                                            <a className="title">下單日期:</a>
                                            <a className="content">{formatDate(shipping.date)}</a>
                                        </div>
                                        <div className="flex-column h-center column">
                                            <a className="title">訂單狀態:</a>
                                            {editOrder && (
                                                <div className="flex-column v-start dropdown">
                                                    <div
                                                        className="flex v-center dropdown-text"
                                                        style={{
                                                            borderRadius: !showOrderStatusDropdown ? "5px" : "5px 5px 0 0",
                                                        }}
                                                        onClick={() => setShowOrderStatusDropdown(!showOrderStatusDropdown)}
                                                    >
                                                        <a
                                                            id="dropdown-status-text"
                                                            onClick={() => setShowOrderStatusDropdown(!showOrderStatusDropdown)}
                                                        >
                                                            {selectedOrderStatus}
                                                        </a>
                                                        {!showOrderStatusDropdown ? <AiFillCaretDown id="dropdown-status-icon" /> : <AiFillCaretUp id="dropdown-status-icon" />}
                                                    </div>
                                                    <div
                                                        id="dropdown-status-content"
                                                        className="dropdown-content"
                                                        style={{ display: showOrderStatusDropdown ? "flex" : "none" }}
                                                    >
                                                        {getOrderStatusList()}
                                                    </div>
                                                </div>
                                            )}
                                            {!editOrder &&
                                                <a className="content">
                                                    {!orderCanceled ?
                                                        <span>{shipping.status}</span> :
                                                        <span className="canceled">訂單已取消</span>
                                                    }
                                                </a>
                                            }
                                        </div>
                                        {!orderCanceled && statusNum < 4 &&
                                            <div className="flex-column h-center column">
                                                <a className="title">預計到貨日:</a>
                                                {editOrder &&
                                                    <input
                                                        type="date"
                                                        value={expectedDeliveryDate}
                                                        onChange={e => setExpectedDeliveryDate(e.target.value)}
                                                    />
                                                }
                                                {!editOrder && (
                                                    <a className="content">
                                                        {shipping.expectedDeliveryDate
                                                            ? formatDate(shipping.expectedDeliveryDate)
                                                            : "(待定)"}
                                                    </a>
                                                )}
                                            </div>
                                        }
                                        {!orderCanceled && !isByCS && (
                                            <div className="flex-column h-center column">
                                                <a className="title">貨運公司:</a>
                                                {editOrder &&
                                                    <input
                                                        value={carrier}
                                                        onChange={e => setCarrier(e.target.value)}
                                                    />}
                                                {!editOrder && (
                                                    <a className="content">{shipping.carrier ? shipping.carrier : "--"}</a>
                                                )}
                                            </div>
                                        )}
                                        {!orderCanceled && !isByCS && (
                                            <div className="flex-column h-center column">
                                                <a className="title">物流單號:</a>
                                                {editOrder &&
                                                    <input
                                                        value={carrierTrackingNumber}
                                                        onChange={e => setCarrierTrackingNumber(e.target.value)}
                                                    />
                                                }
                                                {!editOrder && (
                                                    <a className="content">{shipping.carrierTrackingNumber ? shipping.carrierTrackingNumber : "--"}</a>
                                                )}
                                            </div>
                                        )}
                                    </div>
                                </div>
                                {/* Shipping info */}
                                <div className="flex-column block shipping">
                                    <div className="flex h-between field-title">
                                        <h3>購買資訊</h3>
                                    </div>
                                    <div className="flex-column field">
                                        <div className="flex-column h-center column">
                                            <a className="title">聯絡姓名:</a>
                                            <a className="content">{shipping.fullName} {shipping.gender}</a>
                                        </div>
                                        <div className="flex-column h-center column">
                                            <a className="title">聯絡電話:</a>
                                            <a className="content">(+886) {shipping.phoneNumber}</a>
                                        </div>
                                        <div className="flex-column h-center column">
                                            <a className="title">Email:</a>
                                            <a className="content">{shipping.email}</a>
                                        </div>
                                        <div className="flex-column h-center column">
                                            <a className="title">付款方式:</a>
                                            <a className="content">{shipping.payment}</a>
                                        </div>
                                        <div className="flex-column h-center column">
                                            <a className="title">送貨方式:</a>
                                            <a className="content">{isByCS ? shipping.shippingMethod + " 取貨" : shipping.shippingMethod}</a>
                                        </div>
                                        {isByCS ?
                                            <div className="flex-column h-center column">
                                                <a className="title">取貨門市:</a>
                                                <div className="flex-column h-center column store-content">
                                                    <a className="content"><b>超商店號:</b> {shipping.pickUpStoreId}</a>
                                                    <a className="content"><b>超商店名:</b> {shipping.pickUpStoreName}</a>
                                                    <a className="content"><b>超商地址:</b> {shipping.pickUpStoreAddress}</a>
                                                </div>
                                            </div> :
                                            <div className="flex-column h-center column">
                                                <a className="title">送貨地址:</a>
                                                <a className="content">{shipping.zipcode} {shipping.city} {shipping.district} {shipping.address}</a>
                                            </div>
                                        }
                                    </div>
                                </div>
                            </div>
                            {/* 訂單流程 */}
                            <div className="flex-column block order-detail-list">
                                <div className="field-title">
                                    <h3>訂單流程</h3>
                                </div>
                                <div className="flex-column field order-detail-list-wrap">
                                    {getOrderDetailList()}
                                </div>
                            </div>
                            {/* Note */}
                            <div className="flex-column block note">
                                <div className="field-title">
                                    <h3>備註</h3>
                                </div>
                                <div className="flex v-baseline field">
                                    <a>{shipping.note ? shipping.note : "(無)"}</a>
                                </div>
                            </div>
                            {/* Order details */}
                            <div className="flex-column block order-detail">
                                <div className="field-title">
                                    <h3>商品資訊</h3>
                                </div>
                                <div className="flex-column field">
                                    {getOrderList()}
                                </div>
                            </div>
                            <div className="flex-column content-wrap summary-wrap">
                                {/* Calculation */}
                                <div className="flex-column calculation">
                                    <div className="flex h-between">
                                        <a>小計:</a>
                                        <a>NT$ {shipping.originalTotal.toLocaleString("en-US")}</a>
                                    </div>
                                    <div className="flex h-between discount">
                                        <a>折扣:</a>
                                        <a>
                                            - NT${" "}
                                            {(shipping.originalTotal - shipping.total).toLocaleString(
                                                "en-US"
                                            )}
                                        </a>
                                    </div>
                                    <div className="flex h-between">
                                        <a>運費:</a>
                                        <a>NT$ {shipping.shipping.toLocaleString("en-US")}</a>
                                    </div>
                                </div>
                                {/* Summary */}
                                <div className="flex h-between summary">
                                    <a>合計:</a>
                                    <a>
                                        NT${" "}
                                        {(shipping.total + shipping.shipping).toLocaleString(
                                            "en-US"
                                        )}
                                    </a>
                                </div>
                            </div>
                            {!orderCanceled &&
                                <button
                                    className="button1 cancel"
                                    onClick={() => {
                                        setShowPopUp(true);
                                        setPopUpType(1);
                                    }}
                                >
                                    取消訂單
                                </button>
                            }
                        </div>
                    </div>
                )}
            </div>
            {showPopUp &&
                <PopUp
                    parentPopUpName={popUpType === 1 ? " update" : ""}
                    popUpContent={popUpType === 1 ? popUpContent1 : popUpContent2}
                    hidePopUp={popUpType === 1 ? hidePopUp1 : hidePopUp2}
                />
            }
        </div >
    )
}
