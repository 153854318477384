// [ category ]
const category = [
  {
    name: "專業安全鞋",
    type: [
      "短筒安全鞋",
      "中、長筒安全鞋",
      "運動防護鞋",
      "無塵靜電防護鞋",
      "職場工作鞋",
      "警消保全鞋",
      "餐飲廚師鞋",
      "長照護士鞋",
    ],
  },
  {
    name: "舒適生活鞋",
    type: [
      "重機專用鞋",
      "登山健走鞋",
      "戰鬥靴",
      "幫浦氣墊鞋",
      "經典工作靴",
      "真牛皮帆船鞋",
      "運動活力鞋",
      "帆布鞋",
    ],
  },
  { name: "其他產品", type: ["器材與用品"] },
];

// [ Locations ]
const locationList = [
  {
    info: "台北營業所暨門市部",
    address: "台北市大同區重慶北路三段223巷24號",
    src: "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d244.76764870233066!2d121.5143858679834!3d25.069128710788636!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3442a94805280327%3A0xddaeecffb7f7d81f!2sNo.%2022-24%2C%20Lane%20223%2C%20Section%203%2C%20Chongqing%20N%20Rd%2C%20Datong%20District%2C%20Taipei%20City%2C%20Taiwan%20103!5e0!3m2!1sen!2sus!4v1679124035527!5m2!1sen!2sus",
    phone: "(02) 2502-6570",
    fax: "(02) 2502-2055",
  },
  {
    info: "新竹營業所暨門市部",
    address: "30046新竹市四維路76號",
    src: "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d636.5310879380438!2d120.96160667512983!3d24.79953587853773!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x34683594840cd0e9%3A0xb23c9ac96c4804a5!2z5b6X6KOV55ub5qWt6IKh5Lu95pyJ6ZmQ5YWs5Y-4!5e0!3m2!1szh-TW!2sus!4v1668578470810!5m2!1szh-TW!2sus",
    phone: "(03) 522-1616",
    fax: "(03) 522-9150",
  },
  {
    info: "新竹總公司暨工廠",
    address: "30352新竹縣湖口鄉新竹工業區大同路11號",
    src: "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3619.8286629898407!2d120.99647471603795!3d24.869700784049527!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x346831501a116c27%3A0x8fa4c27e909be4cd!2z5b6X6KOV55ub5qWt6IKh5Lu95pyJ6ZmQ5YWs5Y-4!5e0!3m2!1szh-TW!2sus!4v1668578567141!5m2!1szh-TW!2sus",
    phone: "(03) 597-6266",
    fax: "(03) 597-6366",
  },
  {
    info: "台中營業所暨門市部",
    address: "40245台中市南區復興路一段446號",
    src: "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3641.5680173220735!2d120.65539131744384!3d24.116682400000006!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x34693c50f7aaf275%3A0xef0f3c058f85e0cc!2zNDAy5Y-w54Gj5Y-w5Lit5biC5Y2X5Y2A5b6p6IiI6Lev5LiA5q61NDQ26Jmf!5e0!3m2!1szh-TW!2sus!4v1668578738839!5m2!1szh-TW!2sus",
    phone: "(04) 2265-3235",
    fax: "(04) 2265-3236",
  },
  {
    info: "高雄營業所暨門市部",
    address: "83087高雄市鳳山區南安路22號",
    src: "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3683.769878046539!2d120.32282081744385!3d22.587708600000003!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x346e033f3cfc153b%3A0x291e765a15aebf91!2zODMw5Y-w54Gj6auY6ZuE5biC6bOz5bGx5Y2A5Y2X5a6J6LevMjLomZ8!5e0!3m2!1szh-TW!2sus!4v1668578806260!5m2!1szh-TW!2sus",
    phone: "(07) 841-4161",
    fax: "(07) 841-4162",
  },
];

// [ Q&A ]
const qaList = [
  {
    question: "為何要穿安全鞋？",
    answer:
      "為避免工作現場可能發生腳部受傷的情況下，例如：製造業、環保業、化工業、造船業、航空業、礦業、營造業、電力檢修業、石化業、交通運輸業、搬運業、食品業、飲食業…等工作場所皆需穿著安全鞋。",
    table: [],
    list: [],
  },
  {
    question: "安全鞋只是比一般鞋子多加鋼頭而已嗎？",
    answer: "為應付各種不同的環境，安全鞋可具備以下功能:",
    table: [
      {
        title: "一般型",
        content: [
          {
            text: "耐衝擊，耐壓迫，耐油性，耐老化，耐剝離。修車場，鑄造，鍛造，汽車裝配，礦場，林業，輸電檢修，貨物搬運，鋼鐵加工，玻璃加工，道路養護等其他可能發生腳部傷害之場合。",
            isList: false,
          },
        ],
      },
      {
        title: "電工用",
        content: [
          {
            text: "適用於使用者在工作現場須操作電氣工具，如電焊、車鑽床等，為了保護使用者的安全必須有適當的電氣隔絕性能，因此耐電壓及絕緣是必須的要求。",
            isList: false,
          },
          {
            text: "絕緣。",
            isList: true,
          },
          {
            text: "耐電壓3,300V，1分鐘。",
            isList: true,
          },
          {
            text: "電焊， 電氣作業防止人體感電。",
            isList: true,
          },
          {
            text: "隨時注意本品之清潔度以保持性能。",
            isList: true,
          },
          {
            text: "本公司出品之安全鞋一般均具有絕緣，耐電壓3,300V，一分鐘以上之性能。",
            isList: true,
          },
          {
            text: "電氣類工作若有電焊火星者請避免使用繫帶式安全鞋，或加電焊操作時加護腳套。",
            isList: true,
          },
        ],
      },
      {
        title: "抗靜電",
        content: [
          {
            text: "適用於操作可燃性物質(氣體、蒸氣、液體、粉體等)，薄膜、各種噴漆裝置、電子零件工作場所。對人體帶有靜電而有可能發生爆炸、火災、觸電等事故、災害，或者可能引起生產障礙時為防止上述情況而使用之安全鞋。",
            isList: false,
          },
          {
            text: "導電係數１.０Ｘ１０5Ω＜Ｒ＜１.０Ｘ１０8Ω",
            isList: true,
          },
          {
            text: "石化工業，製藥業，無塵室防止塵爆或產品障礙。",
            isList: true,
          },
          {
            text: "靜電安全鞋絕不可穿著於電氣作業以免有感電之危險。",
            isList: true,
          },
          {
            text: "隨時注意本品之清潔度以保持性能。",
            isList: true,
          },
        ],
      },
      {
        title: "導電",
        content: [
          {
            text: "國防、爆竹工業等易爆易燃場所，須將人體所產生的靜電經由接地排除至體外者。",
            isList: false,
          },
          {
            text: "導電係數 Ｒ＜４.５Ｘ１０ Ω。(BSEN 規定為10萬歐姆以下)",
            isList: true,
          },
          {
            text: "爆竹，炸藥工廠等易爆燃燒場所防止爆炸保障公共安全。",
            isList: true,
          },
          {
            text: "隨時注意本品之清潔度以保持性能。",
            isList: true,
          },
          {
            text: "由於低電阻的關係須注意與電接觸時有感電的危險。",
            isList: true,
          },
        ],
      },
      {
        title: "防水",
        content: [
          {
            text: "全部防水橡膠或塑膠製造。",
            isList: true,
          },
          {
            text: "隧道作業，水場，建築工地，大地工程，污廢水處理。",
            isList: true,
          },
        ],
      },
      {
        title: "防穿刺",
        content: [
          {
            text: "使用者的工作場所有遭遇尖銳物品刺穿鞋底之危險者，可以在鞋底部加裝防穿刺鋼板。",
            isList: false,
          },
          {
            text: "耐踩彈簧鋼片中敷。",
            isList: true,
          },
          {
            text: "建築業，土木業等有受穿刺危險之場所。",
            isList: true,
          },
          {
            text: "該設計可以使得單腳承受112.2kg(1100N)重的施力而不會讓鐵釘刺穿。",
            isList: true,
          },
        ],
      },
      {
        title: "無塵鞋",
        content: [
          {
            text: "抗靜電係數１.０Ｘ１０5Ω＜Ｒ＜１.０Ｘ１０8Ω。",
            isList: true,
          },
          {
            text: "半導體，軟片．製藥，實驗室，食品加工，電腦機房，印刷作業等須防霉、抗菌、防塵、防靜電之場所。",
            isList: true,
          },
          {
            text: "若有需要可加裝安全鋼頭。",
            isList: true,
          },
        ],
      },
      {
        title: "耐熱",
        content: [
          {
            text: "斷熱鞋中敷可避免腳部受到熱的侵害。",
            isList: true,
          },
          {
            text: "鍋爐，高爐煉熔等非直接與熱源接觸之場所。",
            isList: true,
          },
          {
            text: "直接加硫製法。",
            isList: true,
          },
        ],
      },
      {
        title: "止滑",
        content: [
          {
            text: "鞋子會打滑的原因主要是鞋底與地面的磨擦力不足。應該考量：",
            isList: false,
          },
          {
            text: "工作場地是否油膩，是的話應該採用耐油鞋底的安全鞋。",
            isList: true,
          },
          {
            text: "鞋底紋路設計與接觸地面積是否恰當。",
            isList: true,
          },
          {
            text: "若有清潔劑或其他水液時是否有足夠的止滑效果。",
            isList: true,
          },
        ],
      },
    ],
    list: [],
  },
  {
    question: "安全鞋尺寸跟運動鞋尺寸一樣嗎？",
    answer:
      "因安全鞋與運動鞋為不同性質之鞋類，二種尺寸有稍微不一樣，請參考每項商品頁面中的尺寸對照表。",
    table: [],
    list: [],
  },
  {
    question: "尺寸不合可以退（換）貨嗎？",
    answer:
      "當然可以，只要沒有穿過且於7日內，均可帶統一發票至本公司營業所，我們會提供您專業親切的服務。",
    table: [],
    list: [],
  },
  {
    question: "安全鞋款式沒變化？",
    answer:
      "本公司除了基本款外，安全鞋方面還有登山、休閒、運動、紳士、涼鞋…等，如需無鋼頭鞋子，另備有登山鞋、護士鞋、帆船鞋、帆布鞋，不只在工作場所外，其餘地方皆適穿。",
    table: [],
    list: [],
  },
  {
    question: "安全鞋隨處買，真的安全嗎？",
    answer:
      "安全鞋的生產過程中，每一個環節都是非常的重要，為確保設計的品質可以落實，我們對生產流程進行嚴格的監督與品管，並且獲得經濟部標準檢驗局國際標準品質保證制度ISO9001認可登錄證明及產品驗證登錄。",
    table: [],
    list: [],
  },
  {
    question: "安全鞋的壽命可以保證有多長？",
    answer:
      "本公司的產品皆經過嚴密設計，除採用較高標準的原材料外，還注重每一個生產環節對於產品性能的影響。 因此對於較惡劣的工作環境亦可以有較大的承受能力，也較一般鞋款較為耐久。",
    table: [],
    list: [],
  },
  {
    question: "鞋頭前面太窄，會買不到適合的鞋款嗎？",
    answer: "本公司所開發超超寬楦頭，可避免腳趾摩擦鋼頭，讓腳趾不再委屈。",
    table: [],
    list: [],
  },
  {
    question: "安全鞋好重？",
    answer:
      "安全鞋因前襯須依CNS國家標準放置鋼頭，故比一般鞋子為重，本公司有研發玻璃纖維鋼頭，相對的就”輕”多了。",
    table: [],
    list: [],
  },
  {
    question: "鋼頭耐壓200焦耳是如何計算的？",
    answer: "依照CNS20345標準，鋼頭耐壓應達200 J (焦耳)，換算為2,740公斤。",
    table: [],
    list: [],
  },
  {
    question: "安全鞋防水性高嗎？",
    answer: "",
    table: [],
    list: [
      {
        content:
          "不管是橡膠或PU都有其防水性，倘因磨擦而破洞，導致雨水有機會藉滲透中底而進入鞋內時，則無法確保其防水性能。",
        emphasize: 0,
        isList: true,
      },
      {
        content:
          "目前廣泛使用牛面皮作為鞋面材料，主要是利用其透氣、服貼、耐磨擦等性能。就防水性能而言牛面皮由於纖維組織綿密、並含有較多的脂肪與角質層，與二榔皮相比可以有較高的防水性。",
        emphasize: 0,
        isList: true,
      },
      {
        content:
          "市面上許多廉價安全鞋，均使用經過表面塗飾的二榔皮或PU COATING(PU榔皮)。二瑯皮在穿著初期其表現略似於PU榔皮，其塗飾之堅牢度在經過使用後極易鬆脫，而突露出疏鬆的組織易吸收水份(或油漬)。而PU榔皮則在二榔皮表面上貼合一層PU皮，而得到較佳的外觀與較好的防水性，但相對的失去了透氣性，且當該層薄膜破損時，其防水性能便無異於表面塗飾的二榔皮。",
        emphasize: 0,
        isList: true,
      },
      {
        content: "※ 本公司堅持使用優質牛皮，不使用二榔皮或PU COATING(PU榔皮)。",
        emphasize: 1,
        isList: false,
      },
      {
        content:
          "針對要求特殊防水鞋，需於鞋內特製襪套式內裡，並使用防水膜合貼防水特多龍，且車縫處需使用特殊機台加壓貼合條",
        emphasize: 0,
        isList: true,
      },
      {
        content:
          "需長時間接觸水或其他滲透性液體的場所，應優先採用橡膠或塑膠安全鞋，以達到較高防水性能。",
        emphasize: 0,
        isList: true,
      },
    ],
  },
  {
    question: "為何鞋子多半從面部先壞？",
    answer:
      "由於鞋子在設計考量時，鞋底所接觸的環境與鞋面的相比潮濕惡劣許多。因此，以舒適、透氣、服貼的天然皮革來製作鞋面，較耐用不透氣的人造材料來製作鞋底。因此，除非在非常特殊的狀況下，鞋子的損壞多半從鞋面開始。當然，比較好的使用方式可以延長皮革的壽命，譬如說時常擦拭鞋油、兩雙以上的鞋子交替使用、不要用火烤溼鞋子、減少用鞋面去踢堅銳或剛硬的物體…等等。",
    table: [],
    list: [
      {
        content:
          "由於強酸鹼等對於皮革有嚴重的破壞性，應避免使鞋子與其直接接觸。",
        emphasize: 0,
        isList: true,
      },
      {
        content:
          "皮革是天然的產品，潮濕的工作場所容易使細菌茲生而腐壞，應儘量適度保持鞋子整體乾燥。",
        emphasize: 0,
        isList: true,
      },
      {
        content: "太高的溫度會加速皮革老化、組織改變，應該儘量避免接近熱源。",
        emphasize: 0,
        isList: true,
      },
      {
        content:
          "在切削、電焊及鞋頭部位經常磨損的工作場所宜使用無接縫型，或在鞋面車縫處加以保護來防止解體開口之危險。",
        emphasize: 0,
        isList: true,
      },
    ],
  },
  {
    question: "一般鞋底損壞問題 － 外在因素：",
    answer: "",
    table: [],
    list: [
      {
        content:
          "剎車油、切削油….對於橡膠有嚴重的破壞性，請避免使鞋子長時間接觸。",
        emphasize: 0,
        isList: true,
      },
      {
        content:
          "較高溫度會加速橡膠老化及鞋底剝離，須避免接近熱源，並採用直接加 硫式產品。",
        emphasize: 0,
        isList: true,
      },
      {
        content: "車床後的鐵屑或現場尖銳物(如碎玻璃)會破壞鞋底組織。",
        emphasize: 0,
        isList: true,
      },
      {
        content: "高溫及強酸鹼較易引起鞋底組織解體 (PU鞋底尤之)。",
        emphasize: 0,
        isList: true,
      },
      {
        content: "本公司針對以上問題，均有其因應的產品，請諮詢本公司專業人員。",
        emphasize: 2,
        isList: false,
      },
    ],
  },
  {
    question: "鞋底損壞問題 － 產品本身因素：",
    answer: "",
    table: [],
    list: [
      {
        content: "加工流程不當: 成型溫度不當、時間錯誤、藥品配方錯誤等。",
        emphasize: 0,
        isList: true,
      },
      {
        content: "鞋底外觀不良: 底屑處理不佳、材料不足、模具壞損所致。",
        emphasize: 0,
        isList: true,
      },
      {
        content: "鞋底斷底: 使用回收橡膠再製。",
        emphasize: 0,
        isList: true,
      },
      {
        content:
          "採用不適當的產品: 例如高溫、強酸鹼的工作場不可使用PU鞋底；油漬場未使用耐油鞋底等。",
        emphasize: 0,
        isList: true,
      },
      {
        content: "儲放時間過久，導致鞋底老化、水解、或硬化。",
        emphasize: 0,
        isList: true,
      },
    ],
  },
  {
    question: "長時間站立腳掌容易酸痛？",
    answer:
      "由於站立時所產生的軀體重量全部集中在腳掌及腳跟部位，因此長時間站立工作者最容易發生。但如果穿著時將鞋帶繫緊便可以減少走動時腳部拖拉鞋子的額外負擔。另外，行走範圍較大者本公司採用高彈力PU氣墊鞋墊來減輕衝擊疏壓，且本公司高彈力ＰＵ氣墊鞋墊，有防霉抗菌、舒適透氣、能達到氣動避震及壓力釋放的效果。另建議直立時腳步寬度與肩膀應同寬。",
    table: [],
    list: [],
  },
  {
    question: "我們的品質有何優異之處？",
    answer: "",
    table: [],
    list: [
      {
        content:
          "以材料來說明，我們不論是皮革、橡膠或者是其他附屬配件，都是設計了優於國家標準的。比方說，鞋底為耐油又耐磨橡膠、較高密度的PU發泡底….。",
        emphasize: 0,
        isList: true,
      },
      {
        content:
          "為求品質穩定，本公司採一條龍方式，全於廠內量產及監控，以便掌握品管。",
        emphasize: 0,
        isList: true,
      },
    ],
  },
];

// [ localizedProductTypes ]
const localizedProductTypes = {
  "name": "商品名稱",
  "model": "型號", 
  "type": "產品分類",
  "color": "顏色",
  "hex": "色碼",
  "originalPrice": "原價",
  "salePrice": "特價",
  "rutenLink": "露天拍賣連結",
  "shopeeLink": "蝦皮拍賣連結",
  "pcstoreLink": "PChome商店街連結",
  "momoLink": "momo購物網連結",
  "rakutenLink": "樂天連結",
  "shorts": "商品特點",
  "specsPart": "商品規格 - 部位",
  "specsMaterial": "商品規格 - 材質說明",
  "specsPros": "商品規格 - 優點",
}

export { category, locationList, qaList, localizedProductTypes };
