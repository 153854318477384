import React, { useEffect } from 'react';
// Icon
import { FaFacebook } from "react-icons/fa";

export default function SignInFB(props) {
  useEffect(() => {
    (function (d, s, id) {
      var js, fjs = d.getElementsByTagName(s)[0];
      if (d.getElementById(id)) { return; }
      js = d.createElement(s); js.id = id;
      js.src = "https://connect.facebook.net/zh_TW/sdk.js";
      fjs.parentNode.insertBefore(js, fjs);
    }(document, 'script', 'facebook-jssdk'));

    window.fbAsyncInit = function () {
      FB.init({
        appId: process.env.REACT_APP_FACEBOOK_APP_IP,
        cookie: true,
        xfbml: true,
        version: 'v15.0'
      });
    };
  }, []);

  const handleFacebookLogin = () => {
    FB.login(function (response) {
      if (response.authResponse) {
        FB.api('/me', { fields: 'name, email' }, function (response) {
          props.responseFacebook(response);
        });
      } else {
        console.log('User canceled login or did not fully authorize.');
      }
    });
  };


  return (
    <button
      className="flex h-center v-center button2 button-fb"
      onClick={handleFacebookLogin}
    >
      <FaFacebook />
      {props.text}
    </button>
  );
}