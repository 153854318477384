import React, { useEffect, useState } from "react";
// Css
import "./cookieUsage.scss";

export default function CookieUsage() {
    const [cookieConsent, setCookieConsent] = useState("");

    useEffect(() => {
        // Check if user's cookie consent preference is stored
        const consent = localStorage.getItem("cookieConsent");

        if (consent) {
            setCookieConsent(consent);
        }
    }, []);

    const handleConsent = (type) => {
        setCookieConsent(type);
        localStorage.setItem("cookieConsent", type);
    };

    if (cookieConsent) {
        return null; // Hide the component if consent has been given
    }

    return (
        <div id="cookie-usage" className="flex h-center v-center">
            <p>本網站使用了一些必要的 cookies 來改善您的瀏覽體驗。</p>
            <button className="button1" onClick={() => handleConsent("all")}>我知道了</button>
            <p>
                <a href="/privacy">了解更多</a>
            </p>
        </div>
    );
}
