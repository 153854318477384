import React, { useState } from "react";
// Footer
import Footer from "../../widgets/Footer/Footer";
// Image
import image from "../../files/images/about-us.png";
import logo1 from "../../files/images/logo-tonya.png";
import logo2 from "../../files/images/logo-toping.png";
import logo3 from "../../files/images/logo-amaa.png";
import logo4 from "../../files/images/logo-toptex.png";
import certificate1 from "../../files/images/certificate/certificate1.jpeg";
import certificate2 from "../../files/images/certificate/certificate2.jpeg";
import certificate3 from "../../files/images/certificate/certificate3.jpeg";
import certificate4_1 from "../../files/images/certificate/certificate4-1.jpeg";
import certificate4_2 from "../../files/images/certificate/certificate4-2.jpeg";
import certificate4_3 from "../../files/images/certificate/certificate4-3.jpeg";
import certificate5 from "../../files/images/certificate/certificate5.jpeg";
import certificate6 from "../../files/images/certificate/certificate6.jpeg";
// Icons
import { CgClose } from "react-icons/cg";
// Css
import "./aboutUs.scss";

export default function BrandStory() {
  // Catalog menu - 關於我們/Toping安全鞋認證
  const [activeList, setActiveList] = useState(1);
  // Modal
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [modalImage, setModalImage] = useState(null);
  const [isCertificate6, setIsCertificate6] = useState(false);

  // Toggle modal state
  const openModal = () => {
    setModalIsOpen(true);
  };

  const closeModal = () => {
    setModalIsOpen(false);
    setModalImage(null);
  };

  const handleClickOnImage = (img) => {
    setModalImage(img);
    setIsCertificate6(img === certificate6);
    openModal();
  };

  // Update active menu list
  const updateActiveList = (index) => {
    setActiveList(index);
  };

  // Determine list shown event
  const getActiveList = (current) => {
    if (activeList === current) {
      return "menu-active";
    } else {
      return "";
    }
  };

  return (
    <div id="page-about" className="flex-column v-center page">
      <div className="flex-column v-center page-content">
        <div className="flex-column v-center all-content">
          <h2>關於我們</h2>
          {/* List menu */}
          <div className="list-menu">
            <div className="flex v-center menu-content">
              <a className={getActiveList(1)} onClick={() => updateActiveList(1)}>
                品牌故事
              </a>
              <div className="divider"></div>
              <a className={getActiveList(2)} onClick={() => updateActiveList(2)}>
                Toping安全鞋認證
              </a>
              <div className="divider"></div>
              <a className={getActiveList(3)} onClick={() => updateActiveList(3)}>
                榮譽證書
              </a>
            </div>
          </div>
          {/* 品牌故事 */}
          {activeList === 1 && (
            <div id="content-story" className="flex-column content">
              {/* 台灣手工安全鞋第一首選 */}
              <h3>台灣手工安全鞋第一首選，最在乎你是否穿得安全又舒適的鞋業</h3>
              <a>
                得裕盛業股份有限公司成立於1957年(民國46年)，由董事長莊金龍先生一手創起，目前已經是經歷一甲子的企業。
                從得裕成立起的那天起，一路秉持著「讓每個人能夠穿好鞋」的精神，從10人學徒制創立起的工作室開始，在短短20年間擴大為新竹當地鞋廠。其中除了積極地與日商青木產業株式會社切磋製鞋工藝外，更二度增資擴廠成有規模的鞋廠，以確保得裕的產能與品質能夠配合經濟起飛的年代。
                在增資擴廠的過程中，得裕為了提供更優質的工作鞋給辛苦的工作人，並多次的與工研院共同研究PU鞋底與橡膠材料的開發。為了減輕穿鞋人的負擔，得裕更積極與日商合作以獲得環保鞋底的技術。最終更引進歐聯法商ESJOT
                GOLDENBERG 的特殊鋼頭，以提供人們在鋼頭上的選擇性。
                得裕在1999年後針對市場需求，將企業方向投入產品多樣化，除了有鋼頭的安全鞋外，更販售防靜電無塵鞋、護士鞋、馬丁鞋等多元鞋款。最終成為大家認識的「得裕盛業股份有限公司」，希望所有在生活上努力打拼的人們，都能穿上雙安全又舒適的好鞋。
              </a>
              {/* Toping大事件紀錄 */}
              <h3>得裕Toping大事件紀錄</h3>
              <div className="flex-column table">
                <div className="flex">
                  <a>1980年</a>
                  <a>
                    受中央標準局邀請共同制定皮革安全鞋Z2029與CNS.6863國家標準首版
                  </a>
                </div>
                <div className="flex">
                  <a>1989年</a>
                  <a>
                    與工研院合作研發PU、SYSTEM配方研究，與耐磨與耐油橡膠鞋底材的開發
                  </a>
                </div>
                <div className="flex">
                  <a>1991年</a>
                  <a>
                    經濟部認定得裕為「傳統性工業技術升級計畫」輔導廠商。
                    積極國際合作引進日本POLYURETHAN鞋底製程，並改造研發出重量輕、彈性佳、耐油且耐磨的優良物性環保鞋底
                  </a>
                </div>
                <div className="flex">
                  <a>1992年</a>
                  <a>
                    與工研院再度合作，對反應性配料及加工應用進行研究，以優化工業用鞋
                  </a>
                </div>
                <div className="flex">
                  <a>1994年</a>
                  <a>
                    引進法商ESJOT GOLDENBERG的特殊鋼頭，提供更多選項給消費者
                  </a>
                </div>
                <div className="flex">
                  <a>1997年</a>
                  <a>
                    委託優生顧問公司輔導，推動整廠ISO 9002品質標準計畫，提升品質
                  </a>
                </div>
                <div className="flex">
                  <a>1998年</a>
                  <a>
                    9月通過ISO
                    9002國際品質保障認證－代表本公司出產的產品皆通過國際標準
                  </a>
                </div>
                <div className="flex">
                  <a>1999年</a>
                  <a>
                    {" "}
                    針對市場需求增設工業安全衛生、消防，並普設門市銷售點，提高與客戶接觸的機會
                  </a>
                </div>
                <div className="flex">
                  <a>2000年</a>
                  <a>增加產品線－加入防靜電鞋、無塵鞋</a>
                </div>
                <div className="flex">
                  <a>2002年</a>
                  <a>10月通過ISO 9001的2000年版國際品質保障認證</a>
                </div>
                <div className="flex">
                  <a>2014年</a>
                  <a>
                    與鞋技中心合作通過中衛中心輔導案─高效能雙向式透氣舒壓工作鞋底設計開發案
                  </a>
                </div>
                <div className="flex">
                  <a>2015年</a>
                  <a>與鞋技中心合作研發專案─導入高值友善生產場域輔導計畫</a>
                </div>
                <div className="flex">
                  <a>2016年</a>
                  <a>與鞋技中心合作研發專案─休閒安全鞋品開發輔導計畫</a>
                </div>
              </div>
              {/* 創業故事 */}
              <h3>創業故事</h3>
              <a>
                得裕公司創始於民國46年，歷經台灣經濟蓬勃發展年代。由於輕、重工業對安全鞋需求殷切，產能極須擴充，於民國79年於新竹工業區擴建新廠房，現有廠房1500坪，員工約70人，年產量超過20萬雙。茲今經營以東亞、特品等自有品牌，做全方位專業工作鞋與舒適生活鞋款之研發生產，為國內少有具特色「一條龍」完整製程與銷售之專業製造廠商。
              </a>
              {/* 創新故事 */}
              <h3>創新故事</h3>
              <div className="flex-column sub-content">
                <a>– 新竹市德高街老師父手工鞋的故事</a>
                <a>
                  得裕公司創辦人莊金龍創業始於民國46年，台灣光復的年代，於新竹市德高街一帶製鞋巷弄，一針一線，手足胼織，開創手工鞋專業製造技術及傳承師徒，並進而拓展創設為中小型企業工廠製造，行銷台灣全省公民營機關、大賣場與消費者。
                </a>
              </div>
              <div className="flex-column sub-content">
                <a>– 台灣輕工業 期與美商OSCAR一段外銷合作</a>
                <a>
                  台灣經濟走過67年外銷擴張年代，得裕公司亦積極拓展外銷，得助於美商Jimlar.BBC.OTC….之協助，順利打入歐美市場，產品行銷於當時之KMart
                  、walmart、Target….等大賣場， 貢獻國家外匯收入。
                </a>
              </div>
              <div className="flex-column sub-content">
                <a>– 台灣經濟轉型與日本大廠AOKI技轉升級與合作</a>
                <a>
                  民國67年得裕公司莊金龍董事長與日本安全靴始祖「青木產業株式會社,
                  AOKI」青木社長簽署技轉合作，派員赴日訓練，技轉日本直接加硫式安全製鞋技術，
                  並合作研發相關製鞋技術。迄今近40年，雙方合作密切，營造雙贏。
                </a>
              </div>
              <div className="flex-column v-center image">
                <img src={image} />
                <a>
                  ▲ 莊金龍董事長(右四)夫人(右四)與日本青木社長夫婦(右 三、右二)
                  ，於新竹市林森路創始工廠合影
                </a>
              </div>
            </div>
          )}
          {/* Toping安全鞋認證 */}
          {activeList === 2 && (
            <div id="content-certificate" className="flex-column content">
              {/* 商譽宣示 */}
              <h3>商譽宣示</h3>
              <a className="text-emp">誠信之美 The Beauty of Integrity</a>
              <a>
                我們忠實堅守「企業誠信」的文化與經營，打造精緻「工藝之美」的產品來服務消費者，善盡企業社會責任。
              </a>
              {/* 品牌宣言 */}
              <h3>品牌宣言</h3>
              <div className="flex-column table brands">
                <div className="flex h-between v-center brand-type">
                  <div className="flex-column">
                    <a>Tonya 東亞</a>
                    <a>
                      <span>特色:</span>安全
                    </a>
                    <a>
                      <span>商標註冊碼:</span>
                      【64247】,【01405060】,【01394492】,【01405060】
                    </a>
                    <a>
                      <span>產品系列:</span>
                      安全鞋/工作鞋等工作安全用鞋靴、衣服等產品及防護用具。
                    </a>
                  </div>
                  <img src={logo1} />
                </div>
                <div className="flex h-between v-center brand-type">
                  <div className="flex-column">
                    <a>Toping 特品</a>
                    <a>
                      <span>特色:</span>生活
                    </a>
                    <a>
                      <span>商標註冊碼:</span>
                      【00468162】,【01410135】,【00981930】,【01249121】
                    </a>
                    <a>
                      <span>產品系列:</span>
                      舒適生活鞋/服務鞋等生活舒適步調及服務業之鞋靴、衣服等產品及用具。
                    </a>
                  </div>
                  <img src={logo2} />
                </div>
                <div className="flex h-between v-center brand-type">
                  <div className="flex-column">
                    <a>A-Maa 阿瑪</a>
                    <a>
                      <span>特色:</span>品味
                    </a>
                    <a>
                      <span>商標註冊碼:</span>【01530926】,【01410552】
                    </a>
                    <a>
                      <span>產品系列:</span>
                      生活品味鞋/照護鞋等，具備生活特色、個人體驗之鞋靴、衣服等產品及救生裝置。
                    </a>
                  </div>
                  <img src={logo3} />
                </div>
                <div className="flex h-between v-center brand-type">
                  <div className="flex-column">
                    <a>Toptex</a>
                    <a>
                      <span>特色:</span>時尚
                    </a>
                    <a>
                      <span>商標註冊碼:</span>【01384948】,【01414599】
                    </a>
                    <a>
                      <span>產品系列:</span>
                      活潑時尚鞋/具活力、個性屬性之鞋靴、衣服等產品，如馬丁、帆船、帆布等時尚鞋及登山鞋、重機鞋等。
                    </a>
                  </div>
                  <img src={logo4} />
                </div>
              </div>
            </div>
          )}
          {/* 榮譽證書 */}
          {activeList === 3 && (
            <div id="content-certification" className="flex-column content">
              <div className="flex h-between certificates">
                <div className="flex-column v-center">
                  <img
                    src={certificate1}
                    onClick={() => handleClickOnImage(certificate1)}
                  />
                  <a>ISO 9001品質管理認證</a>
                </div>
                <div className="flex-column v-center">
                  <img
                    src={certificate2}
                    onClick={() => handleClickOnImage(certificate2)}
                  />
                  <a>產物責任險</a>
                </div>
                <div className="flex-column v-center">
                  <img
                    src={certificate3}
                    onClick={() => handleClickOnImage(certificate3)}
                  />
                  <a>台灣製鞋品認證</a>
                </div>
              </div>
              <div className="flex-column v-center certificates">
                <div className="flex h-between certificate-mul">
                  <img
                    src={certificate4_1}
                    onClick={() => handleClickOnImage(certificate4_1)}
                  />
                  <img
                    src={certificate4_2}
                    onClick={() => handleClickOnImage(certificate4_2)}
                  />
                  <img
                    src={certificate4_3}
                    onClick={() => handleClickOnImage(certificate4_3)}
                  />
                </div>
                <a>商品驗證登錄證書</a>
                <a>橡膠鞋底安全鞋(靜電、導電、一般、耐踩、止滑、耐磨)</a>
              </div>
              <div className="flex v-center certificates">
                <div className="flex-column v-center">
                  <img
                    src={certificate5}
                    onClick={() => handleClickOnImage(certificate5)}
                  />
                  <a>商品驗證登錄證書</a>
                  <a>發泡聚胺酯鞋底安全鞋</a>
                </div>
                <div className="flex-column v-center certificate-last">
                  <img
                    src={certificate6}
                    onClick={() => handleClickOnImage(certificate6)}
                  />
                  <a>臺灣製MIT微笑產品認證書</a>
                </div>
              </div>
              {modalIsOpen && (
                <div className="flex h-center v-center modal">
                  <div className={`flex-column modal-content${isCertificate6 ? " img-hztl" : ""}`}>
                    <CgClose className="icon close" onClick={closeModal} />
                    <div className="flex v-center h-center">
                      <img src={modalImage} />
                    </div>
                  </div>
                </div>
              )}
            </div>
          )}
        </div>
      </div>
      <Footer />
    </div>
  );
}
