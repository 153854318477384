import React, { useEffect, useState } from "react";
import axios from "axios";
// Pagination
import Pagination from "../../../widgets/Pagination/Pagination";
// Icons
import { AiFillCaretDown, AiFillCaretUp } from "react-icons/ai";
// Css
import "./logAuthAction.scss";

export default function LogAuthAction() {
    // [ Page ]
    const [page, setPage] = useState(1);
    const [shouldUpdatePage, setShouldUpdatePage] = useState(false);
    const [totalPages, setTotalPages] = useState(1);
    // [ Filter by user ]
    const [showDropdown, setShowDropdown] = useState(false);
    const [users, setUsers] = useState([]);
    const [selectedUser, setSelectedUser] = useState("所有管理員");
    // [ Event listener ]
    const [click, setClick] = useState(null);
    // [ Content ]
    const [data, setData] = useState([]);
    const [filteredData, setFilteredData] = useState([]);

    useEffect(() => {
        callGetAuthLogActionEndpoint();

        // Event listener - mouse click
        const handleClick = (event) => {
            setClick(event);
        };

        document.addEventListener("click", handleClick);

        return () => {
            document.removeEventListener("click", handleClick);
        };
    }, []);

    useEffect(() => {
        if (shouldUpdatePage) {
            // Get auth log action list
            callGetAuthLogActionEndpoint();
        }
    }, [shouldUpdatePage]);

    useEffect(() => {
        // Click event
        if (click) {
            hideDropdown(click);
            setClick(null);
        }
    }, [click]);

    useEffect(() => {
        if (selectedUser === "所有管理員") {
            setFilteredData(data);
        }
        else {
            setFilteredData(data.filter(item => item.userName === selectedUser));
        }
    }, [selectedUser, data]);

    // Endpoint
    async function callGetAuthLogActionEndpoint() {
        await axios
            .get(`${process.env.REACT_APP_API}/logs/authAction`, {
                headers: { "x-api-key": process.env.REACT_APP_API_KEY },
                params: { page },
            })
            .then((res) => {
                if (res.status === 200) {
                    setData(res.data.list);
                    setTotalPages(res.data.totalPages);
                    setUsers(res.data.users);
                    setShouldUpdatePage(false);
                }
            })
            .catch(() => { });
    }

    // Get logs list
    const getLogsList = () => {
        const list = [];

        if (filteredData.length > 0) {
            filteredData.forEach((item, index) => {
                const match = item.action.match(/(.*?)(\(ID:.*?\))(.*)/);
                const beforeID = match ? match[1] : item.action;
                const idPart = match ? match[2] : "";
                const afterID = match ? match[3] : "";

                list.push(
                    <div key={"log_row" + index} className="flex log-row">
                        <a key={"log_acct" + index} className="flex h-center v-center">
                            {item.userName}
                        </a>
                        <a key={"log_page" + index} className="flex h-center v-center">
                            {item.page}
                        </a>
                        <a key={"log_time" + index} className="flex h-center v-center">
                            {formatDate(item.created_at)}
                        </a>
                        <a key={"log_action" + index} className="flex-column h-center v-center">
                            {beforeID && beforeID}
                            <span className="flex h-center v-center">
                                {idPart && <span className="id">{idPart}</span>}
                                {afterID && afterID}
                            </span>
                        </a>
                    </div>
                );
            });
        } else {
            list.push(
                <div key="log_row_no_record" className="flex h-center no-record">
                    沒有紀錄
                </div>
            );
        }

        return list;
    }

    const hideDropdown = (event) => {
        const { id } = event.target;
        const parentId = event.target.parentElement
            ? event.target.parentElement.id
            : null;

        // dropdown
        if (
            showDropdown &&
            id !== "dropdown-text" &&
            id !== "dropdown-content" &&
            id !== "dropdown-icon" &&
            parentId !== "dropdown-icon"
        ) {
            setShowDropdown(false);
        }
    };

    // Fomat date
    const formatDate = (dateStr) => {
        const date = new Date(dateStr);

        const year = date.getFullYear();
        const month = String(date.getMonth() + 1).padStart(2, '0'); // Months are zero-indexed
        const day = String(date.getDate()).padStart(2, '0');
        const hours = String(date.getHours()).padStart(2, '0');
        const minutes = String(date.getMinutes()).padStart(2, '0');
        const seconds = String(date.getSeconds()).padStart(2, '0');

        return `${year}/${month}/${day} ${hours}:${minutes}:${seconds}`;
    }

    return (
        <div id="log-auth-actions" className="flex-column v-center">
            {/* User Filter Dropdown */}
            <div className="flex v-center user-filter ">
                <a className="title">篩選使用者:</a>
                <div className="flex-column dropdown">
                    <div
                        className="flex h-between v-center dropdown-text"
                        onClick={() => setShowDropdown(!showDropdown)}
                    >
                        <a id="dropdown-text" className="flex h-center v-center">{selectedUser || "--"}</a>
                        {showDropdown ? (
                            <AiFillCaretUp id="dropdown-icon" />
                        ) : (
                            <AiFillCaretDown id="dropdown-icon" />
                        )}
                        <div
                            style={{ display: showDropdown ? "flex" : "none", }}
                            id="dropdown-content"
                            className="v-center dropdown-content"
                        >
                            <a
                                onClick={() => {
                                    setSelectedUser("所有管理員");
                                    setShowDropdown(!showDropdown);
                                }}
                            >所有管理員</a>
                            {users.map((user, index) => (
                                <a
                                    key={index}
                                    onClick={() => {
                                        setSelectedUser(user);
                                        setShowDropdown(!showDropdown);
                                    }}
                                >
                                    {user}
                                </a>
                            ))}
                        </div>
                    </div>
                </div>
            </div>

            {/* Log list */}
            <div className="flex-column log-list">
                {/* Title */}
                <div className="flex title">
                    <a>帳號</a>
                    <a>頁面</a>
                    <a>時間</a>
                    <a>操作</a>
                </div>
                <div className="log-list-content">
                    {getLogsList()}
                </div>
            </div>
            {/* Pagination */}
            <Pagination
                page={page}
                setPage={setPage}
                totalPages={totalPages}
                setShouldUpdatePage={setShouldUpdatePage}
            />
        </div>
    );
}
