import React, { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import axios from "axios";
// Category
import { category } from "../../pages/Data";
// Pop up
import PopUp from "../../widgets/PopUps/PopUps";
// Helper
import { clearCookie } from "../../utils/Cookie";
// Icons
import { IoSearch, IoMenu } from "react-icons/io5";
import { BiUser } from "react-icons/bi";
import { AiOutlineShopping } from "react-icons/ai";
import { BsFillCheckCircleFill } from "react-icons/bs";
import { PiUserBold } from "react-icons/pi";
import { CgClose } from "react-icons/cg";
// Images
import Logo from "../../files/images/logo-toping.png";
import Logo1 from "../../files/images/logo-tonya1.png";
import NavSubImg from "../../files/images/nav-sub.jpeg";
// Css
import "./nav.scss";

export default function Nav(props) {
  const navigate = useNavigate();

  // Mobile view
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 925);
  // Search
  const [searchInput, setSearchInput] = useState("");
  // Pop up
  const [showPopUp, setShowPopUp] = useState(false);
  // Cart
  const [showCart, setShowCart] = useState(false);
  // Toggle menu list
  const [showMenu1, setShowMenu1] = useState(false);
  const [showMenu2, setShowMenu2] = useState(false);
  const [expandCategory, setExpandCateogry] = useState(
    new Array(category.length).fill(false)
  );

  const handleResize = () => {
    setIsMobile(window.innerWidth <= 925);
  };

  useEffect(() => {
    callGetCartEndpoint();

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  useEffect(() => {
    if (props.toggleCart) {
      callGetCartEndpoint();
      setTimeout(() => {
        props.setToggleCart(false);
      }, 3000);
    }

    if (props.refreshCart) {
      callGetCartEndpoint();
      props.setRefreshCart(false);
    }
  }, [props.toggleCart, props.refreshCart]);

  // Endpoint
  async function callGetCartEndpoint() {
    const cartId = getCartFromCookie();

    if (cartId) {
      await axios
        .get(`${process.env.REACT_APP_API}/carts/get?`, {
          params: { cartId },
          headers: { "x-api-key": process.env.REACT_APP_API_KEY },
        })
        .then((res) => {
          if (res.status === 200) {
            // Set cart list state
            props.setCart(res.data);

            // Update cart quantity
            props.setCartQuantity(res.data.length);
            props.setUpdateQuantity(true);
          }
        })
        .catch(() => { });
    } else {
      props.setCart([]);
      props.setCartQuantity(0);
      props.setUpdateQuantity(true);
    }
  }
  async function callSearchProductEndpoint() {
    await axios
      .get(`${process.env.REACT_APP_API}/products/search?`, {
        params: { input: searchInput },
        headers: { "x-api-key": process.env.REACT_APP_API_KEY },
      })
      .then((res) => {
        if (res.status === 200) {
          props.setSearchResult(res.data.result);
          props.setUpdateSearch(true);
          navigate("/search-product");
        }
      })
      .catch(() => { });
  }

  // Sign out
  const signOut = () => {
    // Clear cookies
    const time = new Date().toUTCString();
    clearCookie("name", "", time);
    clearCookie("user", "", time);
    clearCookie("x", "", time);
    clearCookie("cart", "", time);
    clearCookie("storeData", "", time);

    // Sign out from facebook
    if (props.socialInfo && props.socialInfo.source == "facebook") {
      window.FB.logout(() => { });
    }

    // Reset cart detail
    props.setCart([]);
    props.setCartQuantity(0);
    props.setUpdateQuantity(true);

    // Show pop up
    setShowPopUp(true);

    if (isMobile) {
      setShowMenu1(!showMenu1);
    }
  };

  // Show sign in modal
  const showSignIn = () => {
    props.setShowSignInAnimation(true);
    props.setShowSignIn(true);

    if (isMobile) {
      setShowMenu1(!showMenu1);
    }
  };

  // Pop up
  const popUpContent = () => {
    return <a>登出成功</a>;
  };
  const hidePopUp = () => {
    setShowPopUp(false);
    props.setSignedIn(false);
    props.setUserFullName(null);
    // Go home page
    navigate("/");
  };

  // Get category
  const getCategory = (isMobile) => {
    const list = [];

    for (let i = 0; i < category.length; i++) {
      const subList = [];
      const { type } = category[i];

      for (let j = 0; j < type.length; j++) {
        subList.push(
          <Link
            key={"type" + i + "_" + j}
            to={"/product-list/" + type[j]}
            className="category-item"
            style={{
              display: !isMobile || expandCategory[i] ? "block" : "none",
            }}
            onClick={clickOnPage}
          >
            {type[j]}
          </Link>
        );
      }

      list.push(
        <div key={"category_div" + i} className={`flex-column category-cont${expandCategory[i] ? " active" : ""}`}>
          <Link
            key={"category_text" + i}
            className={`dropdown-title dropdown-item${expandCategory[i] ? " active" : ""}`}
            to={"/product-list/" + category[i].name}
            onClick={() => clickOnCategoryList(isMobile, i)}
          >
            {category[i].name}
          </Link>
          {subList}
        </div>
      );
    }

    return list;
  };

  const clickOnPage = (isMobile) => {
    if (isMobile) {
      setShowMenu2(!showMenu2);
    }
    props.setClickNav(true);
  };

  // Click on category list
  const clickOnCategoryList = (isMobile, index) => {
    if (isMobile) {
      setExpandCateogry((old) => {
        old[index] = !old[index];
        return old;
      });
    }
    props.setClickNav(!isMobile);
  };

  // Get cart from cookie
  const getCartFromCookie = () => {
    let id = null;
    let { cookie } = document;
    cookie = cookie.split("; ");

    if (cookie.length > 0 && cookie[0] !== "") {
      for (let item of cookie) {
        item = item.split("=");
        if (item[0] === "cart") {
          id = item[1];
          break;
        }
      }
    }

    return id;
  };

  // Get cart list
  const getCartList = () => {
    const list = [];

    list.push(
      <div key="cart_title" className="flex h-between title">
        <a key="cart_product_name">商品名稱</a>
        <a key="cart_color">顏色</a>
        <a key="cart_size">尺寸</a>
        <a key="cart_quantity">數量</a>
      </div>
    );

    if (props.cart.length > 0) {
      props.cart.forEach((item, index) => {
        const { name, model, colorCode, size, quantity } = item;

        list.push(
          <div key={"cart_" + index} className="flex h-between v-center">
            <a key={"cart_model_" + index}>{`${name} (型號: ${model})`}</a>
            <div
              key={"cart_color_" + index}
              style={{ backgroundColor: colorCode }}
              className="color"
            ></div>
            <a key={"cart_size_" + index}>{size}</a>
            <a key={"cart_quantity_" + index}>{quantity}</a>
          </div>
        );
      });
    } else {
      list.push(
        <div key="cart_no_item" className="flex h-center v-center">
          <a key="cart_no_item_text">購物車內無商品</a>
        </div>
      );
    }

    if (props.toggleCart) {
      list.push(
        <div key="cart_add" className="flex h-center v-center add-success">
          <div key="cart_add_icon_background"></div>
          <BsFillCheckCircleFill key="cart_add_icon" />
          <a key="cart_add_text">商品已成功加入購物車!</a>
        </div>
      );
    }

    return list;
  };

  // Search input
  const searchProduct = (isMobile) => {
    callSearchProductEndpoint();

    if (isMobile) {
      setShowMenu1(!showMenu1)
    }
  };

  // Handle key press
  const handleKeyPress = (event) => {
    if (event.keyCode == 13) {
      callSearchProductEndpoint();
    }
  };

  // Handle input change
  const handleInputChange = (event) => {
    setSearchInput(event.target.value);
  };

  return (
    <nav>
      <div className="flex h-between v-center">
        <div className="nav-logo">
          <Link to="/" className="flex v-center">
            <img src={Logo} />
            <img src={Logo1} />
          </Link>
        </div>
        <div className="flex h-center v-center nav-menu desktop">
          <div className="dropdown">
            <Link
              className="nav-main nav-product"
              to="/product-list/所有商品"
              onClick={() => props.setClickNav(true)}
            >
              線上購物
            </Link>
            <div className="dropdown-content">
              <div className="flex dropdown-content-main">
                {getCategory(false)}
                <div className="flex-column h-center v-center dropdown-img">
                  <img src={NavSubImg} />
                  <a>台灣製造 • Made in Taiwan</a>
                </div>
              </div>
            </div>
          </div>
          <a className="divider"></a>
          <Link to="/catalog" className="nav-main">
            產品型錄
          </Link>
          <a className="divider"></a>
          <Link to="/location" className="nav-main">
            公司據點
          </Link>
          <a className="divider"></a>
          <Link to="/qa" className="nav-main">
            Q&A
          </Link>
        </div>
        <div className="flex v-center nav-user">
          <div className="flex v-center input desktop">
            <input
              type="text"
              placeholder="搜尋商品"
              value={searchInput}
              onChange={handleInputChange}
              onKeyDown={handleKeyPress}
            />
            <IoSearch className="icon" onClick={() => searchProduct(false)} />
          </div>
          <div className="flex v-center menu-items mobile">
            <IoSearch className="icon" onClick={() => setShowMenu1(true)} />
            <PiUserBold className="icon" onClick={() => setShowMenu1(true)} />
            <IoMenu
              className="icon menu-icon"
              onClick={() => setShowMenu2(true)}
            />
          </div>
          {/* Mobile dropdown - users & search */}
          <div className="flex-column h-center v-center nav-menu mobile">
            <div className={`blur-background${showMenu1 ? " active" : ""}`} />
              <div className={`flex-column dropdown${showMenu1 ? " active" : ""}`}>
                <CgClose
                  className="icon close-icon"
                  onClick={() => setShowMenu1(false)}
                />
                <div className="flex-column user-cont">
                  {!props.signedIn &&
                    <a className="nav-main dropdown-item" onClick={showSignIn}>加入會員 / 會員登入</a>
                  }
                  {props.signedIn &&
                    <Link to="/info" className="nav-main dropdown-item" onClick={() => setShowMenu1(!showMenu1)}>個人資料</Link>
                  }
                  <Link to="/cart" className="nav-main dropdown-item" onClick={() => setShowMenu1(!showMenu1)}>購物車</Link>
                  <Link to="/order-tracking" className="nav-main dropdown-item" onClick={() => setShowMenu1(!showMenu1)}>訂單查詢</Link>
                  {props.signedIn &&
                    <a onClick={signOut} className="nav-main dropdown-item">會員登出</a>
                  }
                  <div className="flex h-between v-center input">
                    <input
                      type="text"
                      placeholder="搜尋商品"
                      value={searchInput}
                      onChange={handleInputChange}
                      onKeyDown={handleKeyPress}
                    />
                    <IoSearch className="icon" onClick={() => searchProduct(true)} />
                  </div>
              </div>
            </div>
          </div>
          {/* Mobile dropdown - categories */}
          <div className="flex-column h-center v-center nav-menu mobile">
            <div className={`blur-background${showMenu2 ? " active" : ""}`} />
            <div className={`flex-column dropdown${showMenu2 ? " active" : ""}`}>
              <CgClose
                className="icon close-icon"
                onClick={() => setShowMenu2(false)}
              />
              {getCategory(true)}
              <div className="dropdown-item">
                <Link to="/catalog" className="nav-main" onClick={() => setShowMenu2(!showMenu2)}>
                  產品型錄
                </Link>
              </div>
              <div className="dropdown-item">
                <Link to="/location" className="nav-main" onClick={() => setShowMenu2(!showMenu2)}>
                  公司據點
                </Link>
              </div>
              <div className="flex-column qa">
                <Link to="/qa" className="nav-main" onClick={() => setShowMenu2(!showMenu2)}>
                  Q&A
                </Link>
              </div>
            </div>
          </div>
          {/* User dropdown - sign in / sign up / user info / order tracking */}
          <div className="dropdown">
            <BiUser className="icon nav-user-icon" />
            <div className="dropdown-content nav-user-dropdown">
              <div className="flex dropdown-content-main">
                <div className="flex-column">
                  {(props.signedIn || props.adminSignedIn) && (
                    <a className="user-info">{props.userFullName},</a>
                  )}
                  {!props.signedIn && !props.adminSignedIn && (
                    <a onClick={showSignIn}>加入會員 / 會員登入</a>
                  )}
                  {(props.signedIn || props.adminSignedIn) && <Link to="/info">個人資料</Link>}
                  <Link to="/order-tracking">訂單查詢</Link>
                  {(props.signedIn || props.adminSignedIn) && <a onClick={signOut}>會員登出</a>}
                </div>
              </div>
            </div>
          </div>
          {/* Cart */}
          <div
            className={`dropdown cart-container${props.cartQuantity > 0 ? "" : " empty"}`}
            onMouseOver={() => setShowCart(true)}
            onMouseOut={() => setShowCart(false)}
          >
            <div className="flex v-center" onClick={() => navigate("/cart")}>
              <AiOutlineShopping className="icon icon-cart" />
              {props.cartQuantity > 0 && (
                <a className="cart-num">{props.cartQuantity}</a>
              )}
            </div>
            <div
              style={{ display: props.toggleCart || showCart ? "flex" : "none" }}
              className="dropdown-content"
            >
              {getCartList()}
            </div>
          </div>
        </div>
        {showPopUp && (
          <div id="sign-out">
            <PopUp popUpContent={popUpContent} hidePopUp={hidePopUp} />
          </div>
        )}
      </div>
    </nav>
  );
}
