import React, { useState, useRef, useEffect } from 'react';

export default function ImageMagnifier(props) {
    const [showMagnifier, setShowMagnifier] = useState(false);
    const [[x, y], setXY] = useState([0, 0]);
    const [[imgWidth, imgHeight], setSize] = useState([0, 0]);
    const originalImageRef = useRef(null);

    useEffect(() => {
        const img = originalImageRef.current;
        if (img) {
            const { width, height } = img.getBoundingClientRect();
            setSize([width, height]);
        }
    }, []);

    const handleMouseMove = (e) => {
        const elem = originalImageRef.current;
        const { top, left } = elem.getBoundingClientRect();

        const x = e.clientX - left;
        const y = e.clientY - top;
        setXY([x, y]);
    };

    return (
        <div className="flex image-magnifier-container">
            <div
                className="flex h-center v-center current-img-container"
                onMouseEnter={() => setShowMagnifier(true)}
                onMouseLeave={() => setShowMagnifier(false)}
                onMouseMove={handleMouseMove}
            >
                <img
                    className="current_image"
                    ref={originalImageRef}
                    src={props.imageSrc}
                    alt={props.altText}
                />
                {showMagnifier && (
                    <div
                        className="cursor_square"
                        style={{
                            left: `${x - props.squareSize / 2}px`,
                            top: `${y - props.squareSize / 2}px`,
                            width: `${props.squareSize}px`,
                            height: `${props.squareSize}px`,
                        }}
                    />
                )}
            </div>
            {showMagnifier && (
                <div className="zoomed_image_cont">
                    <img
                        className="current_image zoomed"
                        src={props.imageSrc}
                        alt={props.altText}
                        style={{
                            transform: `scale(${props.zoomLevel})`,
                            transformOrigin: `${(x / imgWidth) * 100}% ${(y / imgHeight) * 100}%`,
                        }}
                    />
                </div>
            )}
        </div>
    );
}