import React, { useEffect, useState } from "react";
import axios from "axios";
// Pagination
import Pagination from "../../../widgets/Pagination/Pagination";
// Css
import "./contactMessages.scss";

export default function ContactMessages() {
    // [ Page ]
    const [page, setPage] = useState(1);
    const [shouldUpdatePage, setShouldUpdatePage] = useState(false);
    const [totalPages, setTotalPages] = useState(1);
    // [ Content ]
    const [data, setData] = useState([]);

    useEffect(() => {
        callGetContactMessagesEndpoint();
    }, []);


    useEffect(() => {
        if (shouldUpdatePage) {
            // Get contact message list
            callGetContactMessagesEndpoint();
        }
    }, [shouldUpdatePage]);

    // Endpoint
    async function callGetContactMessagesEndpoint() {
        await axios
            .get(`${process.env.REACT_APP_API}/contact/list`, {
                headers: { "x-api-key": process.env.REACT_APP_API_KEY },
                params: { page },
            })
            .then((res) => {
                if (res.status === 200) {
                    setData(res.data.list);
                    setTotalPages(res.data.totalPages);
                    setShouldUpdatePage(false);
                }
            })
            .catch(() => { });
    }

    // Get message list
    const getMessageList = () => {
        const list = [];

        if (data.length > 0) {
            data.forEach((item, index) => {
                list.push(
                    <div key={"message_row" + index} className="flex-column record">
                        <div key={"message_record1" + index} className="flex h-center v-center row row-a">
                            <a key={"message_time_title" + index} className="title">時間: </a>
                            <a key={"message_time" + index}>{formatDate(item.created_at)}</a>
                        </div>
                        <div key={"message_record2" + index} className="flex-column h-center">
                            <div key={"message_page_record2_1" + index} className="flex h-center row v-center">
                                <a key={"message_page_title" + index} className="title">頁面: </a>
                                <a key={"message_page" + index}>{item.page}</a>
                            </div>
                            <div key={"message_name_record2_2" + index} className="flex h-center v-center row row-a">
                                <a key={"message_name_title" + index} className="title">名字: </a>
                                <a key={"message_name" + index}>{item.name}</a>
                            </div>
                            <div key={"message_company_record2_3" + index} className="flex h-center v-center row">
                                <a key={"message_company_title" + index} className="title">公司: </a>
                                <a key={"message_company" + index}> {item.company ? item.company : "-"}</a>
                            </div>
                            <div key={"message_email_record2_4" + index} className="flex h-center v-center row row-a">
                                <a key={"message_email_title" + index} className="title">Email: </a>
                                <a key={"message_email" + index}> {item.email}</a>
                            </div>
                            <div key={"message_phone_record2_5" + index} className="flex h-center v-center row">
                                <a key={"message_phone_title" + index} className="title">電話: </a>
                                <a key={"message_phone" + index}>{item.phone ? item.phone : "-"}</a>
                            </div>
                        </div>
                        <div key={"message_record3" + index} className="flex h-center v-center row row-a">
                            <a key={"message_content_title" + index} className="title">內容: </a>
                            <a key={"message_content" + index}>{item.message}</a>
                        </div>
                    </div>
                );
            });
        } else {
            list.push(
                <div key="message_row_no_record" className="flex h-center no-record">
                    沒有紀錄
                </div>
            );
        }

        return list;
    }

    // Fomat date
    const formatDate = (dateStr) => {
        const date = new Date(dateStr);

        const year = date.getFullYear();
        const month = String(date.getMonth() + 1).padStart(2, '0'); // Months are zero-indexed
        const day = String(date.getDate()).padStart(2, '0');
        const hours = String(date.getHours()).padStart(2, '0');
        const minutes = String(date.getMinutes()).padStart(2, '0');
        const seconds = String(date.getSeconds()).padStart(2, '0');

        return `${year}/${month}/${day} ${hours}:${minutes}:${seconds}`;
    }

    return (
        <div id="contact-messages" className="flex-column v-center">
            {/* Message list */}
            <div className="flex-column message-list">
                <div className="message-list-content">
                    {getMessageList()}
                </div>
            </div>
            {/* Pagination */}
            <Pagination
                page={page}
                setPage={setPage}
                totalPages={totalPages}
                setShouldUpdatePage={setShouldUpdatePage}
            />
        </div>
    )
}